<template>

    <div>
        <div style="margin-top: 100px" class="container">
            <div>


                <div style="border-color: #1283ac" class="card">
                    <div class="card-body">

                        <div class="text-center">
                            <img src="@/assets/logo.png"/>
                            <p>Estes Termos e Condições de Uso do Sistema definem o relacionamento entre você e nossa
                                empresa.
                                <br>De modo geral, permitimos que voce use nossos serviços se concordar em seguir estes
                                termos.</p>
                        </div>
                    </div>


                    <br>
                    <div class="container">
                        <div class="text-center">
                            <strong>Termos e condições gerais de uso do sistema Infoconsig</strong>
                        </div>
                        <div style="margin-top: 10px" class="termo container">


                            <p> Estes termos e condições (daqui em diante referidos apenas como “Termo”) se aplicam à
                                utilização de
                                nosso
                                sistema Infoconsig, por você.</p>

                            <p>
                                Nosso sistema é mantido pela pessoa jurídica Sttórico Sistemas Ltda. (Sttórico),
                                devidamente
                                registrada
                                sob o
                                CNPJ nº 14.129.074/0001-37, com sede em Balneário Camboriú-SC, e-mail:
                                administracao@infoconsig.com.br,
                                titular
                                exclusiva da propriedade intelectual sobre o Infoconsig .</p>

                            <p>
                                1. Do objeto : A licença de uso do Infoconsig visa fornecer acesso ao usuário para
                                administrar às
                                informações
                                sobre as suas consignações em folha de pagamento.</p>
                            <p>
                                2. Da aceitação: O presente Termo estabelece obrigações contratadas de livre e
                                espontânea
                                vontade,
                                por
                                tempo
                                indeterminado, entre o Infoconsig e as pessoas físicas, usuárias do Infoconsig . Ao
                                utilizar
                                a
                                Infoconsig o
                                usuário aceita integralmente as presentes normas e compromete-se a observá-las, sob o
                                risco
                                de
                                aplicação
                                das
                                penalidade cabíveis. A aceitação do presente instrumento é imprescindível para o acesso
                                e
                                para a
                                utilização de
                                quaisquer serviços fornecidos pela empresa. Caso não concorde com as disposições deste
                                instrumento,
                                o
                                usuário
                                não deve utilizá-los.
                            </p>
                            <p>
                                3. Do acesso dos usuários: Serão utilizadas todas as soluções técnicas à disposição do
                                responsável
                                pelo
                                Infoconsig para permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete)
                                dias por
                                semana. No
                                entanto, a navegação no Infoconsig ou em alguma de suas páginas poderá ser interrompida,
                                limitada ou
                                suspensa
                                para atualizações, modificações ou qualquer ação necessária ao seu bom funcionamento.
                            </p>

                            <p>
                                4. Do cadastro: O acesso às funcionalidades do Infoconsig exigirá a realização de um
                                cadastro
                                prévio. Ao
                                se
                                cadastrar o usuário deverá informar dados completos, recentes e válidos, sendo de sua
                                exclusiva
                                responsabilidade
                                manter referidos dados atualizados, bem como o usuário se compromete com a veracidade
                                dos
                                dados
                                fornecidos.
                            </p>

                            <p>
                                4.1. O usuário se compromete a não informar seus dados cadastrais e/ou de acesso ao
                                Infoconsig a
                                terceiros,
                                responsabilizando-se integralmente pelo uso que deles seja feito.
                            </p>
                            <p>
                                4.2. Menores de 18 anos e aqueles que não possuírem plena capacidade civil deverão obter
                                previamente
                                o
                                consentimento expresso de seus responsáveis legais para utilização do Infoconsig e dos
                                serviços ou
                                produtos,
                                sendo de responsabilidade exclusiva dos mesmos o eventual acesso por menores de idade e
                                por
                                aqueles
                                que
                                não
                                possuem plena capacidade civil sem a prévia autorização.
                            </p>
                            <p>
                                4.3. Mediante a realização do cadastro o usuário declara e garante expressamente ser
                                plenamente
                                capaz,
                                podendo
                                exercer e usufruir livremente dos serviços e produtos.
                            </p>
                            <p>
                                4.4. O usuário deverá fornecer e manter atualizado um endereço de e-mail válido e número
                                de
                                telefone
                                celular,
                                através do qual o Infoconsig realizará todas comunicações necessárias.
                            </p>
                            <p>
                                4.5. Após a confirmação do cadastro, o usuário possuirá um login (seu CPF) e uma senha
                                pessoal, a
                                qual
                                assegura
                                ao usuário o acesso individual à mesma. Desta forma, compete ao usuário exclusivamente a
                                manutenção
                                de
                                referida
                                senha de maneira confidencial e segura, evitando o acesso indevido às informações
                                pessoais.
                            </p>
                            <p>
                                4.6. Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do
                                usuário, que
                                deverá
                                informar prontamente ao Infoconsig em caso de uso indevido da respectiva senha.
                            </p>
                            <p>
                                4.7. Não será permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta,
                                que
                                é
                                pessoal e
                                intransferível.
                            </p>
                            <p>
                                4.8. Caberá ao usuário assegurar que o seu equipamento seja compatível com as
                                características
                                técnicas
                                que
                                viabilize a utilização do Infoconsig e dos serviços ou produtos.</p>
                            <p>
                                4.9. O usuário poderá, a qualquer tempo, requerer o cancelamento de seu cadastro junto
                                ao
                                Infoconsig. O
                                seu
                                descadastramento será realizado o mais rapidamente possível, desde que não sejam
                                verificados
                                lançamentos
                                de
                                consignações em andamento.</p>
                            <p>
                                4.10. O usuário, ao aceitar os Termos e Política de Privacidade, autoriza expressamente
                                a
                                Infoconsig
                                a
                                coletar,
                                usar, armazenar, tratar, ceder ou utilizar as informações derivadas do uso do
                                Infoconsig,
                                incluindo
                                todas as
                                informações preenchidas pelo usuário no momento em que realizar ou atualizar seu
                                cadastro,
                                além de
                                outras
                                expressamente descritas na Política de Privacidade que deverá ser autorizada pelo
                                usuário.</p>
                            <p>
                                5. Do cancelamento: O usuário poderá solicitar o cancelamento do uso do Infoconsig de
                                acordo
                                com os
                                termos que
                                forem definidos no momento de sua contratação, a qualquer momento, através do e-mail:
                                suporte@infoconsig.com.br</p>
                            <p>
                                6. Do suporte: Em caso de qualquer dúvida, sugestão ou problema com a utilização da
                                Infoconsig , o
                                usuário
                                poderá entrar em contato com o suporte, através do e-mail: suporte@infoconsig.com.br ou
                                pelo
                                canal
                                de
                                atendimento disposto no próprio Infoconsig . Estes serviços de atendimento ao usuário
                                estarão
                                disponíveis das
                                segunda-feiras as sexta-feiras nos seguintes horários: das 8:30hs às 18:30hs.</p>
                            <p>
                                7. Das responsabilidades</p>
                            <p>
                                7.1. É de responsabilidade do usuário: a) pelos defeitos ou vícios técnicos originados
                                no
                                próprio
                                sistema do
                                aparelho de celular do usuário; b) a correta utilização do Infoconsig , prezando pela
                                boa
                                convivência,
                                pelo
                                respeito e cordialidade com os atendentes do suporte operacional; c) pelo cumprimento e
                                respeito ao
                                conjunto de
                                regras disposto nesse Termo de Condições Geral de Uso, na respectiva Política de
                                Privacidade
                                e na
                                legislação
                                nacional e internacional; d) pela proteção aos dados de acesso à sua conta/perfil (login
                                e
                                senha).</p>
                            <p>
                                7.2. É de responsabilidade do Infoconsig : a) indicar as características do seu uso; b)
                                os
                                defeitos
                                e
                                vícios
                                encontrados no seu uso, desde que lhe tenha dado causa; c) as informações que foram por
                                ele
                                divulgadas,
                                sendo
                                que os comentários ou informações divulgadas por usuários são de inteira
                                responsabilidade
                                dos
                                próprios
                                usuários;</p>
                            <p>
                                d) os conteúdos ou atividades ilícitas praticadas através do Infoconsig ; e) pelos links
                                externos
                                contidos em
                                seu sistema que possam redirecionar o usuário à ambiente externo a sua rede.</p>

                            <p>
                                7.3. Não poderão ser incluídos links externos ou páginas que sirvam para fins comerciais
                                ou
                                publicitários ou
                                quaisquer informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas,
                                discriminatórias
                                ou
                                ofensivas.</p>
                            <p>
                                8. Dos direitos autorais: O presente Termo de Uso concede aos usuários uma licença não
                                exclusiva,
                                não
                                transferível e não sublicenciável, para acessar e fazer uso do Infoconsig.</p>
                            <p>
                                8.1. A estrutura do Infoconsig, as marcas, logotipos, nomes comerciais, layouts,
                                gráficos e
                                design
                                de
                                interface,
                                imagens, ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de som e
                                áudio,
                                programas
                                de
                                computador, banco de dados, arquivos de transmissão e quaisquer outras informações e
                                direitos de
                                propriedade
                                intelectual da razão social da Sttórico, observados os termos da Lei da Propriedade
                                Industrial (Lei
                                nº
                                9.279/96), Lei de Direitos Autorais (Lei nº 9.610/98) e Lei do Software (Lei nº
                                9.609/98),
                                estão
                                devidamente
                                reservados.</p>
                            <p>
                                8.2. Este Termos de Uso não cede ou transfere ao usuário qualquer direito, de modo que o
                                acesso não
                                gera
                                qualquer direito de propriedade intelectual ao usuário, exceto pela licença limitada ora
                                concedida.</p>
                            <p>
                                8.3. O uso do Infoconsig pelo usuário é pessoal, individual e intransferível, sendo
                                vedado
                                qualquer
                                uso
                                não
                                autorizado, comercial ou não-comercial. Tais usos consistirão em violação dos direitos
                                de
                                propriedade
                                intelectual da razão social da Sttórico, puníveis nos termos da legislação
                                aplicável.</p>
                            <p>
                                9. Das sanções: Sem prejuízo das demais medidas legais cabíveis, a razão social da
                                Sttórico
                                poderá,
                                a
                                qualquer
                                momento, advertir, suspender ou cancelar a conta do usuário: a) que violar qualquer
                                dispositivo do
                                presente
                                Termo; b) que descumprir os seus deveres de usuário; c) que tiver qualquer comportamento
                                fraudulento,
                                doloso ou
                                que ofenda a terceiros.</p>
                            <p>
                                10. Da rescisão: A não observância das obrigações pactuadas neste Termo de Uso ou da
                                legislação
                                aplicável
                                poderá, sem prévio aviso, ensejar a imediata rescisão unilateral por parte da razão
                                social
                                da
                                Sttórico e
                                o
                                bloqueio do uso do aplictivo.</p>
                            <p>
                                11. Das alterações: Os itens descritos no presente instrumento poderão sofrer
                                alterações,
                                unilateralmente e a
                                qualquer tempo, por parte da Sttórico, para adequar ou modificar o seu uso, bem como
                                para
                                atender
                                novas
                                exigências legais. As alterações serão veiculadas pelo Infoconsig e o usuário poderá
                                optar
                                por
                                aceitar o
                                novo
                                conteúdo ou por cancelar o seu uso.</p>
                            <p>
                                12. Da política de privacidade: Além do presente Termo, o usuário deverá consentir com
                                as
                                disposições
                                contidas
                                na respectiva Política de Privacidade a ser apresentada a todos os interessados dentro
                                da
                                interface
                                da
                                Infoconsig .</p>
                            <p>
                                13. Do foro: Para a solução de controvérsias decorrentes do presente instrumento será
                                aplicado
                                integralmente o
                                Direito brasileiro. Os eventuais litígios deverão ser apresentados no foro da comarca de
                                Balneário
                                Camboriú-SC,
                                onde se encontra a sede da empresa.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 10px" class="row">
                <div class="col-md-6"><input type="checkbox" v-model="checkboxValue">
                    Li e concordo com os termos e condições.
                </div>
                <div class="col-md-6 text-right"><a target="_blank" href="termos.pdf">Imprimir Texto</a></div>
            </div>
            <div style="margin-top: 20px" class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <button style="border-radius: 15px" class="btn btn-primary btn-block" :disabled="!checkboxValue"
                            @click="aceiteTermo">Aceitar
                    </button>
                </div>
                <div class="col-md-3"></div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;
export default {
    name: "TermoAceite",
    data() {
        return {
            checkboxValue: false
        };
    },
    methods: {
        async aceiteTermo() {
            const authToken = localStorage.getItem('authToken');
            if (authToken) {
                try {
                    const response = await axios.get(baseUrl+'v2/termo-aceite', {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    });

                    const user = response.data;

                    if (user){
                        this.$router.push('/myUsers');
                    }
                } catch (error) {
                    console.error('Erro ao consultar usuário', error);
                    throw error;
                }
            } else {
                console.error('Token de autenticação não encontrado');
                return null;
            }
        },

    },
    mounted() {
        this.$consultarUsuario;
        //this.$termoUso;
    }
}
</script>

<style scoped>
.termo {
    /* Largura do contêiner */
    background-color: rgba(208, 208, 208, 0.2);
    height: 300px; /* Altura do contêiner */
    overflow: auto; /* Habilita a barra de rolagem quando o conteúdo ultrapassar as dimensões do contêiner */
}
p{
    color: black;
}
</style>