<template>
    <div>
        <p>Seu endereço IP local é: {{ meuIpLocal }}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            meuIpLocal: 'Obtendo IP...',
        };
    },
    mounted() {
        this.obterMeuIpLocal();
    },
    methods: {
        async obterMeuIpLocal() {
            try {
                const response = await fetch('https://httpbin.org/ip');
                const data = await response.json();
                this.meuIpLocal = data.origin || 'Não disponível';
            } catch (error) {
                console.error('Erro ao obter o IP local:', error);
                this.meuIpLocal = 'Erro ao obter o IP';
            }
        },
    },
};
</script>
