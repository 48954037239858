<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">Resumo da Margem</h5>
    </div>
    <div class="card-body">
      <strong>Margem Empréstimos</strong>
      <div class="progress">
        <div class="progress-bar bg-danger" :style="{ width: utilizada_porcentagem + '%' }"></div>
        <div class="progress-bar bg-success" :style="{ width: livre_porcentagem + '%' }"></div>
      </div>

      <div class="row">
        <div class="col-md-6 text-left">Utilizado: {{ valor_utilizado }}</div>
        <div class="col-md-6 text-right">Livre: {{ valor_livre }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const baseUrl = process.env.VUE_APP_BASE_URL
export default {
  props: {
    cd_servidor: Number,
    cd_consignataria: Number
  },
  data() {
    return {
      utilizada_porcentagem: 0,
      livre_porcentagem: 0,
      valor_utilizado: "",
      valor_livre: ""
    };
  },

  methods: {
    async getMargem() {
      console.log('cd_consignataria antes da requisição:', this.cd_consignataria);


      const url = `${baseUrl}minhamargem/${this.cd_servidor}/consignataria/${this.cd_consignataria}`;

      try {
        const response = await axios.get(url);
        this.livre_porcentagem = response.data.livreporc;
        this.utilizada_porcentagem = response.data.utilizadaporc;
        this.valor_livre = response.data.valor_disponivel;
        this.valor_utilizado = response.data.valor_utilizado;
        // ... outras atribuições de dados se necessário
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style scoped>
.page-wraper {
  width: 80%;
  margin: 0 auto;
}

.custom-line {
  border: none;
  border-top: 1px solid #1384AD;
  margin: 10px 0;
}

.card {
  margin-bottom: 30px;
}

.card-header {

  background-color: #1384ad;

}

.card-header > h5 {
  color: white;
}
</style>
