<template>
    <div>
        <div style="margin-top: 100px" class="container">
            <div class="text-center">


                <div class="card">
                    <div class="card-body">
                        <img src="img/logo.png"/></div>

                </div>
            </div>


            <div style="margin-top: 100px" class="card">
                <div style="background-color: #1283ac" class="card-header">
                    <h5 style="color:white" class="card-title">Lista de Perfis</h5>
                </div>
                <div class="card-body">
                    <div v-for="user in userList" :key="user.id">
                        <label>
                            <input type="radio" :value="user.cd_usuario" v-model="selectedUser"/>
                            {{ user.ds_login }}
                        </label>
                    </div>
                    <button style="background-color: #1283ac" class="btn btn-primary btn-block" @click="selectUser">
                        Selecionar
                    </button>
                </div>
            </div>
        </div>

        <b-modal id="my-modal" hide-footer>
            <template #modal-title>
                <h3>Pesquisa de satisfação Infoconsig</h3>
            </template>
            <div v-if="pergunta === 0" class="d-flex justify-content-between">
                <button type="button" class="btn btn-outline-primary" v-on:click="goHome">Responder Depois</button>
                <button type="button" class="btn btn-success" v-on:click="handleNext('inicio')">Responder agora</button>
            </div>
            <div v-if="pergunta === 1">
                <p>1. Por quanto tempo você tem usado este software?</p>
                <input type="radio" name="resp1" v-model="resp1" value="1">Menos de 1 mês <br>
                <input type="radio" name="resp1" v-model="resp1" value="2">De 1 a 3 meses <br>
                <input type="radio" name="resp1" v-model="resp1" value="3">De 4 a 6 meses <br>
                <input type="radio" name="resp1" v-model="resp1" value="4">De 7 a 12 meses <br>
                <input type="radio" name="resp1" v-model="resp1" value="5">Mais de 1 ano <br>
                <div class="d-flex justify-content-end">
                    <button type="button" v-on:click="handleNext(resp1)" class="btn btn-primary">Próxima</button>
                </div>

            </div>
            <div v-if="pergunta === 2">
                <p>2. Com que frequência você usa este software?</p>
                <input type="radio" name="resp2" v-model="resp2" value="1">Diariamente <br>
                <input type="radio" name="resp2" v-model="resp2" value="2">Semanalmente <br>
                <input type="radio" name="resp2" v-model="resp2" value="3">Mensalmente <br>
                <input type="radio" name="resp2" v-model="resp2" value="4">Raramente <br>
                <div class="d-flex justify-content-end">
                    <button type="button" v-on:click="handleNext(resp2)" class="btn btn-primary">Próxima</button>
                </div>
            </div>
            <div v-if="pergunta === 3">
                <p>3. Avalie sua satisfação geral com o uso do Infoconsig. (Escala de 1 a 5, sendo 1 muito
                    insatisfeito e 5 muito satisfeito)</p>
                <input type="radio" name="resp3" v-model="resp3" value="1">Muito Insatisfatório: A experiência foi
                muito abaixo das minhas expectativas e deixou muito a desejar. <br>
                <input type="radio" name="resp3" v-model="resp3" value="2">Insatisfatório: A experiência não atendeu
                às minhas expectativas e houve problemas significativos. <br>
                <input type="radio" name="resp3" v-model="resp3" value="3">Neutro: A experiência foi adequada, mas
                não excepcional. Minhas expectativas foram atendidas, mas nada mais. <br>
                <input type="radio" name="resp3" v-model="resp3" value="4">Satisfatório: A experiência atendeu às
                minhas expectativas e fiquei contente com o serviço/produto. <br>
                <input type="radio" name="resp3" v-model="resp3" value="5">Muito Satisfatório: A experiência superou
                as minhas expectativas e fiquei extremamente satisfeito com o serviço/produto. <br>
                <div class="d-flex justify-content-end">
                    <button type="button" v-on:click="handleNext(resp3)" class="btn btn-primary">Próxima</button>
                </div>
            </div>
            <div v-if="pergunta === 4">
                <p>4. O Infoconsig atende as suas necessidades?</p>
                <input type="radio" name="resp4" v-model="resp4" value="1">Sempre <br>
                <input type="radio" name="resp4" v-model="resp4" value="2">Quase sempre <br>
                <input type="radio" name="resp4" v-model="resp4" value="3">Às vezes <br>
                <input type="radio" name="resp4" v-model="resp4" value="4">Raramente <br>
                <input type="radio" name="resp4" v-model="resp4" value="5">Nunca <br>
                <div class="d-flex justify-content-end">
                    <button type="button" v-on:click="handleNext(resp4)" class="btn btn-primary">Próxima</button>
                </div>
            </div>
            <div v-if="pergunta === 5">
                <p>5. Qual sua opinião sobre a facilidade de uso do software? (Escala de 1 a 5, sendo 1 muito
                    difícil e 5 muito fácil)</p>
                <input type="radio" name="resp5" v-model="resp5" value="1">Muito Difícil: O Infoconsig é
                extremamente complicado de se utilizar, exigindo um esforço excepcional. <br>
                <input type="radio" name="resp5" v-model="resp5" value="2">Difícil: O Infoconsig é desafiador e
                exige bastante esforço para ser utilizado. <br>
                <input type="radio" name="resp5" v-model="resp5" value="3">Neutro: O Infoconsig não é nem fácil nem
                difícil de usar, requerendo um esforço moderado. <br>
                <input type="radio" name="resp5" v-model="resp5" value="4">Fácil: O Infoconsig é relativamente
                simples de ser utilizado, requerendo um esforço mínimo. <br>
                <input type="radio" name="resp5" v-model="resp5" value="5">Muito Fácil: O Infoconsig é extremamente
                simples e intuitivo, não exigindo praticamente nenhum esforço para ser utilizado. <br>
                <div class="d-flex justify-content-end">
                    <button type="button" v-on:click="handleNext(resp5)" class="btn btn-primary">Próxima</button>
                </div>
            </div>
            <div v-if="pergunta === 6">
                <p>6. Você já utilizou o suporte de atendimento do Infoconsig?</p>
                <input type="radio" name="resp6" v-model="resp6" value="1">Sim <br>
                <input type="radio" name="resp6" v-model="resp6" value="2">Não <br>
                <div class="d-flex justify-content-end">
                    <button type="button" v-on:click="handleNext(resp6)" class="btn btn-primary">Próxima</button>
                </div>
            </div>
            <div v-if="pergunta === 7">
                <p>6.1. Como você avalia o suporte ao cliente, se necessário? (Escala de 1 a 5, sendo 1 muito
                    insatisfatório e 5 muito satisfatório)</p>
                <input type="radio" name="resp61" v-model="resp61" value="1">Muito Insatisfatório: O suporte ao
                cliente foi muito abaixo das minhas expectativas e deixou muito a desejar. <br>
                <input type="radio" name="resp61" v-model="resp61" value="2">Quase sempre <br>
                <input type="radio" name="resp61" v-model="resp61" value="3">Insatisfatório: O suporte ao cliente
                não atendeu às minhas expectativas e houve problemas significativos. <br>
                <input type="radio" name="resp61" v-model="resp61" value="4">Neutro: O suporte ao cliente foi
                adequado, mas não excepcional. Minhas expectativas foram atendidas, mas nada mais. <br>
                <input type="radio" name="resp61" v-model="resp61" value="5">Satisfatório: O suporte ao cliente
                atendeu às minhas expectativas e fiquei contente com o serviço. <br>
                <input type="radio" name="resp61" v-model="resp61" value="6">Muito Satisfatório: O suporte ao
                cliente superou as minhas expectativas e fiquei extremamente satisfeito com o serviço. <br>
                <div class="d-flex justify-content-end">
                    <button type="button" v-on:click="handleNext(resp61)" class="btn btn-primary">Próxima</button>
                </div>
            </div>
            <div v-if="pergunta === 8">
                <p>7. Você recomendaria o Infoconsig para um amigo ou colega?</p>
                <input type="radio" name="resp7" v-model="resp7" value="1">Definitivamente sim <br>
                <input type="radio" name="resp7" v-model="resp7" value="2">Provavelmente sim <br>
                <input type="radio" name="resp7" v-model="resp7" value="3">Incerto <br>
                <input type="radio" name="resp7" v-model="resp7" value="4">Provavelmente não <br>
                <input type="radio" name="resp7" v-model="resp7" value="5">Definitivamente não <br>
                <div class="d-flex justify-content-end">
                    <button type="button" v-on:click="handleNext(resp7)" class="btn btn-primary">Próxima</button>
                </div>
            </div>

            <div v-if="pergunta === 9">
                <p>8. Por favor, forneça algum feedback adicional que você acredita que poderia melhorar a sua
                    experiência com o software.</p>
                <p>Resposta: </p><input type="text" v-model="resp8">
                <div class="d-flex justify-content-end">
                    <button type="button" v-on:click="submitResearch()" class="btn btn-primary">Próxima</button>
                </div>
            </div>
            <div v-if="pergunta === 10">
                <p>Obrigado por dedicar um tempo para preencher este formulário. Seu feedback é muito importante
                    para nós.</p>
                <div class="d-flex justify-content-end">
                    <button type="button" v-on:click="goHome" class="btn btn-primary">Fechar</button>
                </div>
            </div>
        </b-modal>


    </div>
</template>

<script>
import axios from 'axios';


const baseUrl = process.env.VUE_APP_BASE_URL;
const infoUrl = process.env.VUE_APP_INFOCONSIG_BASE_URL;


export default {
    name: 'MyUsers',
    data() {
        return {

            userList: [], // lista de usuários
            selectedUser: null, // usuário selecionado

            //pesquisa de satisfação
            pergunta: 0,
            resp1: '',
            resp2: '',
            resp3: '',
            resp4: '',
            resp5: '',
            resp6: '',
            resp61: '',
            resp7: '',
            resp8: '',
            selectedGroup: null,
            pesquisa: false,
            authToken: localStorage.getItem('authToken')
        };
    },
    components: {},
    mounted() {
        this.$termoUso();

        this.fetchMyUserData();

        this.$consultaUsuario();

    },
    methods: {
        async fetchMyUserData() {

            try {
                const authToken = localStorage.getItem('authToken');
                //alert(authToken);

                if (!authToken) {
                    // Tratar o caso em que o token não está presente
                    this.$router.push('/');
                }

                const response = await axios.post(baseUrl + 'v2/me', null, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });

                if (response.data.length == 1) {


                    //console.log(response.data[0])

                    const user = response.data[0]
                    console.log(user)
                    localStorage.setItem('cd_usuario', user.cd_usuario);
                    document.cookie = "pessoa=" + user.cd_usuario;
                    document.cookie = "pessoa=" + user.cd_usuario + "; path=/";
                    document.cookie = "token=" + this.authToken;
                    document.cookie = "token=" + this.authToken + "; path=/";
                    //alert(infoUrl);
                    //console.log(infoUrl);
                    location.href = infoUrl + "home";
                }

                if (response.data.length == 0) {
                    console.log('1');
                } else {
                    this.userList = response.data;
                }

                // Atualizar a lista de usuários com a resposta da solicitação HTTP

            } catch (error) {
                // Lidar com erros na solicitação HTTP
                console.error(error);
            }
        },

        selectUser() {


            if (this.selectedUser) {
                const authToken = localStorage.getItem('authToken');
                localStorage.setItem('cd_usuario', this.selectedUser);
                            document.cookie = "pessoa=" + this.selectedUser;
                            document.cookie = "pessoa=" + this.selectedUser + "; path=/";
                            document.cookie = "token=" + authToken;
                            document.cookie = "token=" + authToken + "; path=/";
                console.log(this.userList);
                this.goHome()

                // for(var index in this.userList ){
                //     let user = this.userList[index]
                //     if (user.cd_usuario === this.selectedUser){
                //         this.selectedGroup = user.cd_grupo
                //         break
                //     }
                // }

                // if (this.selectedGroup && this.selectedGroup !== 1) {
                //     axios.get(`${baseUrl}busca-pesquisas/${this.selectedGroup}`)
                //         .then(response => {

                //             if (response.data.length > 0) this.pesquisa = true

                //             localStorage.setItem('pesquisa_id', response.data.id)
                //             localStorage.setItem('cd_usuario', this.selectedUser);
                //             document.cookie = "pessoa=" + this.selectedUser;
                //             document.cookie = "pessoa=" + this.selectedUser + "; path=/";
                //             document.cookie = "token=" + authToken;
                //             document.cookie = "token=" + authToken + "; path=/";
                //             if (!this.pesquisa) return this.goHome()

                //             this.$bvModal.show('my-modal')
                //         })
                //         .catch(error => console.log(error))
                // }
                // Armazenar o cd_usuario selecionado no LocalStorage

                //alert(infoUrl);
                //console.log(infoUrl);


                //this.goHome()
            }
        },
        goHome() {
            location.href = `${infoUrl}home`
        },
        handleNext(resp) {
            if (resp === '') return
            if (this.pergunta === 6 && resp === '2') {
                this.pergunta = this.pergunta + 2
                return
            }
            this.pergunta++
        },
        submitResearch() {
            const data = {
                'user': localStorage.getItem('cd_usuario'),
                'pesquisaId': localStorage.getItem('pesquisa_id'),
                'respostas': {
                    '1': this.resp1,
                    '2': this.resp2,
                    '3': this.resp3,
                    '4': this.resp4,
                    '5': this.resp5,
                    '6': this.resp6,
                    '6.1': this.resp61,
                    '7': this.resp7,
                    '8': this.resp8,
                }
            }
            axios.post(`${baseUrl}responde-pesquisas`, data)
                .then(response => console.log(response))
                .catch(error => console.log(error))

            this.pergunta++
        }
    }
};
</script>

<style scoped>

</style>