<template>

  <div>
    <Menus></Menus>

    <div class="modal" tabindex="-1" role="dialog" v-if="showModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Título do Modal</h5>
            <button type="button" class="close" data-dismiss="modal" @click="closeModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Conteúdo do modal...</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">
              Fechar
            </button>
            <button type="button" class="btn btn-primary">Salvar mudanças</button>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 100px" class="container">
      <div>


        <div style="border-color: #1283ac;margin-bottom: 20px" class="card">
          <div class="card-body">

            <div class="text-center">
              <p>Olá!</p>
              <p>Disponibilizamos uma simulação que representa valores aproximados para uma contratação de
                empréstimo, após sua consulta você deverá confirmar valores com a instituição
                Financeira</p>
            </div>
          </div>


        </div>
        <div style="border-color: #1283ac" class="card">
          <div style="background-color: #1283ac;color: white" class="card-header">Digite os dados para a
            simulação
          </div>
          <div class="card-body">
            <div class="row" style="">
              <div class="col-md-3">
                <label>Prazo em Meses</label>
                <input v-mask="'###'" v-model="prazo" class="form-control">
              </div>
              <div class="col-md-3">
                <label>Valor Parcela</label>
                <input :disabled="valorFormatado !== null" v-model="valorParcelaFormatado" id="decimal1"
                  @input="formatarValor" @keydown="handleKeyDown" class="form-control" name="valorParcela">
              </div>
              <div class="col-md-3 text-center">
                <br>
                ou
              </div>
              <div class="col-md-3">
                <label>Valor Financiado</label>
                <input :disabled="valorParcelaFormatado !== null" v-model="valorFormatado" id="decimal2"
                  @input="formatarValor" @keydown="handleKeyDown" class="form-control" name="valorFinanciado">

              </div>

            </div>

            <div style="margin-top: 10px" class="container text-center">
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-6 text-right">
                  <button @click="limparCampos" class="btn btn-outline-danger">Limpar</button>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 text-left">
                  <button @click="buscarTaxas" class="btn btn-outline-primary">Simular
                  </button>
                </div>


              </div>
            </div>


          </div>


        </div>
        <div class="container">
        </div>
        <div v-if="vertabela" class="text-center table-responsive" style="margin-top: 30px">
          <h1 style="color: #1283ac">Simulação</h1>

          <table class="table table-striped">
            <thead style="background-color: #d9eaf4">
              <tr class="text-center" style="color:#1283ac;">
                <th></th>
                <th>Consignatária</th>
                <th>CET <br>
                  Anual

                </th>
                <th>Taxa <br>
                  de Juros %am
                </th>
                <th>Valor <br>
                  Financiado
                </th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="(simulation, index) in simulationData" :key="index" class="text-center">
                <td><input type="radio" :value="simulation" v-model="selectedSimulation" name="selectedSimulation"
                    id=""></td>
                <td>{{ simulation.bankName }}</td>
                <td>{{ simulation.cetPerMonth }}%</td>
                <td>{{ simulation.interestRateInPercent }}%</td>
                <td>R$ {{ simulation.financedAmountInCents }},00</td>

              </tr>


            </tbody>
          </table>

          <!-- <div style="margin: 10px" class="form-group">
            <button class="btn btn-primary btn-infoconsig" @click="solicitarProposta">Solicitar Proposta
            </button>
          </div> -->

        </div>
      </div>
    </div>


  </div>
</template>

<script>
//import axios from "axios";
import axios from "axios";
import Menus from "@/components/Menus.vue";
import { consultaUsuario } from '@/utils/global';

const baseUrl = process.env.VUE_APP_BASE_URL;
const authToken = localStorage.getItem('authToken');
//alert(authToken);


//const baseUrl = process.env.VUE_APP_BASE_URL;
export default {
  name: "TermoAceite",
  props: {
    initialValue: {
      type: Number,
      default: 0
    },

  },
  data() {
    return {
      prazo: null,
      valorParcela: 0,
      valorFinanciado: 0,
      priceInput: '',
      valorInputValue: '',
      valor: this.initialValue,
      valorParcelaInit: this.initialValue,
      vertabela: false,
      valorParcelaFormatado: null,
      valorFormatado: null,
      simulationData: [],
      escolhaProposta: null,
      selectedSimulation: null,
      showModal: false,
    };
  },
  methods: {


    formatarValor(event) {

      let id = event.target.id;
      let value = 0;
      if (id === "decimal1") {
        value = this.valorParcelaFormatado;
      } else {
        value = this.valorFormatado;
      }


      // Remover caracteres não numéricos, mantendo apenas números
      value = value.replace(/[^0-9]/g, '');

      // Adicionar um ponto decimal após duas casas decimais
      if (value.length > 2) {
        value = value.slice(0, -2) + ',' + value.slice(-2);
      }

      if (id === "decimal1") {
        this.valorParcelaFormatado = value;
      } else {
        this.valorFormatado = value;
      }
      // Atualizar o valor do input com a formatação

    },
    handleKeyDown(event) {


      // Reverter a direção da tecla Enter para permitir entrada da direita para a esquerda
      if (event.key === 'Enter') {
        event.preventDefault();
        this.valorParcelaFormatado = this.valorParcelaFormatado.split('').reverse().join('');
      }
    },

    /*s
    updateValor(event) {
        this.valorFormatado = event.target.value;
    },
    updateParcela(event) {
        this.valorParcelaFormatado = event.target.value;
    }, */


    limparCampos() {
      this.vertabela = false;
      this.prazo = null;
      this.valorParcelaFormatado = null;
      this.valorFormatado = null;
    },
    buscarTaxas() {
      let valor;
      valor = 0;
      let total = 0;
      if (this.valorFormatado != null) {
        valor = this.valorFormatado;
        total = 1;
      }

      if (this.valorParcelaFormatado != null) {
        valor = this.valorParcelaFormatado
      }
      const headers = {
        'Authorization': `Bearer ${authToken}`
      };
      const params = {
        prazo: this.prazo,
        valor: valor,
        cd_usuario: localStorage.getItem('cd_usuario'),
        total: total
      }
      axios.post(baseUrl + 'busca-taxas-simulador', params, {
        headers: headers
      })
        .then(response => {
          console.log(response)
          if (response.data.token) {
            const dados = {
              "token": authToken,
              "installmentsInMonths": this.prazo,
              "amountInCents": response.data.valor,
              "total":total
            };

            axios.post(baseUrl + 'pegartabela', dados)
              .then(response => {

                this.simulationData = response.data.data.simulations;
                this.vertabela = true;
                console.log(this.simulationData);
              })
              .catch(error => {
                console.error('Erro na requisição:', error);
              });


          } else {
            this.$swal.fire({
              icon: 'error',
              title: '',
              text: 'Dados incorretos',
              customClass: {
                confirmButton: 'my-custom-button-class'
              }
            });
          }

        })
        .catch(error => {
          console.error(error);
          //this.validCpf = false;
          //this.showPassword = false;
          //this.userName = '';
        });
    },
    solicitarProposta() {
      if (this.selectedSimulation) {
        const params = {
          total_parcelas: this.prazo,
          codigo_do_banco: this.selectedSimulation.bankCode,
          cet_anual: this.simulationData.cetPerMonth,
          cet_mensal: this.simulationData.interestRateInPercent,
          valor_financiado: this.selectedSimulation.amountReleasedInCents,
          p_nr_reg_serv: localStorage.getItem('cd_usuario')
          // ... other properties you want to include
        };

        this.showConfirmation(params);
        console.log(params);
        console.log("Selected simulation:", this.selectedSimulation);
        // You can perform further actions with the selected simulation data

      } else {
        console.log("No simulation selected");
        // Handle the case where no simulation is selected
        this.modalMessage = 'Nenhuma simulação selecionada.'; // Set error message
      }
    },

    showConfirmation(params) {
      console.log(params)
      this.$swal.fire({
        title: 'Ola',
        html: '<div style="text-align: justify;">' +
          '<p style="color: black;">' +
          'A consignatária irá receber seu pedido e irá entrar em contato com você para finalizar a operação.<br>' +
          'Caso não receba um retorno em 24hrs, você deve ligar para a Consignatária.<br>' +
          '<span style="color: #1283ac;">Você autoriza a Consignatária a consultar sua margem de consignação para fazer a proposta?</span>' +
          '</p>' +
          '</div>',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Autorizar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const authToken = localStorage.getItem('authToken');

          //  alert(cd_usuario);
          const headers = {
            'Authorization': `Bearer ${authToken}`
          };

          axios.post(baseUrl + 'solicitacao-nova-servidor', params, { headers: headers })
            .then(response => {
              console.log(response)
              if (response.data.success === true) {

                this.$swal.fire('Autorizado!', 'Ação autorizada com sucesso.', 'success');
                this.limparCampos();
                // this.emailList = response.data;
                //this.showEmailCampos = true
              } else {
                this.$swal.fire({
                  icon: 'error',
                  title: '',
                  text: 'Não foi possivel realizar operação',
                  customClass: {
                    confirmButton: 'my-custom-button-class'
                  }
                });
              }

            })
            .catch(error => {
              console.error(error);
              //this.validCpf = false;
              //this.showPassword = false;
              //this.userName = '';
            });
          // Ação a ser realizada ao clicar em "Autorizar"

        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          // Ação a ser realizada ao clicar em "Cancelar"
          this.$swal.fire('Cancelado', 'Ação cancelada.', 'error');
        }
      });
    },


  },
  mounted() {
    const resultado1 = consultaUsuario();
    console.log(resultado1);

  },

  computed: {}
  ,
  filters: {}
  ,
  components: { Menus }
}

</script>

<style scoped>
.termo {
  /* Largura do contêiner */
  background-color: rgba(208, 208, 208, 0.2);
  height: 300px;
  /* Altura do contêiner */
  overflow: auto;
  /* Habilita a barra de rolagem quando o conteúdo ultrapassar as dimensões do contêiner */
}

p {
  color: black;
}

.btn-infoconsig {
  background-color: #1283ac;
}


.table-striped tbody tr:nth-of-type(odd) {
  background-color: white;
  /* Cor de fundo para linhas ímpares */
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #d9eaf4;
  /* Cor de fundo para linhas pares */
}
</style>