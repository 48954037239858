<template>
<div>
  <Menus></Menus>
  <div class="container">

    <h2 class="title">Solicitação de Refinanciamento a partir de proposta</h2>
    <hr class="custom-line"/>

    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <GraficoMargem
            ref="graficoMargem"
            :cd_consignataria="parseInt(this.cd_consignataria)"
            :cd_servidor="cd_servidor"
        ></GraficoMargem>
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Dados para Contato</h5>
          </div>
          <div class="card-body">

            <label class="font-weight-bold">Telefone:</label> {{ this.telefone }}<br>
            <label class="font-weight-bold">Email:</label> {{ this.email }}<br>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="card card-primary">
          <div class="card-header">
            <h5 class="card-title">Informações do Funcionário</h5>
          </div>
          <div class="card-body">
            <label class="font-weight-bold">Consignante:</label> {{ this.nm_consignante }}<br>
            <label class="font-weight-bold">Averbador :</label> {{ this.nm_averbador }} <br>
            <label class="font-weight-bold">Nome: </label> {{ this.servidor }}<br>
            <label class="font-weight-bold">Matrícula: </label> {{ this.matricula }}<br>

            <label class="font-weight-bold">Data Admissão: </label> {{ this.dt_admissao }}<br>
            <label class="font-weight-bold">Regime:</label> {{ this.regime }}<br>
            <label class="font-weight-bold">Categoria:</label> {{ this.categoria }}<br>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-primary">
      <div class="card-header"><span class="text-white">Novas Condições</span></div>

      <table class="tb100pc">
        <tbody>
        <tr class="bg_azul">
          <td colspan="12" class="text-center">
            Contrato solicitado para Refinanciamento
          </td>
        </tr>
        <tr class="bg_title_tb text-center">
          <td></td>
          <td colspan="2"><label>Datas</label></td>
          <td colspan="4"><label>Parcelas (meses)</label></td>
          <td colspan="3"><label>Valores do Contrato (R$)</label></td>
        </tr>
        <tr class="bg_title_tb_yellow">
          <td class="text-center"><label>Solicitação</label></td>
          <td class="text-center"><label>Início</label></td>
          <td class="text-center"><label>Final</label></td>

          <td class="text-center"><label>Total</label></td>
          <td class="text-center"><label>Pagas</label></td>
          <td class="text-center"><label>A Pagar</label></td>
          <td class="text-center"><label>Valor(R$)</label></td>

          <td class="text-center"><label>Financiado</label></td>
          <td class="text-center"><label>Pago</label></td>
          <td class="text-center"><label>A Pagar</label></td>
        </tr>
        <tr>
          <td class="text-center">{{ solicitacao }}</td>
          <td class="text-center">{{ dt_inicial }}</td>
          <td class="text-center">{{ dt_final }}</td>
          <td class="text-center">{{ total_parcela }}</td>
          <td class="text-center">{{ parcela_pagas }}</td>
          <td class="text-center">{{ falta_pagar }}</td>
          <td class="text-center">{{ vl_parcela }}</td>
          <td class="text-center">{{ vl_financiado }}</td>
          <td class="text-center">{{ vl_pago }}</td>
          <td class="text-center">{{ vl_falta_pagar }}</td>

        </tr>
        </tbody>
      </table>
      <hr/>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <label class="">Saldo Disponível Refinanciamento:</label>
            <div class="input-group">
              <span class="input-group-text">R$</span>
              <input
                  class="form-control text-center"

                  type="text"
                  readonly="true"
                  v-model="margem_disponivel_novo_contrato"
              />
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <label>* Nº de Parcelas:</label>
            <input
                maxlength="3"
                size="3"
                class="form-control text-center"
                v-model="n_parcelas"

            />
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <label>* Valor da Parcela:</label>


            <div class="input-group">
              <span class="input-group-text">R$</span>
              <input
                  v-model="vl_nova_parcela"
                  class="form-control text-center"
                  type="text"
                  @input="formatarValor"
                  @keydown="handleKeyDown"
                  style="text-align: right;"
              />
            </div>
          </div>
        </div>
        <br>

        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <button @click="confirmRefinancingFromProposal"
                    class="btn btn-outline-primary btn-block">
              <i class="fas fa-check"></i> Confirmar
            </button>
          </div>
          <div class="col-md-4">
            <button
                class="btn btn-outline-danger btn-block">
              <i class="fas fa-times"></i> Cancelar
            </button>
          </div>
          <div class="col-md-2"></div>


        </div>

      </div>
    </div>

    <div
        class="row"
        style="
        padding: 5px;
        margin-bottom: 15px;
        border: 1px solid black;
        margin-left: 0px;
      "
    >
      <h5 class="card-title">dados</h5>
      <br/>
      <pre><br/>
        {{ dados }}
      </pre>
    </div>
    <div
        class="row"
        style="
        padding: 5px;
        margin-bottom: 15px;
        border: 1px solid black;
        margin-left: 0px;
      "
    >
      <h5>CONFIRM</h5>
      <pre>
        {{ response_confirm }}
      </pre>
    </div>
    <div
        class="row"
        style="
        padding: 5px;
        margin-bottom: 15px;
        border: 1px solid black;
        margin-left: 0px;
      "
    >
      <h5>CANCELA</h5>
      <pre>
        {{ response_reject }}
      </pre>
    </div>
  </div>
</div>

</template>

<script lang="ts">
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import GraficoMargem from "@/components/GraficoMargem.vue";
import Menus from "@/components/Menus.vue";


Vue.use(BootstrapVue);

const baseUrl = process.env.VUE_APP_BASE_URL;

export default Vue.extend({
  components: {
    GraficoMargem,
    Menus
  },
  data() {
    return {
      vl_nova_parcela: '',
      'n_parcelas':'',
      margem_disponivel_novo_contrato: "",
      margem_disponivel: "",
      vl_pago: "",
      vl_falta_pagar: "",
      vl_financiado: "",
      parcela_pagas: "",
      vl_parcela: "",
      falta_pagar: "",
      dt_inicial: "",
      dt_final: "",
      total_parcela: '',
      solicitacao: "",
      proposta: "",
      servidor: "",
      cpf: "",
      matricula: "",
      lotacao: "",
      valor: "",
      numeroParcelas: "",
      valorParcela: "",
      nm_averbador: "",
      nm_consignante: "",
      regime: "",
      categoria: "",
      telefone: "",
      email: "",
      cd_servidor: 0,
      cd_consignataria: 0,
      livre_porcentagem: 0,
      utilizada_porcentagem: 0,
      valor_livre: '',
      valor_utilizado: '',
      dt_admissao: '',
      valor_liberado: '',
      cet_mensal: '',
      cet_anual: '',
      dt_efetivacao: '',
      dt_validade: '',
      dados: "",
      data_frm_refin: [],
      data_frm_margem: [],
      response_confirm: "",
      response_reject: "",
    };
  },

  methods: {

    formatarValor() {
      let value = this.vl_nova_parcela;

      // Remover caracteres não numéricos, mantendo apenas números
      value = value.replace(/[^0-9]/g, '');

      // Adicionar um ponto decimal após duas casas decimais
      if (value.length > 2) {
        value = value.slice(0, -2) + ',' + value.slice(-2);
      }

      // Atualizar o valor do input com a formatação
      this.vl_nova_parcela = value;
    },
    handleKeyDown(event) {
      // Reverter a direção da tecla Enter para permitir entrada da direita para a esquerda
      if (event.key === 'Enter') {
        event.preventDefault();
        this.vl_nova_parcela = this.vl_nova_parcela.split('').reverse().join('');
      }
    },
    confirmRefinancingFromProposal() {

      let data = {
        'cd_proposta': this.proposta,
        'vl_nova_parcela': this.vl_nova_parcela,
        'n_parcelas':this.n_parcelas

      }
      console.log(data);
      axios
          .post(baseUrl + "confirm-proposta-refin", data)
          .then((response) => {
            this.response_confirm = response.data;
            if (response.data.errors) {
              let errorText = "";
              for (const [, value] of Object.entries(response.data.errors)) {
                errorText += value[0] + "<br>";
              }
              this.$swal.fire({
                icon: "error",
                title: "",
                html: errorText,
                customClass: {
                  confirmButton: "my-custom-button-class",
                },
              });
            }
            if (response.data.success) {
              this.$swal.fire({
                icon: "success",
                title: "",
                text: response.data.message,
                customClass: {
                  confirmButton: "my-custom-button-class",
                },
                didClose: () => {
                  window.parent.close();
                },
              });
            }
          })
          .catch((error) => console.log(error));
    },

    rejectRefinancingFromProposal(event) {
      event.preventDefault();
      const request = {
        proposta: this.cdProposta,
        action: "reject",
      };

      axios
          .post(baseUrl + "rejeita-proposta-refin", request)
          .then((response) => {
            this.response_reject = response.data;
            if (response.data.errors) {
              let errorText = "";
              for (const [, value] of Object.entries(response.data.errors)) {
                errorText += value[0] + "<br>";
              }
              this.$swal.fire({
                icon: "error",
                title: "",
                html: errorText,
                customClass: {
                  confirmButton: "my-custom-button-class",
                },
              });
            }
            if (response.data.success) {
              this.$swal.fire({
                icon: "success",
                title: "",
                text: response.data.message,
                customClass: {
                  confirmButton: "my-custom-button-class",
                },
                didClose: () => {
                  window.parent.close();
                },
              });
            }
          })
          .catch((error) => console.log(error));
    },




    getRenegotiaedLoanContract() {
      const request = {proposta: this.cdProposta};

      axios
          .post(baseUrl + "busca-proposta-refin", request)
          .then((response) => {
            if (response.data.errors) {
              let errorText = "";
              for (const [, value] of Object.entries(response.data.errors)) {
                errorText += value[0] + "<br>";
              }
              this.$swal.fire({
                icon: "error",
                title: "",
                html: errorText,
                customClass: {
                  confirmButton: "my-custom-button-class",
                },
              });
            } else {

              this.dados = response.data;

              const param = {
                cd_servidor: this.dados.cd_servidor,
                cd_consignataria: this.dados.cd_consignataria,
              };

              this.getAvailableMargin(param);

            }
          })
          .catch((error) => console.log(error));
    },


    async getPropostaDados() {
      try {
        const response = await axios.get(baseUrl + 'busca-solicitacao-refin/' + this.cdProposta);

        console.log(response);
        this.solicitacao = response.data.cd_solicitacao;
        this.dt_inicial = response.data.dt_inicial;
        this.dt_final = response.data.dt_final;
        this.total_parcela = response.data.nr_parcela;
        this.parcela_pagas = response.data.parcela_paga
        this.falta_pagar = response.data.falta_pagar
        this.vl_parcela = response.data.vl_parcela
        this.vl_financiado = response.data.vl_financiado
        this.vl_pago = response.data.vl_pago
        this.vl_falta_pagar = response.data.vl_falta_pagar
        this.margem_disponivel = response.data.margem.valor_no_format_disponivel
        console.log(response.data.vl_financiado);
        this.margem_disponivel_novo_contrato = response.data.margem_disponivel


      } catch (error) {
        console.log(error);
      }
    },
    async getPropostaServidor() {
      try {
        const response = await axios.get(baseUrl + 'busca-dados-refin/' + this.cdProposta);

        this.proposta = response.data.cd_proposta;
        this.servidor = response.data.servidor.pessoa.nm_pessoa;
        this.cpf = response.data.servidor.pessoa.nr_cpf;
        this.matricula = response.data.servidor.nr_matricula;
        this.lotacao = response.data.servidor.ds_lotacao;
        this.nm_averbador = response.data.servidor.averbador.nm_averbador;
        this.nm_consignante = response.data.servidor.consignante.nm_consignante;
        this.regime = response.data.servidor.regime.ds_regime_vinculo_trab;
        this.categoria = response.data.servidor.categoria.ds_situacao_categoria;
        this.telefone = '' + response.data.servidor.pessoa.nr_ddd_celular + "" + response.data.servidor.pessoa.nr_celular;
        this.email = response.data.servidor.pessoa.ds_email;
        this.cd_servidor = response.data.servidor.cd_servidor;
        this.cd_consignataria = response.data.cd_consignataria;
        this.dt_admissao = response.data.servidor.dt_admissao;

        // alert(this.cd_consignataria);

        // Agora, chame executarGetMargem

        if (this.cd_consignataria != 0) {
          await this.executarGetMargem();
        }

      } catch (error) {
        console.log(error);
      }
    },
    async executarGetMargem() {
      // Chame o método getMargem do componente GraficoMargem.vue
      await this.$nextTick();

      // Chame o método getMargem do componente GraficoMargem.vue

      await this.$refs.graficoMargem.getMargem();

    },
  },

  mounted() {
    this.getPropostaServidor()
    this.getPropostaDados()
    //this.getRenegotiaedLoanContract();

  },


  computed: {
    cdProposta() {
      return this.$route.params.cd_proposta;
    },


  },
});
</script>

<style scoped>
:root {
  --cem-porcento: 100%;
}

.page-wraper {
  width: 80%;
  margin: 0 auto;
}

.custom-line {
  border: none;
  border-top: 1px solid #1384ad;
  margin: 10px 0;
}

.card {
  margin-bottom: 30px;
}

.card-header {
  background-color: #1384ad;
}

.card-header > h5 {
  color: white;
}

.card-primary {
  border-color: #337ab7;
}

.card-body-table {
  flex: 1 1 auto;
  padding: 0rem 0rem 1.25rem 0rem;
}

.tb100pc {
  width: 100%;
}

.tb100pc th td,
.tb100pc tr td,
.tb100pc caption {
  font-weight: normal;
  padding: 5px;
}

.tb100pc tr.bg_title_tb {
  background-color: #71b5ce;
  color: #f1f5f8;
  font-weight: bold;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.tb100pc tr.bg_title_tb_yellow {
  background-color: #ffeabf;
  font-size: 14px;
}

.row0 {
  background-color: #ecf6fe;
}

.row1 {
  background-color: #e0effc;
}

.white-li li {
  color: black;
}


</style>
