<template>
  <div>


    <br/>


  </div>

</template>


<script>

//import axios from 'axios';

const baseUrl = process.env.VUE_APP_BASE_URL;

const baseUrlInfo = process.env.VUE_APP_INFOCONSIG_BASE_URL;
import axios from "axios";

export default {

  data() {
    return {}
  },

  methods: {

    async getMenu(){
      const authToken = localStorage.getItem('authToken');
      //const cd_usuario = localStorage.getItem('cd_usuario');

      //  alert(cd_usuario);
      const headers = {
        'Authorization': `Bearer ${authToken}`
      };
      const response = await axios.get(`${baseUrl}valida-menu/${this.cdMenu}`,{headers:headers})
      console.log(baseUrlInfo,response.data);

      //alert(response);
      window.location.replace(`${baseUrlInfo}${response.data}`);

      //window.location = `${baseUrlInfo}${response.data}`;

    }
  },
  mounted() {

    this.getMenu()
    //alert(this.cdMenu)
  },
  computed: {
    cdMenu() {
      return this.$route.params.cd_menu;
    }
  }


};

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.card {
  margin-bottom: 30px;
}

span {
  color: black;
}

.card-header {
  background-color: #1384ad;
}


.card-header > h5 {
  color: white;
}

.card-primary {
  border-color: #337ab7;
}

.form-control {

  border: 2px solid #1384ad; /* Largura e cor da borda desejadas */

}

.custom-file {
  border: 2px solid #1384ad;
}

.multiselect {

}

.multiselect__tags {
  border: 2px solid #1384ad;

  border: 2px solid #1384ad;

}

.btn {


}

multiselect__option--highlight {
  background-color: #1384AD;
}

.btn-primary {
  background-color: #1384AD;
}

.btn-outline-primary {
  border-color: #1384AD;
}

.btn-primary:hover {
  background-color: #1384AD;
}

</style>