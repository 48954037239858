<template>
  <div>
    <Menus></Menus>

    <br/>


    <div class="container">
      <h2 class="title">PROCESSOS JUDICIAIS</h2>
      <br><br>
      <div class="row">
        <div class="col-md-6">
          <button :class="{ 'btn-info': currentTab === 1, 'btn-secondary': currentTab !== 1 }" class="btn btn-block"
                  @click="selectTab(1)">Consultar
          </button>
        </div>
        <div class="col-md-6">
          <button :class="{ 'btn-info': currentTab === 2, 'btn-secondary': currentTab !== 2 }" class="btn btn-block"
                  @click="selectTab(2)">Adicionar
          </button>
        </div>
      </div>

      <br>
      <br>
      <br>
      <div v-if="activeTab === 1">
        <!-- Content for Tab 1 -->
        <div class="row">
          <div class="col-md-6">
            <div class="container">
              <div class="card card-primary">
                <div style="height: 50px" class="card-header">

                </div>
                <div class="card-body">


                  <div class="container">
                    <div class="form-group row">
                      <label class="col-md-3" for="email">Servidor</label>
                      <div class="col-md-9">
                        <multiselect
                            v-model="selectedOption1"
                            :options="apiOptions1"
                            label="nm_pessoa"
                            track-by="cd_servidor"
                            placeholder="Selecione uma opção"
                            @search-change="handleSearchChange1"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3" for="email">CPF</label>
                      <div class="col-md-9">
                        <input v-model="cpfCon" type="text" class="form-control" id="">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3" for="email">Matrícula</label>

                      <div class="col-md-9">
                        <multiselect
                            v-model="selectedMatriculaConsulta"
                            :options="opcoesMatriculasConsulta"
                            label="label"
                            track-by="id"
                            placeholder="Selecione uma matrícula"
                            :searchable="false"
                        ></multiselect>
                      </div>

                    </div>
                    <div class="form-group row">
                      <label class="col-md-3" for="email">Consignatária</label>
                      <div class="col-md-9">
                        <multiselect
                            v-model="selectConsignatariaConsulta"
                            :options="opçoesConsignataria"
                            label="nm_fantasia"
                            track-by="cd_consignataria"
                            placeholder="Selecione uma consignataria"
                            :searchable="true"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-3" for="email">Período</label>
                      <div class="col-md-9">
                        <div>
                          <div class="row">
                            <input v-model="inicio" type="date" class="form-control col-md-4">
                            <label class="col-md-4 text-center" for="email">até</label>
                            <input v-model="fim" type="date" class="form-control col-md-4">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-md-12">
                        <button @click="postPesquisa" class="btn btn-primary">Pesquisar</button>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <table class="table table-striped">
              <thead>
              <tr style="color: white;background-color: #1384AD">
                <th>Servidor</th>
                <th>CPF</th>
                <th>Matrícula</th>
                <th>Consignatária</th>
                <th>Data</th>
                <th>Processo nº</th>
                <th>Descrição</th>
                <th>Documento</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="resultado in resultados" :key="resultado.id">
                <td>{{ resultado.servidor.pessoa.nm_pessoa }}</td>
                <td>{{ resultado.servidor.pessoa.nr_cpf }}</td>
                <td>{{ resultado.servidor.nr_matricula }}</td>
                <td>{{ resultado.consignataria.nm_fantasia }}</td>
                <td>{{ resultado.dataFormatada }}</td>
                <td>{{ resultado.numero_processo }}</td>
                <td>{{ resultado.descricao }}</td>
                <td>
                  <a target="_blank" :href="baseUrl+resultado.arquivo">
                    <font-awesome-icon :icon="['fas', 'file']"/>
                  </a>
                </td>

                <!-- Adicione mais colunas conforme necessário -->
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 2">
        <div class="col-md-6">
          <div class="container">
            <div class="card card-primary">
              <div style="height: 50px" class="card-header">

              </div>
              <div class="card-body">


                <div class="container">
                  <div class="form-group row">
                    <label class="col-md-3" for="email">Servidor</label>

                    <div class="col-md-9">
                      <multiselect
                          v-model="selectedOption2"
                          :options="apiOptions2"
                          label="nm_pessoa"
                          track-by="cd_servidor"
                          placeholder="Selecione uma opção"
                          @search-change="handleSearchChange2"

                      ></multiselect>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3" for="email">CPF</label>
                    <div class="col-md-9">
                      <input type="text" v-model="cpfCad" class="form-control" id="cpfCad">
                    </div>

                  </div>
                  <div class="form-group row">
                    <label class="col-md-3" for="email">Matrícula</label>
                    <div class="col-md-9">
                      <multiselect
                          v-model="selectedMatricula"
                          :options="opcoesMatriculas"
                          label="label"
                          track-by="id"
                          placeholder="Selecione uma matrícula"
                          :searchable="false"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3" for="email">Consignatária</label>
                    <div class="col-md-9">
                      <multiselect
                          v-model="selectConsignatariaCad"
                          :options="opçoesConsignataria"
                          label="nm_fantasia"
                          track-by="cd_consignataria"
                          placeholder="Selecione uma consignataria"
                          :searchable="true"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3" for="email">Data</label>
                    <div class="col-md-9">
                      <input type="date" v-model="dataCad" class="form-control" id="data">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3" for="email">Processo n°</label>
                    <div class="col-md-9">

                      <input type="text" v-model="processo" class="form-control">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-3" for="email">Descrição</label>
                    <div class="col-md-9">

                      <textarea class="form-control" v-model="descricao" cols="30" rows="10"></textarea>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="customFile"
                             @change="handleFileChange"/>
                      <label class="custom-file-label" for="customFile">Selecionar Arquivo</label>
                    </div>

                  </div>
                  <div class="form-group">
                    <div class="row text-center">
                      <div class="col">
                        <button class="btn btn-outline-primary btn-block">Limpar</button>
                      </div>
                      <div class="col">
                        <button @click="postCadProcesso()" :disabled="!isFormValid" class="btn btn-primary btn-block">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

  </div>

</template>


<script>
import Menus from "@/components/Menus.vue";
import Multiselect from "vue-multiselect";
import axios from 'axios';

const baseUrl = process.env.VUE_APP_BASE_URL;


export default {
  components: {Menus, Multiselect},
  data() {
    return {
      currentTab: 1,
      baseUrl: process.env.VUE_APP_BASE_URL_DOCUMENTO,
      name: 'ConsultaProcesso',
      resultados: null,
      selectedOption1: null,
      selectedOption2: null,
      apiOptions1: [],
      apiOptions2: [],
      searchQuery1: '',
      searchQuery2: '',
      selectConsignatariaCad: [],
      selectConsignatariaConsulta: null,
      opçoesConsignataria: [],
      selectedMatriculaConsulta: null,
      opcoesMatriculasConsulta: [],
      dataCad: '',
      processo: '',
      descricao: '',
      cpfCad: '',
      cpfCon: '',
      opcoesMatriculas: [],
      selectedMatricula: [],
      file: [],
      inicio: null,
      fim: null,
      servidor: null,
      activeTab: 1,

    };
  },
  watch: {
    selectedOption2: function (newVal) {
      // A função watch é chamada quando selectedOption1 muda
      if (newVal) {
        this.getCpfServidor();
      }
    },
    selectedOption1: function (newVal) {
      // A função watch é chamada quando selectedOption1 muda
      if (newVal) {
        this.getCpfServidorConsulta();
      }
    },
  },
  methods: {
    selectTab(tabNumber) {
      this.activeTab = tabNumber;
      this.currentTab = tabNumber;
    },
    handleFileChange(event) {
      this.file = event.target.files[0];


      const file = this.file;


      // Faça o que precisar com o arquivo, como enviar para o servidor, etc.
      console.log('Arquivo selecionado:', file);

      // Atualize o rótulo do arquivo com o nome do arquivo selecionado (opcional)
      const fileName = file ? file.name : 'Selecionar Arquivo';
      document.querySelector('.custom-file-label').innerText = fileName;
    },
    async fetchData1() {
      try {
        const response = await axios.get(baseUrl + 'consulta-name', {
          params: {query: this.searchQuery1},
        });
        this.apiOptions1 = response.data;
      } catch (error) {
        console.error('Erro ao buscar dados da API 1', error);
      }
    },
    async fetchData2() {
      try {
        const response = await axios.get(baseUrl + 'consulta-name', {
          params: {query: this.searchQuery2},
        });
        this.apiOptions2 = response.data;
      } catch (error) {
        console.error('Erro ao buscar dados da API 2', error);
      }

    },
    handleSearchChange1(query) {
      this.searchQuery1 = query;
      // Verifica se a consulta possui pelo menos 5 caracteres antes de fazer a solicitação à API
      if (query.length >= 5) {
        this.fetchData1();
      }
    },
    handleSearchChange2(query) {
      this.searchQuery2 = query;
      // Verifica se a consulta possui pelo menos 5 caracteres antes de fazer a solicitação à API
      if (query.length >= 5) {
        this.fetchData2();
        //alert('aqui');
      }
    },

    async getCpfServidor() {
      try {
        const response =
            axios.get(baseUrl + 'busca-pessoa-cpf/' + this.selectedOption2.cd_pessoa)
                .then(response => {
                  this.cpfCad = response.data.cpf;

                  console.log(response.data.matriculas);

                  if (response.data.matriculas.length > 1) {

                    this.opcoesMatriculas = response.data.matriculas;
                    this.selectedMatricula = response.data.matriculas[0].id;

                    console.log(this.selectedMatricula)
                  } else if (response.data.matriculas.length === 1) {
                    // Lida com o caso em que há apenas uma matrícula
                    this.selectedMatricula = response.data.matriculas[0]
                    this.opcoesMatriculas = response.data.matriculas[0];
                  }
                })
                .catch(error => {
                  console.error('Erro ao obter matrículas', error);
                });
        //const consultaMatriculas = await axios.get(baseUrl + 'busca-pessoa-matricula/' +this.selectedOption2.cd_pessoa);
        console.log(response);
        this.cpfCad = response.data
      } catch (error) {
        console.log(error);
      }

      // Lógica para obter CPF do servidor e realizar ações necessárias
      //console.log('CPF do servidor:', this.selectedOption2.cd_pessoa);
      // Adicione aqui o código que você deseja executar quando o CPF do servidor é obtido
    },
    async getCpfServidorConsulta() {
      try {
        const response =
            axios.get(baseUrl + 'busca-pessoa-cpf/' + this.selectedOption1.cd_pessoa)
                .then(response => {
                  this.cpfCon = response.data.cpf;

                  console.log(response.data);

                  if (response.data.matriculas.length > 1) {

                    this.opcoesMatriculasConsulta = response.data.matriculas;
                    this.selectedMatriculaConsulta = response.data.matriculas[0].id;


                  } else if (response.data.matriculas.length === 1) {
                    // Lida com o caso em que há apenas uma matrícula
                    this.selectedMatriculaConsulta = response.data.matriculas[0]
                    this.opcoesMatriculasConsulta = response.data.matriculas[0];
                  }
                })
                .catch(error => {
                  console.error('Erro ao obter matrículas', error);
                });
        //const consultaMatriculas = await axios.get(baseUrl + 'busca-pessoa-matricula/' +this.selectedOption2.cd_pessoa);
        console.log(response);
        this.cpfCad = response.data
      } catch (error) {
        console.log(error);
      }

      // Lógica para obter CPF do servidor e realizar ações necessárias
      //console.log('CPF do servidor:', this.selectedOption1.cd_pessoa);
      // Adicione aqui o código que você deseja executar quando o CPF do servidor é obtido
    },

    async getConsignataia() {
      try {
        const response =
            axios.get(baseUrl + 'consignatarias')
                .then(response => {
                  console.log(response)
                  this.opçoesConsignataria = response.data;
                })
                .catch(error => {
                  console.error('Erro ao obter matrículas', error);
                });

        console.log(response);

      } catch (error) {
        console.log(error);
      }


    },

    postPesquisa() {

      if (this.selectedMatriculaConsulta) {
        this.servidor = this.selectedMatriculaConsulta.id
      }
      const request = {

        servidor: this.servidor,
        consignataria: this.selectConsignatariaConsulta !== null ? this.selectConsignatariaConsulta.cd_consignataria : null,
        pessoa: this.selectedOption1 !== null ? this.selectedOption1.cd_pessoa : null,
        inicio: this.inicio,
        fim: this.fim

      };

      axios.post(`${baseUrl}consulta-processo`, request, {})
          .then(response => {
            console.log(response)
            this.resultados = response.data;
            if (response.data.success) {
              this.$swal.fire({
                icon: 'success',
                title: '',
                text: response.data.message
              })


              //window.location.reload();

            }
          })
          .catch(error => console.log(error))


      console.log(request);
    },
    postCadProcesso() {
      const request = {
        servidor: this.selectedMatricula.id,
        consignataria: this.selectConsignatariaCad.cd_consignataria,
        data: this.dataCad,
        processo: this.processo,
        descricao: this.descricao,
        arquivo: this.file,

      };

      axios.post(`${baseUrl}cad-consulta-processo`, request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then(response => {
            console.log(response)
            if (response.data.success) {
              this.$swal.fire({
                icon: 'success',
                title: '',
                text: response.data.message
              })

              this.selectedMatricula = null;
              this.selectConsignatariaCad = null;
              this.dataCad = null;
              this.processo = null;
              this.descricao = null;
              //window.location.reload();

            }
          })
          .catch(error => console.log(error))
      console.log(request)
    }
  },
  mounted() {
    this.getConsignataia()
  },
  computed: {
    // Use uma propriedade computada para determinar se o formulário é válido
    isFormValid() {
      return (
          this.selectedMatricula &&
          this.selectConsignatariaCad &&
          this.dataCad &&
          this.processo &&
          this.descricao
      );
    },
  }

};

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.card {
  margin-bottom: 30px;
}

span {
  color: black;
}

.card-header {
  background-color: #1384ad;
}


.card-header > h5 {
  color: white;
}

.card-primary {
  border-color: #337ab7;
}

.form-control {

  border: 2px solid #1384ad; /* Largura e cor da borda desejadas */

}

.custom-file {
  border: 2px solid #1384ad;
}

.multiselect {

}

.multiselect__tags {
  border: 2px solid #1384ad;

  border: 2px solid #1384ad;

}

.btn {


}

multiselect__option--highlight {
  background-color: #1384AD;
}

.btn-primary {
  background-color: #1384AD;
}

.btn-outline-primary {
  border-color: #1384AD;
}

.btn-primary:hover {
  background-color: #1384AD;
}

</style>