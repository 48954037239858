<script>

import AjudaModal from "@/components/AjudaModal.vue";

export default {
  components: {AjudaModal}
}
</script>

<template>
  <div class="container centralizar">

    <div class="container">
      <div class="row">
        <div class="col-md-7 card-slide">
          <div class="container">
            <img style="margin-top: 30px" class="img-fluid" src="img/logo.png"/>
          </div>
          <slot name="esquerdo"></slot>


          <AjudaModal></AjudaModal>
        </div>
        <div class="col-md-5 card-login">
          <slot style="height: 20px;margin-top: 12px" name="voltar"></slot>
          <div class="text-center">


          </div>

          <slot name="direito"></slot>
          <div class="footer">
            <p class="direito"> © 2024. Sttórico Sistemas Ltda. Todos os direitos reservados.</p>
          </div>


        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');





p {
  color: white;
}

.username {
  color: #FFF;

  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.text-ajuda {
  color: #1384AD;
  font-family: "Nunito Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
}

.text-conectar {
  color: #0E1726;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (min-width: 544px) {
  .card-slide {
    width: 543px;
    height: 417px;
    flex-shrink: 0;
    border-radius: 20px 0px 0px 20px;
    background: #FBFBFB;
    box-shadow: 10px 10px 20px -10px #000; /* largura do card */
  }
}

.card-slide {
  z-index: 1;
  height: 550px;
  flex-shrink: 0;
  border-radius: 20px 0px 0px 20px;
  background: #FBFBFB;
  box-shadow: 10px 10px 20px -10px #000; /* largura do card */
}

.card-login {

  z-index: 0;

  height: 550px;
  flex-shrink: 0;
  border-radius: 0px 20px 20px 0px;
  background: #1384AD;
  box-shadow: 10px 10px 20px -10px #000;
}

.welcome {
  color: #FFF;
  font-family: "Nunito Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.logo-outline {
  margin-top: 30px;
  width: 100px;

  flex-shrink: 0;
}

.logo {
  margin-top: 43px;
  margin-left: 44px;
}

label {
  color: #FFF;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.direito {
  color: #BFC9D4;
  font-family: "Nunito Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  text-align: center;
  padding: 10px;
}

.ola {
  color: #FFF;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.ola, .username {
  display: inline-block;
  vertical-align: bottom; /* Alinha as divs verticalmente no topo */
  margin-right: 10px; /* Espaçamento entre as divs */
}

.funcionamento {
  margin-left: 107px;
  width: 281px;
  height: 48px;
  flex-shrink: 0;
  color: #0E1726;

  font-family: "Nunito Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.forget {
  color: #FFF;

  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.centralizar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

</style>