<template>
  <div>
    <Menus></Menus>
    <br>
    <b-modal id="edit-menu" hide-footer>
      <template #modal-title>
        Editar Menu
      </template>
      <div class="d-block text-center">
        <div class="form-group">
          <label for="">Nome Menu</label>
          <input class="form-control" v-model="nomeEdit">
        </div>
        <div class="form-group">
          <label for="">Url</label>
          <input class="form-control" v-model="urlEdit">
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="">Ativo</label>
            <b-form-checkbox size="lg" v-model="ativoEdit" name="check-button" switch>

            </b-form-checkbox>
          </div>
          <div class="form-group col-md-4">
            <label for="">Visivel</label>
            <b-form-checkbox size="lg" v-model="visivelEdit" name="check-button" switch>

            </b-form-checkbox>
          </div>
          <div class="form-group col-md-4">
            <label for="">Vue</label>
            <b-form-checkbox size="lg" v-model="vueEdit" name="check-button" switch>

            </b-form-checkbox>
          </div>
        </div>
      </div>
      <b-button class="mt-3" block>Close Me</b-button>
    </b-modal>
    <div class="container">
      <div class="card">
        <div class="card-header"><h5>Cadastro de Menu</h5></div>
        <div class="card-body">
          <div class="form-group">
            <label for="">Nome Menu</label>
            <input v-model="nome" class="form-control">
          </div>
          <div class="form-group">
            <label for="">Menu Pai</label>
            <multiselect
                v-model="selectMenuConsulta"
                :options="opçoesMenus"
                label="nm_menu"
                track-by="cd_menu"
                placeholder="Selecione menu pai"
                :searchable="true"
            ></multiselect>
          </div>
          <div class="form-group">
            <label for="">Url</label>
            <input v-model="urlmenu" class="form-control">
          </div>
          <div class="form-group">
            <label for="">Ordem</label>
            <input v-model="ordem" type="number" class="form-control">
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <label for="">Visivel</label>
              <b-form-checkbox size="lg" v-model="visivel" name="check-button" switch>

              </b-form-checkbox>
            </div>
            <div class="form-group col-md-4">
              <label for="">Ativo</label>
              <b-form-checkbox size="lg" v-model="ativo" name="check-button" switch>

              </b-form-checkbox>
            </div>
            <div class="form-group col-md-4">
              <label for="">Vue</label>
              <b-form-checkbox size="lg" v-model="vue" name="check-button" switch>

              </b-form-checkbox>
            </div>
          </div>
          <div class="form-group">
            <button @click="gravarMenu" class="btn btn-block btn-primary">Cadastrar</button>
          </div>

        </div>
      </div>

      <div class="card">

        <table ref="dataTable" class="table table-striped">
          <thead>
          <tr class="text-center" style="color: white;background-color: #1384AD">
            <th>ID</th>
            <th>Menu</th>
            <th>Menu Pai</th>
            <th>Descrição</th>

            <th>url</th>
            <th>Ação</th>
          </tr>
          </thead>
          <tbody>
          <tr class="text-center" v-for="resultado in resultados" :key="resultado.id">
            <td>{{ resultado.nm_menu }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>

          </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>

</template>

<script>


import Menus from "@/components/Menus.vue";
import axios from "axios";
import $ from "jquery";
import Swal from 'sweetalert2'
import Multiselect from "vue-multiselect";

// import $ from "jquery";

const baseUrl = process.env.VUE_APP_BASE_URL;
const authToken = localStorage.getItem('authToken');
const headers = {
  'Authorization': `Bearer ${authToken}`
};
export default {
  data() {
    return {
      diretorio: "",
      resultados: [],
      dataTable: null,
      opçoesMenus: [],
      ordem: 0,
      ativo: 0,
      vue: 0,
      visivel: 0,
      selectMenuConsulta: null,
      urlmenu: '',
      nome: null,
      nomeEdit: null,
      ativoEdit: false,
      vueEdit: false,
      visivelEdit: false,
      urlEdit: '',
    }
  },


  name: 'TelaDiretorio',
  components: {Multiselect, Menus},
  methods: {
    limpar() {
      this.diretorio = '';
    },
    async getMenus() {


      //  alert(cd_usuario);

      const response = await axios.get(`${baseUrl}listar-menus`, {headers: headers})
      this.resultados = response.data
      this.opçoesMenus = response.data
      console.log(this.opçoesMenus)
    },

    initializeDataTable() {
      // Salve a referência à instância do Vue

      const self = this;
      // Inicialize o DataTable dentro do hook mounted
      if ($.fn.DataTable.isDataTable(this.$refs.dataTable)) {
        // Destroi a instância DataTable existente antes de inicializar novamente
        $(this.$refs.dataTable).DataTable().destroy();
      }

      // Inicialize o DataTable
      this.dataTable = $(this.$refs.dataTable).DataTable({
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Portuguese-Brasil.json'
        },
        columns: [
          {data: 'cd_menu'},
          {data: 'nm_menu'},
          {
            data: null,
            render: function (data) {
              // Verifica se o menu_pai existe e tem o atributo nm_menu
              if (data.menu_pai && data.menu_pai.nm_menu) {
                return data.menu_pai.nm_menu;
              } else {
                return '-';
              }
            }
          },

          {data: 'ds_alt'},
          {data: 'ds_url'},
          {
            data: null,
            render: function () {
              return '<button class="btn btn-xs btn-outline-warning">Editar</button>';
            },
            createdCell: function (cell) {
              // Adiciona um ouvinte de eventos de clique usando a referência à instância do Vue
              $(cell).on('click', function () {
                self.getEdit($(this).closest('tr').find('td').eq(0).text());
              });
            },
          }
        ],
      });
    },
    async getEdit(escolha) {
      const response = await axios.get(`${baseUrl}busca-menu/` + escolha, {headers: headers});
      console.log(response);
      this.nomeEdit = response.data.nm_menu
      this.urlEdit = response.data.ds_url
      this.ativoEdit = response.data.id_ativo === "1" ? true : false;
      this.visivelEdit = response.data.id_visivel === "1" ? true : false;
      this.vueEdit = response.data.vue === "1" ? true : false;

      this.$bvModal.show('edit-menu');
      //alert(escolha)
    },

    async gravarMenu() {

      // Exibindo o alerta de carregamento
      Swal.fire({
        title: 'Carregando...',
        html: '<div class="loading-container"><div class="loading-spinner"></div></div>',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });

      try {
        // Parâmetros para a requisição POST
        const params = {
          cd_menu_pai: this.selectMenuConsulta ? this.selectMenuConsulta.cd_menu : 0,
          ativo: this.ativo,
          visivel: this.visivel,
          nome: this.nome,
          urlmenu: this.urlmenu,
          ordem: this.ordem,
          vue: this.vue
        };

        console.log(params)
        // Realizando a requisição POST
        const response = await axios.post(`${baseUrl}grava-menu`, params, {headers: headers});
        // this.diretorio = ''
        // Lógica após a resposta bem-sucedida
        console.log(response.data); // Exemplo de como utilizar os dados da resposta
        Swal.fire({
          title: 'Sucesso!',
          text: 'O menu foi gravado com sucesso.',
          icon: 'success',
          timer: 3000 // Tempo em milissegundos (3 segundos)
        }).then(() => {
          // Fechando o alerta de carregamento após exibir a mensagem de sucesso
          Swal.close();
          this.getMenus()
        });

      } catch
          (error) {
        // Lógica para lidar com erros
        console.error('Erro ao gravar o diretório:', error);
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao gravar o diretório.',
          icon: 'error'
        }).then(() => {
          // Fechando o alerta de carregamento após exibir a mensagem de erro
          Swal.close();
        });
      }
    }


  },

  watch: {
    resultados: {
      handler() {
        // Atualize o DataTable quando a lista de resultados mudar
        if (this.dataTable) {
          this.dataTable.clear().rows.add(this.resultados).draw();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getMenus(); // Carrega os dados iniciais
    this.initializeDataTable(); // Carrega os dados iniciais
  },
  mounted() {
    this.initializeDataTable();

    this.getMenus();
  },

};


</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>

#myChart-legend {
  font-size: 70px; /* Ajuste o tamanho da fonte conforme necessário */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-header {
  background-color: #1384ad;
}


.card-header > h5 {
  color: white;
}

</style>