<template>
  <div class="container">

    <h2 class="title">Solicitação de Informação de Saldo</h2>
    <br/>
    <b-card no-body>
      <template v-slot:header>

        <div class="d-flex justify-content-between align-items-center">
          <span class="text-white">Dados do Servidor</span>
          <b-button @click="toggleCollapse('collapse1')" variant="transparent">
            <b-icon variant="white" :icon="isOpen['collapse1'] ? 'chevron-up' : 'chevron-down'"
                    aria-hidden="true"></b-icon>
          </b-button>
        </div>
      </template>
      <b-collapse id="collapse1" v-model="isOpen['collapse1']">
        <b-card-body>
          <table class="tb100pc">
            <tbody>
            <tr>
              <td>Servidor:</td>
              <td>
                <input
                    type="text"
                    name="DS_SERVIDOR"
                    id="DS_SERVIDOR"
                    class="form-control"
                    v-model="dados.ds_servidor"
                    title=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
              <td>Matrícula:</td>
              <td>
                <input
                    type="text"
                    name="NR_MATRICULA"
                    id="NR_MATRICULA"
                    class="form-control"
                    v-model="dados.nr_matricula"
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
            </tr>
            <tr>
              <td>Regime Trabalho:</td>
              <td>
                <input
                    type="text"
                    name="ID_REGIME_TRABALHO"
                    id="ID_REGIME_TRABALHO"
                    class="form-control"
                    v-model="dados.ds_regime_trabalho"
                    title=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
              <td>CPF:</td>
              <td>
                <input
                    type="text"
                    name="NR_CPF"
                    id="NR_CPF"
                    class="form-control"
                    v-model="dados.nr_cpf"
                    title=""
                    readonly="readonly"
                />
              </td>
            </tr>
            <tr>
              <td>Categoria:</td>
              <td>
                <input
                    type="text"
                    name="DS_CATEGORIA"
                    id="DS_CATEGORIA"
                    class="form-control"
                    v-model="dados.ds_categoria"
                    title=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
              <td>Lotação:</td>
              <td>
                <input
                    type="text"
                    name="DS_LOTACAO"
                    id="DS_LOTACAO"
                    class="form-control"
                    v-model="dados.ds_lotacao"
                    title=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
            </tr>
            <tr>
              <td>Órgão:</td>
              <td>
                <input
                    type="text"
                    name="DS_ORGAO"
                    id="DS_ORGAO"
                    class="form-control"
                    v-model="dados.ds_orgao"
                    title=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
              <td>Averbador:</td>
              <td>
                <input
                    type="text"
                    name="NM_AVERBADOR"
                    id="NM_AVERBADOR"
                    class="form-control"
                    v-model="dados.nm_averbador"
                    title=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
            </tr>
            <tr>
              <td>Consignante:</td>
              <td>
                <input
                    type="text"
                    name="DS_CONSIGNANTE"
                    id="DS_CONSIGNANTE"
                    class="form-control"
                    v-model="dados.ds_consignante"
                    title=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
              <td>Data Admissão:</td>
              <td>
                <input
                    type="text"
                    name="DT_ADMISSAO"
                    id="DT_ADMISSAO"
                    class="form-control"
                    v-model="dados.dt_admissao"
                    title=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body>
      <template v-slot:header>
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-white">Dados do Contrato</span>
          <b-button @click="toggleCollapse('collapse2')" variant="transparent">
            <b-icon variant="white" :icon="isOpen['collapse2'] ? 'chevron-up' : 'chevron-down'"
                    aria-hidden="true"></b-icon>
          </b-button>
        </div>
      </template>
      <b-collapse id="collapse2" v-model="isOpen['collapse2']">
        <b-card-body>
          <table class="tb100pc">
            <tbody>
            <tr>
              <td>Consignatária:</td>
              <td colspan="3">
                <input
                    type="text"
                    name="DS_CONSIGNATARIA"
                    id="DS_CONSIGNATARIA"
                    class="form-control"
                    v-model="dados.ds_consignataria"
                    title=""
                    required=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
            </tr>
            <tr>
              <td>Nº da Solicitação:</td>
              <td>
                <input
                    type="text"
                    name="CD_SOLICITACAO"
                    id="CD_SOLICITACAO"
                    class="form-control"
                    v-model="dados.cd_solicitacao"
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
              <td>Tipo da Solicitação:</td>
              <td>
                <input
                    type="text"
                    name="DS_TIPO_SOLICITACAO"
                    id="DS_TIPO_SOLICITACAO"
                    class="form-control"
                    v-model="dados.ds_tipo_solicitacao"
                    title=""
                    required=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
            </tr>
            <tr>
              <td><label>Nº do Contrato / Documento:</label></td>
              <td>
                <input
                    type="text"
                    name="CD_CONTRATO_CONSIGNATARIA"
                    id="CD_CONTRATO_CONSIGNATARIA"
                    class="form-control"
                    v-model="dados.nr_contrato"
                    required=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
              <td>Tipo da Consignação:</td>
              <td>
                <input
                    type="text"
                    name="DS_TIPO_CONSIGNACAO"
                    id="DS_TIPO_CONSINGACAO"
                    class="form-control"
                    v-model="dados.ds_tipo_consignacao"
                    title=""
                    required=""
                    readonly="readonly"
                    maxlength="150"
                />
              </td>
            </tr>
            <tr>
              <td>Quant. de Parcelas:</td>
              <td class="input-group">
                <input
                    type="text"
                    name="NR_PARCELA"
                    id="NR_PARCELA"
                    class="form-control"
                    v-model="dados.nr_parcela"
                    readonly="readonly"
                    maxlength="2"
                />
              </td>
              <td>Valor Parcela:</td>
              <td class="input-group">
                <input
                    type="text"
                    name="VL_PARCELA"
                    id="VL_PARCELA"
                    v-model="dados.vl_parcela"
                    class="form-control"
                    required=""
                    readonly="readonly"
                    maxlength="20"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body>
      <template v-slot:header>
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-white">Histórico de Descontos</span>
          <b-button @click="toggleCollapse('collapse3')" variant="transparent">
            <b-icon variant="white" :icon="isOpen['collapse3'] ? 'chevron-up' : 'chevron-down'"
                    aria-hidden="true"></b-icon>
          </b-button>
        </div>
      </template>
      <b-collapse id="collapse3" v-model="isOpen['collapse3']">
        <b-card-body>
          <table class="tb100pc">
            <tbody>
            <tr class="bg_title_tb">
              <td class="text-center">Referência</td>
              <td class="text-center">Nº Parcela</td>
              <td class="text-center">Valor da Parcela</td>
              <td class="text-center">Valor Pago</td>
            </tr>
            <tr
                v-for="(item_tr, index) in dados.parcelas_folha"
                :key="item_tr.nr_parcela"
                :class="{ row0: index % 2 === 0, row1: index % 2 !== 0 }"
            >
              <td class="text-center">{{ item_tr.ds_mes_ano_folha }}</td>
              <td class="text-center">
                {{ item_tr.nr_parcela }}/{{ dados.nr_parcela }}
              </td>
              <td class="text-center">{{ item_tr.ds_total_lancado_folha }}</td>
              <td class="text-center">
                {{ item_tr.ds_total_descontado_folha }}
              </td>
            </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <template v-slot:header>
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-white">Saldo Devedor</span>
          <b-button @click="toggleCollapse('collapse4')" variant="transparent">
            <b-icon variant="white" :icon="isOpen['collapse4'] ? 'chevron-up' : 'chevron-down'"
                    aria-hidden="true"></b-icon>
          </b-button>
        </div>
      </template>
      <b-collapse id="collapse4" v-model="isOpen['collapse4']">
        <b-card-body>
          <table class="table">
            <thead>
            <tr class="text-center">
              <th>Saldo Devedor para Quitação:</th>
              <th>Validade do Saldo:</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="saldo in saldos" :key="saldo.id">
              <td>
                <b-input-group :prepend="`Saldo ${saldo.id} R$`" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input v-model="saldo.value"
                                placeholder=""></b-form-input>
                </b-input-group>
              </td>
              <td>
                <b-form-input type="date" v-model="saldo.selectedDate" :min="minDate"></b-form-input>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="text-center">
            <b-row class="text-center">
              <b-col md="6">
                <b-button @click="informarSaldoDevedor" block squared variant="outline-primary"
                          :disabled="!isButtonEnabled">Informar Saldo Devedor
                </b-button>
              </b-col>
              <b-col md="6">
                <b-button @click="getRejectSaldo" block squared variant="outline-danger">Não Informar Saldo Devedor
                </b-button>
              </b-col>
            </b-row>

          </div>


        </b-card-body>
      </b-collapse>
    </b-card>


  </div>
</template>
<script lang="ts">
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import axios from "axios";

Vue.use(BootstrapVue);

const baseUrl = process.env.VUE_APP_BASE_URL;

export default Vue.extend({
  components: {
    // Menus,
  },
  data() {
    return {
      dados: "",
      data_frm_saldo: [],
      isOpen: {
        collapse1: false,
        collapse2: false,
        collapse3: false,
        collapse4: true,
      },

      minDate: this.getCurrentDate(),
      saldos: [
        {id: 1, value: "", selectedDate: null,},
        {id: 2, value: "", selectedDate: null,},
        {id: 3, value: "", selectedDate: null,},
      ],


    };
  },
  methods: {
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    toggleCollapse(collapseName) {
      this.isOpen[collapseName] = !this.isOpen[collapseName];
    },

    formatMoneyReal(event, v_model, obj_id) {
      let valor = event.target.value.replace(/\D/g, "");
      if (valor.length > 0) {
        valor = (parseFloat(valor) / 100).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }

      v_model = v_model.split(".");

      if (v_model.length == 1) {
        this[v_model[0]] = valor;
      } else if (v_model.length == 2) {
        this[v_model[0]][v_model[1]] = valor;
      }

      document.getElementById(obj_id).value = valor;
    },
    inform(event) {
      event.preventDefault();
      let frm_data = Object.assign({}, this.data_frm_saldo);
      const request = {
        proposta: this.cdProposta,
        action: "inform",
        frm_data: frm_data,
      };
      axios
          .post(baseUrl + "informa-saldo-emprestimo", request)
          .then((response) => {
            this.response_inform = response.data;
            if (response.data.errors) {
              let errorText = '';
              for (const [, value] of Object.entries(response.data.errors)) {
                errorText += value[0] + '<br>';
              }
              this.$swal.fire({
                icon: 'error',
                title: '',
                html: errorText,
                customClass: {
                  confirmButton: 'my-custom-button-class'
                }
              });
            }
            if (response.data.success) {
              this.$swal.fire({
                icon: 'success',
                title: '',
                text: response.data.message,
                customClass: {
                  confirmButton: 'my-custom-button-class'
                },
                didClose: () => {
                  window.parent.close();
                },

              });
            }
          })
          .catch((error) => console.log(error));
    },

    informarSaldoDevedor() {

      const valores = this.saldos
          .filter(saldo => saldo.value.trim() !== '' && saldo.selectedDate !== null)
          .map(saldo => {
            return {
              id: saldo.id,
              value: saldo.value,
              selectedDate: saldo.selectedDate,
            };
          });


      const params = {
        idProposta: this.cdProposta,
        valores: valores.filter(valor => valor.value.trim() !== '' && valor.selectedDate !== null),
      };
      console.log(params);

      //alert(this.cdProposta);

      axios.post(baseUrl + "informa-saldo-emprestimo", params)
          .then((response) => {
            this.response_inform = response.data;
            if (response.data.errors) {
              let errorText = '';
              for (const [, value] of Object.entries(response.data.errors)) {
                errorText += value[0] + '<br>';
              }
              this.$swal.fire({
                icon: 'error',
                title: '',
                html: errorText,
                customClass: {
                  confirmButton: 'my-custom-button-class'
                }
              });
            }
            if (response.data.success) {
              this.$swal.fire({
                icon: 'success',
                title: '',
                text: response.data.message,
                customClass: {
                  confirmButton: 'my-custom-button-class'
                },
                didClose: () => {
                  window.parent.close();
                },

              });
            }
          })
          .catch((error) => console.log(error));
      console.log(valores);

    },
    getRejectSaldo() {
      event.preventDefault();

      const request = {
        proposta: this.cdProposta,
      };
      axios
          .post(baseUrl + "rejeita-saldo-emprestimo", request)
          .then((response) => {
            this.response_reject = response.data;
            if (response.data.errors) {
              let errorText = '';
              for (const [, value] of Object.entries(response.data.errors)) {
                errorText += value[0] + '<br>';
              }
              this.$swal.fire({
                icon: 'error',
                title: '',
                html: errorText,
                customClass: {
                  confirmButton: 'my-custom-button-class'
                }
              });
            }
            if (response.data.success) {
              this.$swal.fire({
                icon: 'success',
                title: '',
                text: response.data.message,
                customClass: {
                  confirmButton: 'my-custom-button-class'
                },
                didClose: () => {
                  window.parent.close();
                },
              });
            }
          })
          .catch((error) => console.log(error));
    },
    getOutstandingBalance() {
      const request = {proposta: this.cdProposta};
      axios
          .post(baseUrl + "solicita-saldo-emprestimo", request)
          .then((response) => {
            this.dados = response.data;
            if (response.data.errors) {
              let errorText = '';
              for (const [, value] of Object.entries(response.data.errors)) {
                errorText += value[0] + '<br>';
              }
              this.$swal.fire({
                icon: 'error',
                title: '',
                html: errorText,
                customClass: {
                  confirmButton: 'my-custom-button-class'
                }
              });
            }
          })
          .catch((error) => console.log(error));
    },
  },
  mounted() {
    this.getOutstandingBalance();
  },
  computed: {
    isButtonEnabled() {
      // Verifica se pelo menos um valor e uma data estão preenchidos
      return this.saldos.some(saldo => saldo.value.trim() !== '' && saldo.selectedDate !== null);
    },
    cdProposta() {
      return this.$route.params.cd_proposta;
    },

  },
});
</script>

<style scoped>
:root {
  --cem-porcento: 100%;
}

.page-wraper {
  width: 80%;
  margin: 0 auto;
}

.custom-line {
  border: none;
  border-top: 1px solid #1384ad;
  margin: 10px 0;
}

.card {
  margin-bottom: 30px;
}

.card-header {
  background-color: #1384ad;
}

.card-header > h5 {
  color: white;
}

.card-primary {
  border-color: #337ab7;
}

.card-body-table {
  flex: 1 1 auto;
  padding: 0rem 0rem 1.25rem 0rem;
}

.tb100pc {
  width: 100%;
}

.tb100pc th td,
.tb100pc tr td,
.tb100pc caption {
  font-weight: normal;
  padding: 5px;
}

.tb100pc tr.bg_title_tb {
  background-color: #71b5ce;
  color: #f1f5f8;
  font-weight: bold;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.row0 {
  background-color: #ecf6fe;
}

.row1 {
  background-color: #e0effc;
}

.white-li li {
  color: black;
}

.btn-custom {
  width: 250px;
  border: 1px solid #337ab7;
  color: #333;
  background-color: white;
  margin-left: 30px;
  margin-right: 30px;
  transition: background-color 0.3s ease-in-out; /* Transição suave para hover */
}

#btn_SalvarProposta:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

#btn_CancelarProposta:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.card-header {
  padding: 0.25rem 1rem; /* Ajuste o padding para controlar a altura do cabeçalho */
  height: 3rem; /* Ajuste a altura do cabeçalho conforme necessário */
}
</style>