<template>
    <div>
        <div style="margin-top: 100px" class="container align-items-center vh-100">
            <div class="row" style="border: 0.2px solid #1384AD; border-radius: 10px">
                <div style="margin-top: 50px; margin-bottom: 10px" class="col-md-6">
                    <div class="text-center">
                        <img class="img-fluid" src="/img/logo.png"/>
                    </div>

                    <form @submit.prevent="login" style="margin-top: 50px" class="container">

                        <div v-if="showCpfField">
                            <div class="form-group">
                                <label for="username">Nova Senha</label>
                                <input type="password" v-model="password"
                                       class="form-control rounded-top-input" id="password"
                                />
                            </div>
                            <div class="form-group">
                                <label for="username">Confirme a Senha</label>
                                <input type="password" v-model="password_confirmation"
                                       class="form-control rounded-top-input" id="password-confirmation"
                                />
                            </div>

                            <button style="border-radius: 15px" class="btn btn-primary btn-block"
                                    @click="checkCpfValidity">Avançar
                            </button>
                        </div>

                        <div v-if="showPassword">
                            <div class="text-center">
                                <p> Olá <br>
                                    {{ userName }}</p>
                            </div>
                            <div class="form-group">
                                <label for="password">Digite a senha senha</label>
                                <input type="password" v-model="password" class="form-control rounded-top-input"
                                       id="password" placeholder="Password"/>

                            </div>
                            <div class="form-group">
                                <div class="text-center">
                                    <button style="border-radius: 15px" @click="login"
                                            :disabled="!cpf || !password || !validCpf"
                                            class="btn btn-primary btn-block">
                                        Entrar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="validCpf && !showPassword">
                            <p>CPF válido, mas não foi possível obter o nome do usuário.</p>
                        </div>


                    </form>


                </div>
                <div class="col-md-6" style="background-color: #1384AD;border-radius: 10px">
                    <div class="text-center">
                        <h1 style="color: white; margin-top: 200px;">Nova Senha</h1>
                        <div style="color: white" class="container text-left">
                            <li>A senha deverá ter no mínimo 8 e no máximo 12 caracteres</li>
                            <li>A senha deverá conter maiúsculas e minúsculas</li>
                            <li>A senha deverá conter números e caracteres especiais</li>

                        </div>
                    </div>


                    <div class="row" style="margin-bottom: 1px">

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

//import MyUsers from './MyUsers.vue';

import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

export default {
    components: {},
    data() {
        return {
            cpf: "",
            password: "",
            validCpf: false,
            showPassword: false,
            userName: '',
            showCpfField: true
        };
    },
    methods: {


        onRecaptchaVerified() {
            alert('funfou')
        },


        checkCpfValidity() {
            axios.post(baseUrl + 'validacpf', {cpf: this.cpf})
                .then(response => {
                    const valid = response.data.valid;
                    if (valid) {
                        this.showCpfField = false;
                        axios.post(baseUrl + 'getusername', {cpf: this.cpf})
                            .then(response => {
                                this.validCpf = true
                                this.showPassword = true;
                                this.userName = response.data.nm_pessoa;

                            })
                            .catch(error => {
                                console.error(error);
                                this.showPassword = false;
                                this.userName = '';
                            });
                    } else {
                        if (response.data.errors) {
                            let errorText = '';
                            for (const [, value] of Object.entries(response.data.errors)) {
                                errorText += value[0] + '<br>';
                            }
                            this.$swal.fire({
                                icon: 'error',
                                title: '',
                                html: errorText,
                                customClass: {
                                    confirmButton: 'my-custom-button-class'
                                }
                            });
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                title: '',
                                text: 'CPF não encontrado',
                                customClass: {
                                    confirmButton: 'my-custom-button-class'
                                }
                            });
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.validCpf = false;
                    this.showPassword = false;
                    this.userName = '';
                });
        },


        async login() {
            try {
                // Verifica se os campos estão preenchidos
                if (!this.cpf || !this.password) {
                    return;
                }

                // Faça a solicitação HTTP para autenticação no Laravel
                const response = await axios.post(baseUrl + "v2/auth", {
                    cpf: this.cpf,
                    password: this.password,
                });

                // Armazene o token no LocalStorage ou em outro local de sua preferência
                const token = response.data.authToken;
                const pessoa = response.data.pessoa[0].cd_usuario;
                localStorage.setItem("authToken", token);
                localStorage.setItem("pessoa", pessoa);
                if (pessoa) {
                    const termo = response.data.user.termo;
                    if (termo !== 0) {
                        this.$router.push('/myuser');


                    } else {
                        this.$router.push('/termoaceite');
                    }

                }
                // Redirecione o usuário para a próxima página, se necessário
            } catch (error) {
                // Lidar com erros de login
                console.error(error);
            }
        },
    },
};
</script>

<style scoped>
.rounded-top-input {
    border-radius: 0.9rem 0.9rem 0 0;
    background-color: rgba(116, 192, 218, 0.25);
    height: 50px;
}

.form-control::placeholder {
    color: white; /* Defina a cor desejada para o texto do placeholder */
}

.my-custom-button-class {
    background-color: #c82333; /* cor de fundo personalizada de perigo no hover */
    border-color: #c82333; /* cor da borda personalizada no hover */
    /* adicione outros estilos personalizados conforme necessário */
}
</style>
