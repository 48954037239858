<template>
  <div>
    <Menus></Menus>
    <br>

    <div class="container">
      <h2 class="title">Usuário - Alteração de Senha</h2>
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="card">


            <div class="card-body">
              <div class="form-group">
                <div class="row text-right">
                  <label class="col-md-5 col-sm-3 control-label">* Nova senha:</label>
                  <div class="col-md-7 col-sm-9">
                    <input v-model="password" class="form-control " type="password">
                    <div class="" v-if="password.length > 0" :style="{ color: passwordStrengthColor }">
                      Força da senha: {{ passwordStrengthText }}
                    </div>
                  </div>

                </div>


              </div>
              <div class="form-group">
                <div class="row text-right">
                  <label class="col-md-5 col-sm-3 control-label">* Confirme sua nova senha:</label>
                  <div class=" col-md-7 col-sm-9">
                    <input class="form-control" type="password" v-model="passwordConfirmation"
                           :disabled="!isPasswordValid">
                  </div>

                </div>
              </div>
              <div class="form-group text-center">
                <button :disabled="!isPasswordValid || !isPasswordConfirmed" @click="trocarSenha"
                        class="btn btn-success">Salvar
                </button>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header" style="color: white"><strong>Critério de senha</strong></div>
            <div class="card-body">
              <ul>
                <li>• A senha deverá ter no mínimo 8 e no máximo 12 caracteres;</li>
                <li>• A senha deverá conter letras e números;</li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

</template>

<script>


import Menus from "@/components/Menus.vue";
import axios from "axios";
import Swal from "sweetalert2";
//import $ from "jquery";
//import Swal from 'sweetalert2'

// import $ from "jquery";

const baseUrl = process.env.VUE_APP_BASE_URL;
const authToken = localStorage.getItem('authToken');
const headers = {
  'Authorization': `Bearer ${authToken}`
};
export default {
  data() {
    return {
      password: '',
      passwordConfirmation: ''
    }
  }, computed: {
    isPasswordValid() {
      // Implemente suas regras de validação aqui
      return (
          this.password.length >= 8 &&
          this.password.length <= 12 &&
          /[a-z]/.test(this.password) &&
          /[A-Z]/.test(this.password) &&
          /\d/.test(this.password) &&
          /\W/.test(this.password)
      );
    },
    isPasswordConfirmed() {
      return this.password === this.passwordConfirmation;
    },
    passwordStrength() {
      // Avalie a complexidade da senha e retorne um nível de força
      // Neste exemplo simples, consideramos 3 níveis de força: fraco, médio e forte
      if (this.password.length >= 8 && this.password.length <= 12) {
        if (
            /[a-z]/.test(this.password) &&
            /[A-Z]/.test(this.password) &&
            /\d/.test(this.password) &&
            /\W/.test(this.password)
        ) {
          return "strong";
        } else {
          return "medium";
        }
      } else {
        return "weak";
      }
    },
    passwordStrengthText() {
      if (this.passwordStrength === "weak") {
        return "Fraca";
      } else if (this.passwordStrength === "medium") {
        return "Média";
      } else if (this.passwordStrength === "strong") {
        return "Forte";
      } else {
        return "";
      }
    },
    passwordStrengthColor() {
      if (this.passwordStrength === "weak") {
        return "red";
      } else if (this.passwordStrength === "medium") {
        return "orange";
      } else if (this.passwordStrength === "strong") {
        return "green";
      } else {
        return "black";
      }
    },
  },


  name: 'TrocarSenha',
  components: {Menus},
  methods: {
    limparSenhas() {
      this.password = '';
      this.passwordConfirmation = ''
    },

    async trocarSenha() {
      // Exibindo o alerta de carregamento
      Swal.fire({
        title: 'Carregando...',
        html: '<div class="loading-container"><div class="loading-spinner"></div></div>',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });

      try {
        // Parâmetros para a requisição POST
        const params = {
          password: this.password,
          password_confirmation: this.passwordConfirmation
        };

        // Realizando a requisição POST
        const response = await axios.post(`${baseUrl}trocar-senha`, params, {headers: headers});
        //this.diretorio = ''
        // Lógica após a resposta bem-sucedida
        console.log(response.data); // Exemplo de como utilizar os dados da resposta
        Swal.fire({
          title: 'Sucesso!',
          text: 'Senha alterada com sucesso.',
          icon: 'success',
          timer: 3000 // Tempo em milissegundos (3 segundos)
        }).then(() => {
          // Fechando o alerta de carregamento após exibir a mensagem de sucesso
          Swal.close();
          this.limparSenhas()
        });

      } catch (error) {
        // Lógica para lidar com erros
        console.error('Ocorreu um erro ao trocar sua senha:', error);
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao trocar sua senha.',
          icon: 'error'
        }).then(() => {
          // Fechando o alerta de carregamento após exibir a mensagem de erro
          Swal.close();
        });
      }
    }

  },

  mounted() {


  },

};


</script>

<style>
#myChart-legend {
  font-size: 70px; /* Ajuste o tamanho da fonte conforme necessário */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

ul {
  color: black;
}

ul li {
  color: black;
}
</style>