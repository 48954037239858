import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginForm from '../components/LoginForm.vue';
import Myusers from '../components/MyUsers.vue';
import TermoAceite from "@/components/TermoAceite.vue";
import ValidaForm from "@/components/ValidaForm.vue";
import SenhaForm from "@/components/SenhaForm.vue";
import EsqueceuSenha from "@/components/EsqueceuSenha.vue";
import PasswordForm from "@/components/PasswordForm.vue";
import NewPasswordForm from "@/components/NewPasswordForm.vue";
import Menus from "@/components/Menus.vue";
import Simulador from "@/components/Simulador.vue";
import AgendamentoPesquisa from "@/views/AgendamentoPesquisa.vue"
import CadastroQuestoes from "@/views/CadastroQuestoes.vue";
import TesteIp from "@/components/TesteIp.vue";
import SolicitacaoProposta from "@/views/SolicitacaoProposta.vue";
import RefinFromProposta from "@/components/RefinFromProposta.vue";
import SolicitacaoSaldoEmprestimo from "@/components/SolicitacaoSaldoEmprestimo.vue";
import UploadFileBanco from "@/components/UploadFileBanco.vue";
import GraficoMargem from "@/components/GraficoMargem.vue";
import ConsultaProcesso from "@/components/ConsultaProcesso.vue";
import DashboardBI from "@/components/DashboardBI.vue";
import ExtratoFinaceiro from "@/components/ExtratoFinaceiro.vue";
import AcessoLog from "@/components/AcessoLog.vue";
import ControleAcessoMenu from "@/components/ControleAcessoMenu.vue";
import MargemCancela from "@/components/MargemCancela.vue";
import Diretorio from "@/components/Diretorio.vue";
import GestaoMenu from "@/components/GestaoMenu.vue";
import TrocarSenha from "@/components/TrocarSenha.vue";
import SlidePage from "@/components/SlidePage.vue";


Vue.use(VueRouter);

const routes = [
    {path: '/', component: LoginForm, name: 'Home'},
    {path: '/myusers', component: Myusers, name: 'MyUsers'},
    {path: '/termoaceite', component: TermoAceite, name: 'TermoAceite'},
    {path: '/valida', component: ValidaForm, name: 'ValidaForm'},
    {path: '/primeirasenha', component: SenhaForm, name: 'SenhaForm'},
    {path: '/forget-password', component: EsqueceuSenha, name: 'EsqueceuSenha'},
    {path: '/password', component: PasswordForm, name: 'PasswordForm'},
    {path: '/newpassword', component: NewPasswordForm, name: 'NewPasswordForms'},
    {path: '/agendamento-pesquisa', component: AgendamentoPesquisa, name: 'AgendamentoPesquisa'},
    {path: '/ts', component: CadastroQuestoes, name: 'CadastroQuestoes'},
    {path: '/reset-password/:token/:email', component: NewPasswordForm, name: 'NewPasswordForm'},
    {path: '/menus', component: Menus, name: 'Menu'},
    {path: '/simulador', component: Simulador, name: 'Simulador'},
    {path: '/testeip', component: TesteIp, name: 'TesteIp'},
    {path: '/solicitacao-proposta/:cd_proposta', component: SolicitacaoProposta, name: 'SolicitacaoProposta'},
    {path: '/refin-from-proposta/:cd_proposta', component: RefinFromProposta, name: 'RefinFromProposta'},
    {path: '/saldo-emprestimo/:cd_proposta', component: SolicitacaoSaldoEmprestimo, name: 'SolicitacaoSaldoEmprestimo'},
    {path: '/upload-file-banco/:cd_proposta', component: UploadFileBanco, name: 'UploadFileBanco'},
    {path: '/margem/:cd_proposta', component: GraficoMargem, name: 'GraficoMargem'},
    {path: '/consulta-judicial', component: ConsultaProcesso, name: 'ConsultaProcesso'},
    {path: '/dashboard', component: DashboardBI, name: 'DashboardBI'},
    {path: '/extrato-financeiro', component: ExtratoFinaceiro, name: 'ExtratoFinaceiro'},
    {path: '/acesso-log', component: AcessoLog, name: 'AcessoLog'},
    {path: '/acesso-menu/:cd_menu', component: ControleAcessoMenu, name: 'ControleAcessoMenu'},
    {path: '/solicitacoes', component: MargemCancela, name: 'MargemCancela'},
    {path: '/diretorio', component: Diretorio, name: 'Diretorio'},
    {path: '/gestao-menu', component: GestaoMenu, name: 'GestaoMenu'},
    {path: '/slides', component: SlidePage, name: 'SlidePage'},
    {path: '/trocar-senha', component: TrocarSenha, name: 'TrocarSenha'}




];

const router = new VueRouter({
    routes
});
router.beforeEach((to, from, next) => {
    const authToken = localStorage.getItem('authToken');
    const pessoa = localStorage.getItem('pessoa');

    if (!authToken && !pessoa && to.name !== 'Home' && to.name !== 'EsqueceuSenha' && to.name !== 'ValidaForm' && to.name != 'PasswordForm' && to.name != 'NewPasswordForm' && to.name != 'Menu' && to.name != 'TesteIp'
        && to.name != 'SolicitacaoProposta'
        && to.name != 'RefinFromProposta'
        && to.name != 'SolicitacaoProposta'
        && to.name != 'SolicitacaoSaldoEmprestimo'
        && to.name != 'ConsultaProcesso'
    ) {
        next({name: 'Home'});
    } else {
        next();
    }
});
export default router;
