<template>
  <div>
    <Menus></Menus>

    <br/>


    <div class="container">
      <h2 class="title">Consulta Solicitações</h2>
      <br><br>
      <table ref="dataTable" class="table table-striped">
        <thead>
        <tr style="color: white;background-color: #1384AD">
          <th>Nº Solicitação</th>
          <th>Valor da Reserva</th>
          <th>Data Solicitação</th>
          <th>Consignatária</th>
          <th>Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="resultado in resultados" :key="resultado.cd_proposta">
          <td>{{ resultado.cd_proposta }}</td>
          <td>R$ {{ resultado.vl_reserva }}</td>
          <td>{{ resultado.dt_insert_formated }}</td>
          <td>{{ resultado.consignataria.nm_fantasia }}</td>
          <td>
            <button @click="postCancelaMargem(resultado.cd_proposta)" class="btn btn-xs btn-outline-danger">Cancelar</button>
          </td>
        </tr>
        </tbody>
      </table>


    </div>

  </div>

</template>


<script>
import Menus from "@/components/Menus.vue";

import axios from 'axios';
import $ from 'jquery';
import 'jquery';  // Importe o jQuery global
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';



const baseUrl = process.env.VUE_APP_BASE_URL;


export default {
  components: {Menus},
  data() {
    return {
      resultados: [],
      dataTable: null

    };
  },

  methods: {
    async getSolicitacaos() {
      const authToken = localStorage.getItem('authToken');

      //  alert(cd_usuario);
      const headers = {
        'Authorization': `Bearer ${authToken}`
      };
      const response = await axios.get(`${baseUrl}busca-solicitacao`, {headers: headers})
      this.resultados = response.data
      console.log(response.data)
    },
    initializeDataTable() {
      // Salve a referência à instância do Vue
      const self = this;

      // Inicialize o DataTable dentro do hook mounted
      if ($.fn.DataTable.isDataTable(this.$refs.dataTable)) {
        // Destroi a instância DataTable existente antes de inicializar novamente
        $(this.$refs.dataTable).DataTable().destroy();
      }

      // Inicialize o DataTable
      this.dataTable = $(this.$refs.dataTable).DataTable({
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Portuguese-Brasil.json'
        },
        columns: [
          { data: 'cd_proposta' },
          { data: 'vl_reserva' },
          { data: 'dt_insert_formated' },
          { data: 'consignataria.nm_fantasia' },
          {
            data: null,
            render: function () {
              return '<button class="btn btn-xs btn-outline-danger">Cancelar</button>';
            },
            createdCell: function (cell) {
              // Adiciona um ouvinte de eventos de clique usando a referência à instância do Vue
              $(cell).on('click', function () {
                self.postCancelaMargem($(this).closest('tr').find('td').eq(0).text());
              });
            },
          },
        ],
      });
    },
    postCancelaMargem(escolha) {
      //alert('aqui')
      this.$swal.fire({
        title: 'Você tem certeza?',
        text: 'Deseja realmente cancelar?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim, cancelar margem!',
        cancelButtonText: 'Não, cancelar',
        customClass: {
          confirmButton: 'my-custom-button-class',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelar(escolha);  // Chame sua função cancelar aqui
        } else {
          this.$swal.fire({
            title: 'Ação interrompida com sucesso',
            icon: 'info',
            customClass: {
              confirmButton: 'my-custom-button-class',
            },
          });

          //this.acaoInterrompida();  // Chame sua função acaoInterrompida aqui
        }
      });
      //alert(escolha)
    },
    async cancelar(escolha) {
      const params = {
        num_solicitacao: escolha
      }
      console.log(params)
      const response = await axios.post(`${baseUrl}solicitacao-cancelar-servidor`, params, {})
      if (response.status === 200) {
        this.$swal.fire({
          title: 'Ação concluída com sucesso',
          icon: 'success',
          customClass: {
            confirmButton: 'my-custom-button-class',
          },
        });
        this.getSolicitacaos()
      }
    }
  },
  watch: {
    resultados: {
      handler() {
        // Atualize o DataTable quando a lista de resultados mudar
        if (this.dataTable) {
          this.dataTable.clear().rows.add(this.resultados).draw();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getSolicitacaos(); // Carrega os dados iniciais
    this.initializeDataTable(); // Carrega os dados iniciais
  },
  mounted() {
    this.initializeDataTable();


  },

  computed: {
    // Use uma propriedade computada para determinar se o formulário é válido

  }


};

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.card {
  margin-bottom: 30px;
}

span {
  color: black;
}

.card-header {
  background-color: #1384ad;
}


.card-header > h5 {
  color: white;
}

.card-primary {
  border-color: #337ab7;
}

.form-control {

  border: 2px solid #1384ad; /* Largura e cor da borda desejadas */

}

.custom-file {
  border: 2px solid #1384ad;
}

.multiselect {

}

.multiselect__tags {
  border: 2px solid #1384ad;

  border: 2px solid #1384ad;

}

.btn {


}

multiselect__option--highlight {
  background-color: #1384AD;
}

.btn-primary {
  background-color: #1384AD;
}

.btn-outline-primary {
  border-color: #1384AD;
}

.btn-primary:hover {
  background-color: #1384AD;
}

</style>