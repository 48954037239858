<template>
  <div>
    <Menus></Menus>

    <br/>
    <div class="container">
      <table class="table table-striped">
        <thead>
        <tr style="color: white;background-color: #1384AD">
          <th>Usuario</th>
          <th>Ação</th>
          <th>Ip</th>
          <th>Data/Hora</th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="resultado in resultados" :key="resultado.id">

          <td>{{resultado.user.name}} -- {{resultado.user.cpf}}</td>
          <td>{{resultado.name}}</td>
          <td>{{resultado.ip}}</td>
          <td>{{resultado.created_at_formatted}}</td>
          <!-- Adicione mais colunas conforme necessário -->
        </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>


<script>
import Menus from "@/components/Menus.vue";

import axios from 'axios';

const baseUrl = process.env.VUE_APP_BASE_URL;


export default {
  components: {Menus},
  data() {
    return {
      resultados: null
    };
  },

  methods: {
    async getAcesso() {
      try {
        const response = await axios.get(`${baseUrl}busca-log`);
        this.resultados = response.data;

      } catch (error) {
        console.error('Erro ao buscar dados da API 2', error);
      }
    }
  },
  mounted() {
    this.getAcesso()

  },
  computed: {
    // Use uma propriedade computada para determinar se o formulário é válido

  }

};

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.card {
  margin-bottom: 30px;
}

span {
  color: black;
}

.card-header {
  background-color: #1384ad;
}


.card-header > h5 {
  color: white;
}

.card-primary {
  border-color: #337ab7;
}

.form-control {

  border: 2px solid #1384ad; /* Largura e cor da borda desejadas */

}

.custom-file {
  border: 2px solid #1384ad;
}

.multiselect {

}

.multiselect__tags {
  border: 2px solid #1384ad;

  border: 2px solid #1384ad;

}

.btn {


}

multiselect__option--highlight {
  background-color: #1384AD;
}

.btn-primary {
  background-color: #1384AD;
}

.btn-outline-primary {
  border-color: #1384AD;
}

.btn-primary:hover {
  background-color: #1384AD;
}

</style>