<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h5>Documentos Anexos</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <table class="table table-striped table-sm">
            <thead class="text-center">
            <tr style="color: white; background-color: #1384AD">
              <th>Tipo do Documento</th>
              <th>Tipo</th>
              <th>Data Envio</th>
              <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(file, index) in filesData" :key="index" class="text-center">
              <td>{{ file.tipo_descritivo }}</td>
              <td>{{ file.file_extension }}</td>
              <td>{{ file.created_at_formatted }}</td>
              <td>
                <div class="row">
                  <div class="col-md-6 text-center"><a target="_blank" :href="file.caminho_file">
                    <b-icon icon="download" variant="primary"></b-icon>
                  </a></div>
                  <div class="col-md-6 text-center"><a href="">
                    <b-icon icon="trash" variant="danger"></b-icon>
                  </a></div>
                </div>


              </td>


            </tr>

            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 text-center">

            <b-button v-b-toggle.collapse-1 variant="primary">Anexar Documento</b-button>
            <b-collapse id="collapse-1" class="mt-2">
              <div id="fechado">
                <div id="card-anexo" style="margin-top: 10px" class="card">
                  <div class="card-body">
                    <div class="text-center">Anexar Documentos</div>
                    <div class="form-group text-left">
                      <label for="">Titulo Documento</label>
                      <select v-model="tipoDocumento" class="form-control" name="" id="">
                        <option value="1">Contrato de Emprestimos</option>
                      </select>

                    </div>
                    <div class="form-group">
                      <input type="file" @change="handleFileChange"> *PDF,JPG,JPEG
                    </div>
                  </div>
                  <div class="form-group">
                    <button @click="uploadFile" class="btn btn-primary" :disabled="!arquivoSelecionado">Enviar</button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import axios from "axios";
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";

const baseUrl = process.env.VUE_APP_BASE_URL;

Vue.use(BootstrapVue)

export default Vue.extend({
  data() {
    return {
      tipoDocumento: '',
      arquivoSelecionado: false,
      arquivo: null,
      filesData: [],
    }
  },
  methods: {
    handleFileChange(event) {
      this.arquivo = event.target.files[0];
      this.arquivoSelecionado = event.target.files.length > 0;
    },
    uploadFile() {
      const formData = new FormData();
      formData.append("arquivo", this.arquivo);
      formData.append("tipoDocumento", this.tipoDocumento);
      formData.append('cd_proposta', this.cdProposta)

      axios
          .post(baseUrl + "upload-file-banco", formData)
          .then((response) => {
            // Lógica após o upload bem-sucedido
            console.log("Upload bem-sucedido", response.data);
            this.arquivoSelecionado = false;
            this.getFileConsignacao();
          })
          .catch((error) => {
            // Lógica de tratamento de erros
            console.error("Erro ao fazer upload", error);
          });
    },

    getFileConsignacao() {
      axios.get(baseUrl + 'busca-file-consignacao/' + this.cdProposta)
          .then(response => {

            this.filesData = response.data;

            console.log(this.filesData);
          })
          .catch(error => {
            console.error('Erro na requisição:', error);
          });
    }

  },
  computed: {
    cdProposta() {
      return this.$route.params.cd_proposta;
    }
  },

  mounted() {
    this.getFileConsignacao();
  }
})


</script>

<style scoped>
.card-header {

  background-color: #1384ad;

}

.card-header > h5 {
  color: white;
}

.btn-primary {
  background-color: #1384ad;
}

#card-anexo {
  background-color: #b2b2b2;
}
</style>