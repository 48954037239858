<template>
    <div class="container-fluid">
        <div class="page-wraper">
            <h3>Agendamento de Pesquisa</h3>
            <hr class="custom-line">
            <div class="card">
                <div class="card-body">
                    <form @submit.prevent="sendForm()">
                        <div class="mb-3">
                            <label for="data_inicial" class="form-label">Data inicial</label>
                            <input type="date" name="data_inicial" class="form-control" v-model="dataInicial">
                        </div>
                        <div class="mb-3">
                            <label for="data_final" class="form-label">Data final</label>
                            <input type="date" name="data_final" class="form-control" v-model="dataFinal">
                        </div>
                        <label for="grupo_usuarios" class="form-label">Grupos de Usuários</label>
                        <div class="form-check">
                            <input type="checkbox" name="grupo_usuarios" class="form-check-input" value="1"
                                v-model="grupoUsuarios">
                            <label for="" class="form-check-label">Consignante</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" name="grupo_usuarios" class="form-check-input" value="2"
                                v-model="grupoUsuarios">
                            <label for="" class="form-check-label">Consignatária</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" name="grupo_usuarios" class="form-check-input" value="3"
                                v-model="grupoUsuarios">
                            <label for="" class="form-check-label">servidor</label>
                        </div>
                        <!--div>
                          <h4>Questões</h4>
                          <b-button variant="primary">Primary</b-button>
                          <div v-for="questao of questoes" :key="questao.id">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" :value="questao.id" v-model="questoesSelecionadas">
                              <label class="form-check-label">
                                <p>{{ questao.enunciado }}</p>
                                <ul>
                                  <li v-for="alternativa of questao.alternativas" :key="alternativa">{{ alternativa.text }}</li>
                                </ul>
                              </label>
                            </div>
                          </div>
                        </div-->
                        <div class="d-flex flex-row-reverse">
                            <button type="submit" class="btn btn-primary">Agendar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import axios from "axios";

Vue.use(BootstrapVue)

const baseUrl = process.env.VUE_APP_BASE_URL

export default Vue.extend({
    data() {
        return {
            dataInicial: '',
            dataFinal: '',
            grupoUsuarios: [],
            questoes: [],
            questoesSelecionadas: [],
        }
    },
    methods: {
        sendForm() {
            const data = {
                data_inicio: this.dataInicial,
                data_final: this.dataFinal,
                grupos: this.grupoUsuarios
            }

            axios.post(`${baseUrl}pesquisas`, data)
                .then(response => {
                  if (response.data.success) {
                    this.$swal.fire({
                      icon: 'success',
                      title: '',
                      text: response.data.message
                    })
                    this.clearForm()
                  }
                })
                .catch(error => console.log(error))
        },
        getQuestoes() {
          this.questoes = []
          axios.get(`${baseUrl}questoes`)
              .then(response=>{
                for (let questao of response.data.data) {
                  this.questoes.push(questao)
                }
              })
              .catch(error => console.log(error))
        },
        clearForm() {
              this.dataInicial = ''
              this.dataFinal = ''
              this.grupoUsuarios = []
        }
    },
    mounted() {
        this.getQuestoes()
    }
})
</script>
<style scoped>
.page-wraper {
    width: 80%;
    margin: 0 auto;
}

.custom-line {
    border: none;
    border-top: 1px solid #1384AD;
    margin: 10px 0;
}
</style>