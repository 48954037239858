<template>
  <div>

    <novo-login>
      <template v-slot:esquerdo>
        <h5 class="text-conectar" style="margin-left: 81px">Não se lembra da sua Senha?</h5>
        <div class="funcionamento">
          Enviaremos um código de validação para o seu e-mail, para procedermos a
          recuperação
          da sua senha. Por favor, selecione seu e-mail na caixa ao lado.
        </div>
      </template>
      <template v-slot:voltar>
        <div style="height: 20px" class="d-flex d-fix justify-content-end align-items-center">
          <router-link to="login"
                       style="margin-top: 40px">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M4.5 12L9.5 7M4.5 12L9.5 17M4.5 12H14.5C16.1667 12 19.5 13 19.5 17" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </router-link>
        </div>
      </template>
      <template v-slot:direito>
        <h1 class="text-recupera text-center">Recuperação da Senha</h1>
        <div style="margin-top: 26px" class="container">

          <div class="form-group">
            <label for="">Digite o n° do seu CPF</label>
            <div class="input-container">
              <input type="text" v-model="cpf" placeholder="CPF" v-mask="'###.###.###-##'"
                     class="form-control rounded-top-input-login" id="username"/>
              <div class="icon-container">
                <!-- Seu ícone SVG aqui -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                      d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                      stroke="#1384AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                      stroke="#1384AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          <div v-if="!showEmailCampos" class="form-group text-center">
            <button @click="consultaCpf" class="btn btn-contiuar">Verificar</button>
          </div>


          <div v-if="showEmailCampos">
            <label for="">Selecione o seu email</label>
            <div style="border: 0.2px solid #1384AD; border-radius: 10px" class="card">
              <div class="card-body">
                <div v-for="email in emailList" :key="email.id">
                  <label class="checkbox">
                    <input type="checkbox" :value="email.id" v-model="selectedEmail"/>
                    {{ email.email }}
                  </label>
                </div>

              </div>


            </div>
            <div style="margin-top: 10px" class="form-group text-center">
              <button :disabled="!selectedEmail" @click="selectEmail"
                      class="btn btn-contiuar">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </template>
    </novo-login>

    <div class="container centralizar">
      <div class="container">
        <div class="row">
          <div class="col-md-7 card-slide">
            <div class="container">
              <img style="margin-top: 30px" class="img-fluid" src="img/logo.png"/>
            </div>
            <div class="">
              <h1 class="welcome">Não se lembra da sua Senha?</h1>


              <p class="text-validar">Enviaremos um código de validação para o seu e-mail, para procedermos a
                recuperação
                da sua senha. Por favor, selecione seu e-mail na caixa ao lado.</p>

            </div>
            <AjudaModal></AjudaModal>
          </div>
          <div class="col-md-5 card-login">

            <div class="footer">
              <p class="direito"> © 2024. Sttórico Sistemas Ltda. Todos os direitos reservados.</p>
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>

</template>

<script>

//import MyUsers from './MyUsers.vue';

import axios from "axios";
import AjudaModal from "@/components/AjudaModal.vue";
import NovoLogin from "@/components/NovoLogin.vue";

const baseUrl = process.env.VUE_APP_BASE_URL;

export default {
  components: {NovoLogin, AjudaModal},
  data() {
    return {
      cpf: '',
      emailList: [], // lista de usuários
      selectedEmail: null, // usuário selecionado
      showEmailCampos: false
    };
  },
  methods: {
    consultaCpf() {
      axios.post(baseUrl + 'forget-password-validation', {cpf: this.cpf})
          .then(response => {
            console.log(response)
            if (response.data) {
              this.emailList = response.data;
              this.showEmailCampos = true
            } else {
              this.$swal.fire({
                icon: 'error',
                title: '',
                text: 'CPF não encontrado',
                customClass: {
                  confirmButton: 'my-custom-button-class'
                }
              });
            }

          })
          .catch(error => {
            console.error(error);
            //this.validCpf = false;
            //this.showPassword = false;
            //this.userName = '';
          });
    },
    selectEmail() {
      if (this.selectedEmail) {

        console.log(this.selectedEmail)
        console.log(this.cpf)


        axios.post(baseUrl + 'reset-password-email', {cpf: this.cpf, escolha: this.selectedEmail})
            .then(response => {
              console.log(response)
              if (response.status === 200) {
                console.log(response)
                this.$swal.fire({
                  icon: 'success',
                  title: '',
                  text: response.data.message,
                  customClass: {
                    confirmButton: 'my-custom-button-class'
                  }

                });

                this.$router.push('/');
              } else {
                this.$swal.fire({
                  icon: 'error',
                  title: '',
                  text: 'Não foi possivel fazer está operação',
                  customClass: {
                    confirmButton: 'my-custom-button-class'
                  }
                });

              }

            })
            .catch(error => {
              console.error(error);
              this.$swal.fire({
                icon: 'error',
                title: '',
                text: 'Não foi possivel fazer está operação',
                customClass: {
                  confirmButton: 'my-custom-button-class'
                }
              });
              //this.validCpf = false;
              //this.showPassword = false;
              //this.userName = '';
            });
        // Armazenar o cd_usuario selecionado no LocalStorage

      }
    }

  }

};


</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');

.rounded-top-input {
  border-radius: 6px;
  border: 1px solid var(--White-light, #E0E6ED);
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
}



@media (min-width: 544px) {
  .card-slide {
    width: 543px;
    height: 417px;
    flex-shrink: 0;
    border-radius: 20px 0px 0px 20px;
    background: #FBFBFB;
    box-shadow: 10px 10px 20px -10px #000; /* largura do card */
  }
}

.card-slide {

  height: 417px;
  flex-shrink: 0;
  border-radius: 20px 0px 0px 20px;
  background: #FBFBFB;
  box-shadow: 10px 10px 20px -10px #000; /* largura do card */
}

.card-login {

  height: 417px;
  flex-shrink: 0;
  border-radius: 0px 20px 20px 0px;
  background: #1384AD;
  box-shadow: 10px 10px 20px -10px #000;
}

.btn-contiuar {
  color: #1384AD;
  font-family: 'nunito Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 15px;
  width: 200px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 80px;
  background: #E0E6ED;
  text-transform: uppercase;
  box-shadow: 0px 10px 20px -10px #003DA4;
}

.text-recupera {
  margin-top: 40px;
  color: #FFF;

  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.checkbox {
  color: black;
}

label {
  color: white;
}

.footer {
  margin-bottom: 15px;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  padding: 10px;


}

.direito {
  color: #BFC9D4;
  font-family: "Nunito Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-validar {
  margin-left: 107px;
  width: 281px;
  height: 70px;
  flex-shrink: 0;
  color: var(--Black, #0E1726);
  font-family: "Nunito Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.welcome {
  margin-top: 23px;
  margin-left: 107px;
  color: #0E1726;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-ajuda {
  color: #1384AD;
  font-family: "Nunito Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
}

.centralizar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (min-width: 544px) {
  .card-slide {
    width: 543px;
    height: 417px;
    flex-shrink: 0;
    border-radius: 20px 0px 0px 20px;
    background: #FBFBFB;
    box-shadow: 10px 10px 20px -10px #000; /* largura do card */
  }
}

.rounded-top-input-login::placeholder {
  color: var(--White-light-dark, #888EA8);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form-control::placeholder {
  color: var(--White-light-dark, #888EA8);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; /* Defina a cor desejada para o texto do placeholder */
}


.funcionamento {
  margin-top: 12px;
  margin-left: 81px;
  width: 350px;
  height: 48px;
  flex-shrink: 0;
  color: #0E1726;

  font-family: "Nunito Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.input-container {
  display: flex;
  align-items: center;
}

.icon-container {
  margin-left: -30px; /* Ajuste conforme necessário para alinhar o ícone à direita */
  position: relative;
  z-index: 2; /* Para garantir que o ícone esteja acima do input */
}

.input-container input {
  flex: 1;
  padding-right: 40px; /* Garante espaço suficiente para o ícone */
}

</style>
