import { render, staticRenderFns } from "./AjudaModal.vue?vue&type=template&id=7ee0822f&scoped=true&"
import script from "./AjudaModal.vue?vue&type=script&setup=true&lang=js&"
export * from "./AjudaModal.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./AjudaModal.vue?vue&type=style&index=0&id=7ee0822f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ee0822f",
  null
  
)

export default component.exports