<template>
  <div>
    <novo-login>
      <template v-slot:esquerdo>
        <h5 class="text-conectar" style="margin-left: 81px">Olá! Seja bem vindo</h5>
        <div class="funcionamento">
          Verificamos que é seu primeiro acesso, vamos precisar realizar algumas verificações para sua segurança.
          <br>
          <br>
          Informe o dados solicitados na tela ao lado.
        </div>
      </template>

      <template v-slot:voltar>
        <div style="height: 20px" class="d-flex d-fix justify-content-end align-items-center">
          <router-link to="login"
                       style="margin-top: 40px">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M4.5 12L9.5 7M4.5 12L9.5 17M4.5 12H14.5C16.1667 12 19.5 13 19.5 17" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </router-link>
        </div>
      </template>
      <template v-slot:direito>
        <div class="container">
          <div class="text-center">
            <h1 class="primeiro-acesso">Validação do Primeiro Acesso</h1>
          </div>
          <div class="container">
            <div v-if="loading">
              <div class="container text-center">
                <div class="spinner-grow text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>

            <div>
              <div v-if="showNascimento" class="form-group">
                <label for="nascimento">Data de Nascimento</label>
                <input
                    type="text"
                    v-model="nascimento"
                    class="form-control rounded-top-input1"
                    id="nascimento"
                    placeholder="dd/mm/aaaa"
                    @input="applyDateMask"
                    required
                />
              </div>
              <div class="form-group text-center">
                <div v-if="!validaNascimento">
                  <button class="btn btn-contiuar" @click="validaNascimentoApi">Validar</button>
                </div>
              </div>

              <div v-if="validaNascimento && !validaMatricula" class="form-group">
                <label for="matricula">Matricula</label>
                <input
                    type="text"
                    v-model="matricula"
                    class="form-control rounded-top-input1"
                    id="matricula"
                />
              </div>

              <div class="form-group text-center">
                <div v-if="!validaMatricula && validaNascimento">

                  <button class="btn btn-contiuar" @click="validaMatriculaApi">Validar</button>
                </div>
              </div>

              <div v-if="validaMatricula" class="form-group">
                <label for="telefone">N° Telefone Celular</label>
                <input
                    type="text"
                    v-model="telefone"
                    v-mask="'(##) #####-####'"
                    class="form-control rounded-top-input1"
                    id="telefone"
                />
              </div>
              <div v-if="validaMatricula" class="form-group">
                <label for="email">Email</label>
                <input
                    type="email"
                    v-model="email"
                    class="form-control rounded-top-input1"
                    id="email"
                />
              </div>
              <div v-if="validaMatricula" :disabled="!telefone || !email" class="form-group text-center">
                <button @click="validaDadosApi" :disabled="!telefone || !email" style="border-radius: 15px"
                        class="btn btn-contiuar">
                  Validar
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </novo-login>


  </div>

</template>

<script>
import axios from "axios";
import NovoLogin from "@/components/NovoLogin.vue";

const baseUrl = process.env.VUE_APP_BASE_URL;
export default {
  components: {NovoLogin},
  data() {
    return {
      hasMatricula: true,
      nascimento: "",
      matricula: "",
      telefone: "",
      email: "",
      validaNascimento: false,
      validaMatricula: false,
      validaTelefone: false,
      validaEmail: false,
      loading: false,
      showNascimento: true,
      showMatricula: true
    };
  },
  methods: {
    applyDateMask(event) {
      let value = event.target.value.replace(/\D/g, '');
      if (value.length > 2) value = value.slice(0, 2) + '/' + value.slice(2);
      if (value.length > 5) value = value.slice(0, 5) + '/' + value.slice(5, 9);
      this.nascimento = value;
    },
    convertDateFormat(date) {
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    },
    validaNascimentoApi() {
      this.loading = true;
      const cpf = localStorage.getItem('cpf');
      const formattedDate = this.convertDateFormat(this.nascimento);
      axios
          .post(baseUrl + "valida-nascimento", {cpf: cpf, nascimento: formattedDate})
          .then(response => {
            if (response.data.exists == 1) {
              if (response.data.servidor) {
                this.hasMatricula = false;
                this.validaNascimento = true;
              } else {
                this.validaNascimento = true;
                this.validaMatricula = true;
              }
              this.loading = false;
              this.showNascimento = false;
            } else {
              this.loading = false;
              this.$swal.fire({
                icon: "error",
                title: "",
                text: "Dados incorretos",
                customClass: {
                  confirmButton: "my-custom-button-class"
                }
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
    },
    validaMatriculaApi() {
      this.loading = true;
      const cpf = localStorage.getItem('cpf');
      axios
          .post(baseUrl + "valida-matricula", {cpf: cpf, matricula: this.matricula})
          .then(response => {
            if (response.data.servidor == 1) {
              if (response.data.servidor) {
                this.loading = false;
                this.validaMatricula = true;
              } else {
                this.loading = false;
                this.$swal.fire({
                  icon: "error",
                  title: "",
                  text: "Dados incorretos",
                  customClass: {
                    confirmButton: "my-custom-button-class"
                  }
                });
              }
            } else {
              this.loading = false;
              this.$swal.fire({
                icon: "error",
                title: "",
                text: "Dados incorretos",
                customClass: {
                  confirmButton: "my-custom-button-class"
                }
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
    },
    validaDadosApi() {
      this.loading = true;
      const formattedDate = this.convertDateFormat(this.nascimento);
      axios
          .post(baseUrl + "valida-dados-pre", {telefone: this.telefone, email: this.email, nascimento: formattedDate})
          .then(response => {
            const valid = response.data.valid;
            console.log(response);

            if (valid) {
              localStorage.setItem("email", this.email);
              localStorage.setItem("telefone", this.telefone);
              localStorage.setItem("nascimento", this.nascimento);

              this.$router.push("/password");
            } else {
              if (response.data.errors) {
                this.loading = false;
                let errorText = "";
                for (const [, value] of Object.entries(response.data.errors)) {
                  errorText += value[0] + "<br>";
                }
                this.$swal.fire({
                  icon: "error",
                  title: "",
                  html: errorText,
                  customClass: {
                    confirmButton: "my-custom-button-class"
                  }
                });
              } else {
                this.$swal.fire({
                  icon: "error",
                  title: "",
                  text: "CPF não encontrado",
                  customClass: {
                    confirmButton: "my-custom-button-class"
                  }
                });
              }
            }
          })
          .catch(error => {
            console.error(error);
            this.validCpf = false;
            this.userName = "";
          });
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');

.card-slide {
  height: 417px;
  flex-shrink: 0;
  border-radius: 20px 0px 0px 20px;
  background: #fbfbfb;
  box-shadow: 10px 10px 20px -10px #000; /* largura do card */
}

.btn-contiuar {
  color: #1384ad;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 15px;
  width: 200px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 80px;
  background: #e0e6ed;
  box-shadow: 0px 10px 20px -10px #003da4;
}

.text-validar {
  margin-left: 107px;
  color: var(--Black, #0e1726);
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.welcome {
  margin-left: 107px;
  color: #0e1726;
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.rounded-top-input1 {
  border-radius: 6px;
  border: 1px solid var(--White-light, #e0e6ed);
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
}

.form-control::placeholder {
  color: white; /* Defina a cor desejada para o texto do placeholder */
}

.my-custom-button-class {
  background-color: #c82333; /* cor de fundo personalizada de perigo no hover */
  border-color: #c82333; /* cor da borda personalizada no hover */
  /* adicione outros estilos personalizados conforme necessário */
}

label {
  color: #fff;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.primeiro-acesso {
  margin-top: 33px;
  color: #fff;
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card-login {
  height: 417px;
  flex-shrink: 0;
  border-radius: 0px 20px 20px 0px;
  background: #1384ad;
  box-shadow: 10px 10px 20px -10px #000;
}

.centralizar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.footer {
  margin-bottom: 15px;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  padding: 10px;
}

.direito {
  color: #bfc9d4;
  font-family: 'Nunito Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-ajuda {
  color: #1384ad;
  font-family: 'Nunito Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
}

.funcionamento {
  margin-top: 12px;
  margin-left: 81px;
  width: 350px;
  height: 48px;
  flex-shrink: 0;
  color: #0E1726;

  font-family: "Nunito Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
