import axios from 'axios';

async function consultaUsuario() {
    const authToken = localStorage.getItem('authToken');
    const baseUrl = process.env.VUE_APP_BASE_URL;

    if (authToken) {
        try {
            const response = await axios.get(baseUrl + 'v2/user', {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });

            const user = response.data;
            console.log('Usuário consultado', user);
            return user;
        } catch (error) {
            localStorage.clear();
            throw new Error('Erro ao consultar usuário: ' + error.message);
        }
    } else {
        localStorage.clear();
        throw new Error('Token de autenticação não encontrado');
    }
}

async function termoUso() {
    try {
        const user = await consultaUsuario();

        if (user && user.termo === 0) {
            //alert('oi');
            return '/termoaceite';
        } else {
            /////alert('passou');
            return '/myusers';
        }
    } catch (error) {
        console.error('Erro ao verificar termo de uso', error);
        throw error;
    }
}

export { consultaUsuario, termoUso };
