<template>
  <div>
    <Menus></Menus>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <button :class="{ 'btn-info': currentTab === 1, 'btn-secondary': currentTab !== 1 }" class="btn btn-block"
                  @click="selectTab(1)">RESUMO GERAL
          </button>
        </div>
        <div class="col-md-6">
          <button :class="{ 'btn-info': currentTab === 2, 'btn-secondary': currentTab !== 2 }" class="btn btn-block"
                  @click="selectTab(2)">RESUMO CONSIGNATÁRIA
          </button>
        </div>
      </div>
      <br>
      <br>
      <div v-if="activeTab === 1">
        <!-- Content for Tab 1 -->
        <div class="row">
          <div class="col-md-6">
            <div class="container">
              <div class="card card-primary">
                <div style="height: 50px" class="card-header">
                  <h3 class="text-white">Filtros</h3>
                </div>
                <div class="card-body">


                  <div class="form-group row">
                    <div class="col-md-3"><label for="">Consignante</label></div>
                    <div class="col-md-9">
                      <select v-model="consignante" class="form-control" name="" id="">
                        <option value=""></option>
                        <option value="1">Porto Alegre</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-3"><label for=""> Averbador</label></div>
                    <div class="col-md-9">
                      <select :disabled="consignante == null" v-model="averbador" class="form-control" name="" id="">
                        <option value=""></option>
                        <option value="1">Porto Alegre</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-3"><label for=""> Mês/Ano</label></div>
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-6"><select v-model="mes" class="form-control" name="" id="">
                          <option value=""></option>
                          <option value="7">Julho</option>
                          <option value="8">Agosto</option>
                          <option value="9">Setembro</option>
                        </select></div>
                        <div class="col-md-6"><select v-model="ano" class="form-control" name="" id="">
                          <option value=""></option>
                          <option value="2023">2023</option>

                        </select></div>

                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                      <button :disabled="averbador == null || mes == null || ano == null" @click="getPesquisa"
                              class="btn btn-primary btn-block">
                        <font-awesome-icon :icon="['fas', 'search']"/>
                        Consultar
                      </button>
                    </div>
                    <div class="col-md-4"></div>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div v-if="resultados" class="col-md-12">
            <table class="table table-striped">
              <thead>
              <tr style="color: white;background-color: #1384AD">
                <th>CONSIGNAÇÕES</th>
                <th>INÍCIO</th>
                <th>LIQUIDADOS</th>
                <th>REFINANCIADOS</th>
                <th>PORTADOS</th>
                <th>FINAL</th>
                <th>TOTAL DESCONTADO</th>
                <th>SALDO SEM DESCONTO</th>
                <th>CONSIGNADOS</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="resultado in resultados" :key="resultado.tipo">
                <td>{{ resultado.tipo }}</td>
                <td>{{ resultado.inicio }}</td>
                <td>{{ resultado.liquidados }}</td>
                <td>{{ resultado.refinanciados }}</td>
                <td>{{ resultado.portados }}</td>
                <td>{{ resultado.final }}</td>
                <td>{{ resultado.total_descontado }}</td>
                <td>{{ resultado.saldo_sem_desconto }}</td>
                <td>{{ resultado.consignados }}</td>

              </tr>
              </tbody>
            </table>
          </div>
          <div v-if="testData" class="col-md-6">
            <div class="card">
              <div class="card-body text-center">
                <h3>Contratos</h3>
                <PieChart :chartData="testData" :chart-id="'myChart'" :options="chartOptions"/>
              </div>
            </div>

          </div>
          <div v-if="testData2" class="col-md-6">
            <div class="card">
              <div class="card-body text-center">
                <h3>Descontos</h3>
                <PieChart :chartData="testData2" :chart-id="'myChart'" :options="chartOptions"/>
              </div>
            </div>

          </div>


        </div>
      </div>
      <div v-if="activeTab === 2">
        <div class="col-md-6">
          <div class="container">

            <div class="card card-primary">
              <div style="height: 50px" class="card-header">
                <h3 class="text-white">Filtros</h3>
              </div>
              <div class="card-body">


                <div class="form-group row">
                  <div class="col-md-3"><label for="">Consignante</label></div>
                  <div class="col-md-9">
                    <select v-model="consignante" class="form-control" name="" id="">
                      <option value=""></option>
                      <option value="1">Porto Alegre</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3"><label for=""> Averbador</label></div>
                  <div class="col-md-9">
                    <select :disabled="consignante == null" v-model="averbador" class="form-control" name="" id="">
                      <option value=""></option>
                      <option value="1">Porto Alegre</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3"><label for=""> Consignação</label></div>
                  <div class="col-md-9">
                    <select :disabled="consignante == null" v-model="tipo_consignacao" class="form-control" name=""
                            id="">
                      <option value=""></option>
                      <option value="1">Empréstimos</option>
                      <option value="2">Convenios</option>
                      <option value="3">Mensalidades</option>
                      <option value="4">Cartão de Crédito</option>
                      <option value="5">Cartão Benefícios</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3"><label for=""> Mês/Ano</label></div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-6"><select v-model="mes" class="form-control" name="" id="">
                        <option value=""></option>
                        <option value="7">Julho</option>
                        <option value="8">Agosto</option>
                        <option value="9">Setembro</option>
                      </select></div>
                      <div class="col-md-6"><select v-model="ano" class="form-control" name="" id="">
                        <option value=""></option>
                        <option value="2023">2023</option>

                      </select></div>

                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <button :disabled="averbador == null || mes == null || ano == null||tipo_consignacao == null "
                            @click="getPesquisaTipo"
                            class="btn btn-primary btn-block">
                      <font-awesome-icon :icon="['fas', 'search']"/>
                      Consultar
                    </button>
                  </div>
                  <div class="col-md-4"></div>

                </div>

              </div>
            </div>
          </div>
        </div>

        <div v-if="resultados2" class="col-md-12">
          <table class="table table-striped">
            <thead>
            <tr style="color: white;background-color: #1384AD">
              <th>CONSIGNAÇÕES</th>
              <th>INÍCIO</th>
              <th>LIQUIDADOS</th>
              <th>REFINANCIADOS</th>
              <th>PORTADOS</th>
              <th>FINAL</th>
              <th>TOTAL DESCONTADO</th>
              <th>SALDO SEM DESCONTO</th>
              <th>CONSIGNADOS</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="resultado in resultados2" :key="resultado.tipo">
              <td>{{ resultado.tipo }}</td>
              <td>{{ resultado.inicio }}</td>
              <td>{{ resultado.liquidados }}</td>
              <td>{{ resultado.refinanciados }}</td>
              <td>{{ resultado.portados }}</td>
              <td>{{ resultado.final }}</td>
              <td>{{ resultado.total_descontado }}</td>
              <td>{{ resultado.saldo_sem_desconto }}</td>
              <td>{{ resultado.consignados }}</td>

            </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div v-if="testData3" class="col-md-6">
            <div class="card">
              <div class="card-body text-center">
                <h3>Contratos</h3>
                <PieChart :chartData="testData3" :chart-id="'myChart'" :options="chartOptions"/>
              </div>
            </div>

          </div>
          <div v-if="testData4" class="col-md-6">
            <div class="card">
              <div class="card-body text-center">
                <h3>Descontos</h3>
                <PieChart :chartData="testData4" :chart-id="'myChart'" :options="chartOptions"/>
              </div>
            </div>

          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>

import {PieChart} from 'vue-chart-3';
import {Chart, registerables} from "chart.js";
import Menus from "@/components/Menus.vue";
import axios from 'axios';

Chart.register(...registerables);
const baseUrl = process.env.VUE_APP_BASE_URL;

export default {
  data() {
    return {
      currentTab: 1,
      activeTab: 1,
      mes: null,
      ano: null,
      consignante: null,
      averbador: null,
      resultados: null,
      resultados2: null,
      chartOptions: generateChartOptions(),
      testData: null,
      testData2: null,
      testData3: null,
      testData4: null,
      tipo_consignacao: null
    }
  },
  methods: {
    selectTab(tabNumber) {
      this.activeTab = tabNumber;
      this.currentTab = tabNumber;
    },
    async getPesquisa() {


      axios.get(`${baseUrl}extrato/${this.mes}/${this.ano}`)
          .then(response => {

            this.resultados = response.data;
            if (response.data.success) {
              this.$swal.fire({
                icon: 'success',
                title: '',
                text: response.data.message
              })


              //window.location.reload();

            }
          })
          .catch(error => console.log(error))

      const apiUrl = `${baseUrl}grafico-extrato/${this.mes}/${this.ano}`;

      try {
        const busca = await axios.get(apiUrl);

        this.testData = generateTestData(busca);


      } catch (error) {
        console.error('Erro ao buscar dados da API 1', error);
      }
      const apiUrl2 = `${baseUrl}grafico-extrato-desconto/${this.mes}/${this.ano}`;

      try {
        const busca2 = await axios.get(apiUrl2);

        this.testData2 = generateTestData2(busca2);


      } catch (error) {
        console.error('Erro ao buscar dados da API 1', error);
      }
      console.log(apiUrl);


      //console.log(request);
    },

    async getPesquisaTipo() {


      axios.get(`${baseUrl}extrato-tipo-consignacao/${this.tipo_consignacao}/${this.mes}/${this.ano}`)
          .then(response => {

            this.resultados2 = response.data;
            if (response.data.success) {
              this.$swal.fire({
                icon: 'success',
                title: '',
                text: response.data.message
              })


              //window.location.reload();

            }
          })
          .catch(error => console.log(error))

      const apiUrl = `${baseUrl}grafico-extrato-tipo/${this.tipo_consignacao}/${this.mes}/${this.ano}`;

      try {
        const busca = await axios.get(apiUrl);

        this.testData3 = generateTestData3(busca);


      } catch (error) {
        console.error('Erro ao buscar dados da API 1', error);
      }

      const apiUrl2 = `${baseUrl}grafico-extrato-desconto-tipo/${this.tipo_consignacao}/${this.mes}/${this.ano}`;

      try {
        const busca2 = await axios.get(apiUrl2);

        this.testData4 = generateTestData4(busca2);


      } catch (error) {
        console.error('Erro ao buscar dados da API 1', error);
      }


      //console.log(request);
    },

  },
  components: {Menus, PieChart},

};

function generateTestData(dados) {
  return {
    labels: dados.data.labels,
    datasets: [
      {
        backgroundColor: dados.data.datasets[0].backgroundColor,
        data: dados.data.datasets[0].data
      }
    ]
  };
}

function generateTestData2(dados) {
  return {
    labels: dados.data.labels,
    datasets: [
      {
        backgroundColor: dados.data.datasets[0].backgroundColor,
        data: dados.data.datasets[0].data
      }
    ]
  };
}

function generateTestData3(dados) {
  return {
    labels: dados.data.labels,
    datasets: [
      {
        backgroundColor: dados.data.datasets[0].backgroundColor,
        data: dados.data.datasets[0].data
      }
    ]
  };
}

function generateTestData4(dados) {
  return {
    labels: dados.data.labels,
    datasets: [
      {
        backgroundColor: dados.data.datasets[0].backgroundColor,
        data: dados.data.datasets[0].data
      }
    ]
  };
}

function generateChartOptions() {
  return {
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
        position: 'right',
      },
      y: {
        beginAtZero: true,
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          autoSkip: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        // position: 'botton',
        labels: {
          fontsize: 30
        }
      },
      datalabels: {
        anchor: 'end', // Define a ancoragem à extremidade final (à direita) da barra
        align: 'end', // Define o alinhamento à extremidade final (à direita) da barra
        color: 'black', // Cor do texto
        font: {
          weight: 'bold', // Define o peso da fonte como negrito
        },
        formatter: (value) => {
          return value; // Exibe o valor do eixo x
        },
      }
      // tooltip: {
      //   position: 'average',
      //   callbacks: {
      //     label: function (context) {
      //       const label = context.dataset.label || '';
      //       const value = context.parsed.x || 0;
      //       return `${label}: ${value}`;
      //     },
      //   },
      // },
    },
  };
}


</script>

<style></style>