<template>

  <div>
    <novo-login>
      <template v-slot:esquerdo>



        <div class="funcionamento">
          Crie uma nova senha para acesso ao sistema de acordo com as seguintes regras: <br>

        </div>
        <div class="criterio">

          <li>A senha deverá ter no mínimo 8 e no máximo 12 caracteres;</li>
          <li>A senha deverá conter letras maiúsculas e minúsculas;</li>
          <li>A senha deverá conter números e caracteres especiais;</li>
        </div>
      </template>
      <template v-slot:voltar>
        <div style="height: 20px" class="d-flex d-fix justify-content-end align-items-center">
          <router-link to="login"
                       style="margin-top: 40px">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M4.5 12L9.5 7M4.5 12L9.5 17M4.5 12H14.5C16.1667 12 19.5 13 19.5 17" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </router-link>
        </div>
      </template>

      <template v-slot:direito>
        <div class="text-center">
          <img class="logo-outline" src="img/logo2.png"/>

        </div>


        <div class="container">

          <div>
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <label for="password">Digite nova senha</label>
                <div class="input-container">
                  <input placeholder="SENHA" class="form-control rounded-top-input1" type="password" id="password"
                         v-model="password">

                  <div class="icon-container" @click="togglePasswordVisibility">
                    <!-- Seu ícone SVG aqui -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clip-path="url(#clip0_1902_1412)">
                        <path d="M0.833496 9.99998C0.833496 9.99998 4.16683 3.33331 10.0002 3.33331C15.8335 3.33331 19.1668 9.99998 19.1668 9.99998C19.1668 9.99998 15.8335 16.6666 10.0002 16.6666C4.16683 16.6666 0.833496 9.99998 0.833496 9.99998Z" stroke="#1384AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" stroke="#1384AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_1902_1412">
                          <rect width="20" height="20" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div v-if="password.length > 0" :style="passwordStrengthStyle">
                  Força da senha: {{ passwordStrengthText }}
                </div>
              </div>


              <div class="form-group">
                <label for="password_confirmation">Confirmar a nova senha</label>
                <div class="input-container">
                  <input placeholder="CONFIRME SUA SENHA" class="form-control rounded-top-input1" type="password"
                         id="password_confirmation" v-model="passwordConfirmation"
                         :disabled="!isPasswordValid">
                  <div class="icon-container" @click="togglePasswordVisibilityConf">
                    <!-- Seu ícone SVG aqui -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clip-path="url(#clip0_1902_1412)">
                        <path d="M0.833496 9.99998C0.833496 9.99998 4.16683 3.33331 10.0002 3.33331C15.8335 3.33331 19.1668 9.99998 19.1668 9.99998C19.1668 9.99998 15.8335 16.6666 10.0002 16.6666C4.16683 16.6666 0.833496 9.99998 0.833496 9.99998Z" stroke="#1384AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" stroke="#1384AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_1902_1412">
                          <rect width="20" height="20" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>


                <div v-if="passwordConfirmation.length > 0 && !isPasswordConfirmed" :style="mismatchStyle">
                  As senhas não coincidem
                </div>
              </div>

              <div class="form-group text-center">
                <button @click="updateDadosApi" class="btn btn-contiuar" type="submit"
                        :disabled="!isPasswordValid || !isPasswordConfirmed">Enviar
                </button>
              </div>


            </form>
            <p v-if="passwordErrors.length > 0" style="color: red;">
              Erros na senha:
              <ul>
                <li v-for="error in passwordErrors" :key="error">{{ error }}</li>
              </ul>
            </p>
          </div>


        </div>

      </template>
    </novo-login>

  </div>


</template>

<script>

//import MyUsers from './MyUsers.vue';

//import axios from "axios";



const baseUrl = process.env.VUE_APP_BASE_URL;

import zxcvbn from 'zxcvbn';

import axios from "axios";
import NovoLogin from "@/components/NovoLogin.vue";

export default {
  components: {NovoLogin},
  data() {
    return {
      password: "",
      passwordConfirmation: "",
      passwordErrors: [],
      passwordVisible:false,
      passwordVisibleConf:false

    };
  },
  computed: {
    isPasswordValid() {
      const result = zxcvbn(this.password);
      return result.score >= 3;
    },
    isPasswordConfirmed() {
      return this.password === this.passwordConfirmation;
    },
    passwordStrength() {
      const result = zxcvbn(this.password);
      return result.score;
    },
    passwordStrengthText() {
      const score = this.passwordStrength;
      if (score === 0 || score === 1) {
        return 'Fraca';
      } else if (score === 2) {
        return 'Média';
      } else if (score === 3) {
        return 'Boa';
      } else if (score === 4) {
        return 'Forte';
      } else {
        return '';
      }
    },
    passwordStrengthStyle() {
      const score = this.passwordStrength;
      let color = 'black';
      if (score === 0 || score === 1) {
        color = 'red';
      } else if (score === 2) {
        color = 'orange';
      } else if (score === 3) {
        color = 'yellowgreen';
      } else if (score === 4) {
        color = 'green';
      }
      return {
        backgroundColor: '#f9f9f9',  // Destaque leve
        marginTop: '3px',
        padding: '5px 10px',
        borderRadius: '4px',
        fontWeight: 'bold',
        color: color
      };
    },
    mismatchStyle() {
      return {
        color: 'red',
        marginTop: '3px',
        padding: '5px 10px',
        borderRadius: '4px',
        backgroundColor: '#ffe6e6'  // Destaque de erro
      };
    },
    passwordStrengthColor() {
      const score = this.passwordStrength;
      if (score === 0 || score === 1) {
        return 'red';
      } else if (score === 2) {
        return 'orange';
      } else if (score === 3) {
        return 'yellowgreen';
      } else if (score === 4) {
        return 'green';
      } else {
        return 'black';
      }
    }
  },
  methods: {

 //   passwordConfirmation
    togglePasswordVisibilityConf() {
      this.passwordVisibleConf = !this.passwordVisibleConf;
      const input2 = document.getElementById('password_confirmation');
      if (this.passwordVisibleConf) {
        input2.type = 'text';
      } else {
        input2.type = 'password';
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
      const input = document.getElementById('password');
      if (this.passwordVisible) {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
    },
    submitForm() {
      this.passwordErrors = [];

      if (!this.isPasswordValid) {
        this.passwordErrors.push("A senha deve atender a todas as regras.");
      }

      if (!this.isPasswordConfirmed) {
        this.passwordErrors.push("As senhas não coincidem.");
      }

      if (this.passwordErrors.length === 0) {
        // Senha válida, aqui você pode enviar o formulário ou fazer outras ações necessárias.

      }
    },
    updateDadosApi() {
      const cpf = localStorage.getItem('cpf');
      const email = localStorage.getItem('email');
      const telefone = localStorage.getItem('telefone');
      axios.post(baseUrl + 'valida-dados-api', {
        cpf: cpf,
        nascimento: this.nascimento,
        email: email,
        telefone: telefone,
        password: this.password,
        password_confirmation: this.passwordConfirmation
      })
          .then(response => {
            console.log(response)
            if (response.data.update == 1) {
              this.$swal.fire({
                icon: 'success',
                title: '',
                text: 'Dados Atualizados com Sucesso',
                customClass: {
                  confirmButton: 'my-custom-button-class'
                }
              });

              this.$router.push('/');

            } else {
              this.$swal.fire({
                icon: 'error',
                title: '',
                text: 'Dados incorretos',
                customClass: {
                  confirmButton: 'my-custom-button-class'
                }
              });
            }

          })
          .catch(error => {
            console.error(error);
            //this.validCpf = false;
            //this.showPassword = false;
            //this.userName = '';
          });
    }
  },


};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');

.rounded-top-input1 {
  border-radius: 6px;
  border: 1px solid var(--White-light, #E0E6ED);
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;

}
.criterio{
  margin-left: 81px;
  color: var(--Black, #0E1726);
  font-family: "Nunito Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;}
.logo-outline {
  margin-top: 30px;
  width: 90px;

  flex-shrink: 0;
}


.form-control::placeholder {
  color: var(--White-light-dark, #888EA8);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; /* Defina a cor desejada para o texto do placeholder */
}



.logo-outline {
  margin-top: 30px;
  width: 90px;

  flex-shrink: 0;
}

label {
  color: white;
}

.btn-contiuar {
  color: #1384AD;
  font-family: 'nunito Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 15px;
  width: 200px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 80px;
  background: #E0E6ED;
  text-transform: uppercase;
  box-shadow: 0px 10px 20px -10px #003DA4;
}



.input-container {
  display: flex;
  align-items: center;
}

.icon-container {
  margin-left: -30px; /* Ajuste conforme necessário para alinhar o ícone à direita */
  position: relative;
  z-index: 2; /* Para garantir que o ícone esteja acima do input */
}

.input-container input {
  flex: 1;
  padding-right: 40px; /* Garante espaço suficiente para o ícone */
}

.funcionamento {
  margin-top: 12px;
  margin-left: 81px;
  width: 350px;
  height: 48px;
  flex-shrink: 0;
  color: #0E1726;

  font-family: "Nunito Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

</style>
