<template>
  <div>
    <Menus></Menus>
    <br>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <br>
          <div class="form-group">
            <label for="">Caminho do Diretorio</label>
            <input class="form-control" v-model="diretorio" type="text" name="" id="">
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <button :disabled="diretorio===''" @click="gravaDiretorio" class="btn btn-outline-primary btn-block">Salvar</button>
              </div>
              <div class="col-md-6">
                <button @click="limpar" class="btn btn-outline-danger btn-block">Limpar</button>
              </div>


            </div>

          </div>
        </div>
      </div>

      <div class="card">

        <table ref="dataTable" class="table table-striped">
          <thead>
          <tr class="text-center" style="color: white;background-color: #1384AD">
            <th>Diretorio</th>
            <th>Usuario</th>
            <th>Data de Criação</th>
          </tr>
          </thead>
          <tbody>
          <tr class="text-center" v-for="resultado in resultados" :key="resultado.id">
            <td>{{ resultado.caminho }}</td>
            <td>{{ resultado.user.name }}</td>
            <td>{{ resultado.created_at_formatted }}</td>

          </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>

</template>

<script>


import Menus from "@/components/Menus.vue";
import axios from "axios";
import $ from "jquery";
import Swal from 'sweetalert2'

// import $ from "jquery";

const baseUrl = process.env.VUE_APP_BASE_URL;
const authToken = localStorage.getItem('authToken');
const headers = {
  'Authorization': `Bearer ${authToken}`
};
export default {
  data() {
    return {
      diretorio: "",
      resultados: [],
      dataTable: null,
    }
  },


  name: 'TelaDiretorio',
  components: {Menus},
  methods: {
    limpar(){
      this.diretorio='';
    },
    async getDiretorios() {


      //  alert(cd_usuario);

      const response = await axios.get(`${baseUrl}busca-diretorios`, {headers: headers})
      this.resultados = response.data
      console.log(response.data)
    },

    initializeDataTable() {
      // Salve a referência à instância do Vue


      // Inicialize o DataTable dentro do hook mounted
      if ($.fn.DataTable.isDataTable(this.$refs.dataTable)) {
        // Destroi a instância DataTable existente antes de inicializar novamente
        $(this.$refs.dataTable).DataTable().destroy();
      }

      // Inicialize o DataTable
      this.dataTable = $(this.$refs.dataTable).DataTable({
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Portuguese-Brasil.json'
        },
        columns: [
          {data: 'caminho'},
          {data: 'user.name'},
          {data: 'created_at_formatted'},
        ],
      });
    },

    async gravaDiretorio() {
      // Exibindo o alerta de carregamento
      Swal.fire({
        title: 'Carregando...',
        html: '<div class="loading-container"><div class="loading-spinner"></div></div>',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });

      try {
        // Parâmetros para a requisição POST
        const params = {
          diretorio: this.diretorio
        };

        // Realizando a requisição POST
        const response = await axios.post(`${baseUrl}grava-diretorios`, params, {headers: headers});
        this.diretorio = ''
        // Lógica após a resposta bem-sucedida
        console.log(response.data); // Exemplo de como utilizar os dados da resposta
        Swal.fire({
          title: 'Sucesso!',
          text: 'O diretório foi gravado com sucesso.',
          icon: 'success',
          timer: 3000 // Tempo em milissegundos (3 segundos)
        }).then(() => {
          // Fechando o alerta de carregamento após exibir a mensagem de sucesso
          Swal.close();
          this.getDiretorios()
        });

      } catch (error) {
        // Lógica para lidar com erros
        console.error('Erro ao gravar o diretório:', error);
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao gravar o diretório.',
          icon: 'error'
        }).then(() => {
          // Fechando o alerta de carregamento após exibir a mensagem de erro
          Swal.close();
        });
      }
    }


  },

  watch: {
    resultados: {
      handler() {
        // Atualize o DataTable quando a lista de resultados mudar
        if (this.dataTable) {
          this.dataTable.clear().rows.add(this.resultados).draw();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getDiretorios(); // Carrega os dados iniciais
    this.initializeDataTable(); // Carrega os dados iniciais
  },
  mounted() {
    this.initializeDataTable();


  },

};


</script>

<style>
#myChart-legend {
  font-size: 70px; /* Ajuste o tamanho da fonte conforme necessário */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>