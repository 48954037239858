<template>
  <div>
    <Menus></Menus>
    <div class="container-fluid">

      <div class="row">
        <div class="col-md-4">
          <div class="card card-primary">
            <div style="height: 50px" class="card-header">
              <h3 class="text-white">Filtros</h3>
            </div>
            <div class="card-body">


              <div class="form-group row">
                <div class="col-md-3"><label for="">Consignante</label></div>
                <div class="col-md-9">
                  <select v-model="consignante" class="form-control" name="" id="">
                    <option value=""></option>
                    <option value="1">Porto Alegre</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-3"><label for=""> Averbador</label></div>
                <div class="col-md-9">
                  <select :disabled="consignante == null" v-model="averbador" class="form-control" name="" id="">
                    <option value=""></option>
                    <option value="1">Porto Alegre</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-3"><label for=""> Mês/Ano</label></div>
                <div class="col-md-9">
                  <div class="row">
                    <div class="col-md-6"><select v-model="mes"  class="form-control" name="" id="">
                      <option value=""></option>
                      <option value="7">Julho</option>
                      <option value="8">Agosto</option>
                      <option value="9">Setembro</option>
                    </select></div>
                    <div class="col-md-6"><select v-model="ano"  class="form-control" name="" id="">
                      <option value=""></option>
                      <option value="2023">2023</option>

                    </select></div>

                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                  <button :disabled="averbador == null || mes == null || ano == null" @click="geraTodos"
                          class="btn btn-primary btn-block">
                    <font-awesome-icon :icon="['fas', 'search']"/>
                    Consultar
                  </button>
                </div>
                <div class="col-md-4"></div>

              </div>

            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div v-if="testData" class="col-md-4">
              <div class="card">
                <div class="card-body text-center">
                  <p>TOTAL SERVIDORES/TIPO CONSIGNAÇÃO</p>
                  <BarChart :chartData="testData" :chart-id="'myChart'" :options="chartOptions"/>
                </div>
              </div>
            </div>
            <div v-if="consig" class="col-md-4">
              <div class="card">
                <div class="card-body text-center">
                  <p>TOTAL CONTRATOS/TIPO CONSIGNAÇÃO</p>
                  <BarChart :chartData="consig" :options="chartOptions"/>
                </div>
              </div>
            </div>
            <div v-if="consig2" class="col-md-4">
              <div class="card">
                <div class="card-body text-center">
                  <p>TOTAL DESCONTOS/TOTAL CONSIGNAÇÃO</p>
                  <BarChart :chartData="consig2" :options="chartOptions"/>
                </div>
              </div>
            </div>
            <div v-if="consig3" class="col-md-4">
              <div class="card">
                <div class="card-body text-center">
                  <p>TOTAL SERVIDORES/CONSIGNATÁRIA</p>
                  <BarChart :chartData="consig3" :options="chartOptions"/>
                </div>
              </div>
            </div>
            <div v-if="consig4" class="col-md-4">
              <div class="card">
                <div class="card-body text-center">
                  <p>TOTAL CONTRATOS/TOTAL CONSIGNATÁRIA</p>
                  <BarChart :chartData="consig4" :options="chartOptions"/>
                </div>
              </div>
            </div>
            <div v-if="consig5" class="col-md-4">
              <div class="card">
                <div class="card-body text-center">
                  <p>TOTAL SERVIDORES/TOTAL CONSIGNAÇÃO</p>
                  <BarChart :chartData="consig5" :options="chartOptions"/>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>

import {BarChart} from 'vue-chart-3';
import {Chart, registerables} from "chart.js";
import axios from "axios";
import Menus from "@/components/Menus.vue";
const baseUrl = process.env.VUE_APP_BASE_URL;

Chart.register(...registerables);

export default {
  data() {
    return {
      mes: null,
      ano: null,
      testData: null,
      consignante: null,
      averbador: null,
      consig: null,
      consig2: null,
      consig3: null,
      consig4: null,
      consig5: null,
      chartOptions: generateChartOptions(),
    }
  },
  methods: {
    geraTodos() {
      this.consultarDados()
      this.consultarDado2()
      this.consultarDado3()
      this.consultarDado4()
      this.consultarDado5()
      this.consultarDado6()
    },
    async consultarDados() {
      // Verifica se ambos mes e ano foram fornecidos
      if (this.mes && this.ano) {
        const apiUrl = `${baseUrl}bi/${this.mes}/${this.ano}`;

        try {
          const response = await axios.get(apiUrl);

          //console.log(response);
          this.testData = generateTestData(response.data);

          console.log(response.data[0].dados)
        } catch (error) {
          console.error('Erro ao buscar dados da API 1', error);
        }
        console.log(apiUrl);
      }
    },

    async consultarDado2() {
      // Verifica se ambos mes e ano foram fornecidos
      if (this.mes && this.ano) {
        const apiUrl = `${baseUrl}bi2/${this.mes}/${this.ano}`;

        try {
          const response = await axios.get(apiUrl);

          //console.log(response);

          this.consig = generateTestData2(response.data);

          // console.log(response.data[0].dados)
        } catch (error) {
          console.error('Erro ao buscar dados da API 1', error);
        }
        console.log(apiUrl);
      }
    },
    async consultarDado3() {
      // Verifica se ambos mes e ano foram fornecidos
      if (this.mes && this.ano) {
        const apiUrl = `${baseUrl}bi3/${this.mes}/${this.ano}`;

        try {
          const response = await axios.get(apiUrl);

          //console.log(response);


          this.consig2 = generateTestData3(response.data);

          // console.log(response.data[0].dados)
        } catch (error) {
          console.error('Erro ao buscar dados da API 1', error);
        }
        console.log(apiUrl);
      }
    },
    async consultarDado4() {
      if (this.mes && this.ano) {
        const apiUrl = `${baseUrl}bi4/${this.mes}/${this.ano}`;

        try {
          const response = await axios.get(apiUrl);

          //console.log(response);


          this.consig3 = generateTestData4(response.data);

          // console.log(response.data[0].dados)
        } catch (error) {
          console.error('Erro ao buscar dados da API 1', error);
        }
        console.log(apiUrl);
      }
    },
    async consultarDado5() {
      if (this.mes && this.ano) {
        const apiUrl = `${baseUrl}bi5/${this.mes}/${this.ano}`;

        try {
          const response = await axios.get(apiUrl);

          //console.log(response);


          this.consig4 = generateTestData5(response.data);

          // console.log(response.data[0].dados)
        } catch (error) {
          console.error('Erro ao buscar dados da API 1', error);
        }
        console.log(apiUrl);
      }
    },
    async consultarDado6() {
      if (this.mes && this.ano) {

        const apiUrl = `${baseUrl}bi6/${this.mes}/${this.ano}`;

        try {
          const response = await axios.get(apiUrl);

          //console.log(response);


          this.consig5 = generateTestData6(response.data);

          // console.log(response.data[0].dados)
        } catch (error) {
          console.error('Erro ao buscar dados da API 1', error);
        }
        console.log(apiUrl);
      }
    },


  },
  name: 'DashboardId',
  components: {Menus, BarChart},

};

function generateTestData(dados) {
  console.log(dados)
  return {
    labels: ['CONSIGNAÇÕES'],
    datasets: dados.map(item => ({
      label: item.label,
      backgroundColor: item.backgroundColor,
      data: [item.data],
    })),
  };
}

function generateTestData2(dados) {
  return {
    labels: ['CONSIGNAÇÕES'],
    datasets: dados.map(item => ({
      label: item.label,
      backgroundColor: item.backgroundColor,
      data: [item.data],
    })),
  };
}

function generateTestData3(dados) {

  return {
    labels: ['CONSIGNAÇÕES'],
    datasets: dados.map(item => ({
      label: item.label,
      backgroundColor: item.backgroundColor,
      data: [item.data],
    })),
  };
}

function generateTestData4(dados) {
  console.log(dados)
  return {
    labels: ['CONSIGNAÇÕES'],
    datasets: dados.map(item => ({
      label: item.label,
      backgroundColor: item.backgroundColor,
      data: [item.data],
    })),
  };
}

function generateTestData5(dados) {
  console.log(dados)
  return {
    labels: ['CONSIGNAÇÕES'],
    datasets: dados.map(item => ({
      label: item.label,
      backgroundColor: item.backgroundColor,
      data: [item.data],
    })),
  };
}

function generateTestData6(dados) {
  return {
    labels: ['CONSIGNAÇÕES'],
    datasets: dados.map(item => ({
      label: item.label,
      backgroundColor: item.backgroundColor,
      data: [item.data],
    })),
  };
}

function generateChartOptions() {
  return {
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        position: 'right',
      },
      y: {
        beginAtZero: true,
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          autoSkip: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          fontsize: 30
        }
      },
      datalabels: {
        anchor: 'end', // Define a ancoragem à extremidade final (à direita) da barra
        align: 'end', // Define o alinhamento à extremidade final (à direita) da barra
        color: 'black', // Cor do texto
        font: {
          weight: 'bold', // Define o peso da fonte como negrito
        },
        formatter: (value) => {
          return value; // Exibe o valor do eixo x
        },
      }
      // tooltip: {
      //   position: 'average',
      //   callbacks: {
      //     label: function (context) {
      //       const label = context.dataset.label || '';
      //       const value = context.parsed.x || 0;
      //       return `${label}: ${value}`;
      //     },
      //   },
      // },
    },
  };
}
</script>

<style>
#myChart-legend {
  font-size: 70px; /* Ajuste o tamanho da fonte conforme necessário */
}
</style>