<template>
  <div>
    <input type="text" v-model="questao"> <br>
    <input type="text" v-model="alternativa"><button @click="addAlternativa">+</button> <br>
    <p>{{ questao }}</p> <br>
    <ul>
      <li v-for="(alter, index) in alternativas" :key="alter">{{ index }}, {{ alter }} <button @click="removeAlternativa(index)">-</button> </li>
    </ul>
    <button @click="salvaQuestao">Salvar</button>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      questao: '',
      alternativa:'',
      alternativas: []
    }
  },
  methods:{
    addAlternativa(){
      this.alternativas.push(this.alternativa)
      this.alternativa = ''
    },
    removeAlternativa(index){
      this.alternativas.splice(index, 1)
    },
    salvaQuestao() {
      let opcs = []
      for (let i in this.alternativas) {
        opcs.push({value: parseInt(i) + 1, text: this.alternativas[i]})
      }

      axios.post('http://simulador.test/api/questao', {
        'enunciado': this.questao,
        'alternativas': opcs
      })
          .then(response => console.log(response.data))
          .catch(error => console.log(error))
    }
  }
}
</script>
<style>
p, ul, li{
  color: #000;
}
</style>