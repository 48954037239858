<template>

  <div>
    <NovoLogin>

      <template v-slot:esquerdo>

        <h5 v-if="!validCpf" class="text-conectar" style="margin-left: 81px">Conecte-se ao Sistema</h5>

        <div v-if="validCpf" class="funcionamento">
          Estamos sempre disponíveis para ajudar você. <br> Se precisar de assistência online, nosso horário de
          atendimento é das 8h às 18h, de segunda a sexta-feira, exceto feriados.
        </div>
        <div v-if="!validCpf" style="margin-top: 70px;display: flex; justify-content: center;" class="container">

          <b-carousel v-if="slides.length > 0" id="carousel-no-animation"
                      style="text-shadow: 0px 0px 2px #000; height: 200px; width: 450px;"
                      no-animation indicators>
            <b-carousel-slide v-for="(slide, index) in slides" :key="index" :caption="slide.caption"
                              :img-src="slide.imgSrc" img-width="400" img-height="200">
            </b-carousel-slide>
          </b-carousel>

        </div>
      </template>

      <template v-slot:voltar>
        <div style="height: 20px" class="d-flex d-fix justify-content-end align-items-center">
          <a style="margin-top: 40px" v-if="validCpf"
             @click="validCpf = false;showPassword=false;showCpfField=true;cpf=null">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M4.5 12L9.5 7M4.5 12L9.5 17M4.5 12H14.5C16.1667 12 19.5 13 19.5 17" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
      </template>
      <template v-slot:direito>
        <div class="text-center ">
          <img class="logo-outline" src="img/logo2.png"/>
          <p v-if="showCpfField" class="welcome">Bem Vindo</p></div>

        <div class="container">
          <form @submit.prevent="login" class="container">
            <div v-if="showCpfField">
              <div class="form-group">
                <label for="username">Digite o n° do seu CPF</label>
                <div class="input-container">
                  <input type="text" v-model="cpf" placeholder="CPF" v-mask="'###.###.###-##'"
                         class="form-control rounded-top-input-login" id="username"/>
                  <div class="icon-container">
                    <!-- Seu ícone SVG aqui -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                          d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                          stroke="#1384AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path
                          d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                          stroke="#1384AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button
                    class="btn btn-contiuar text-center"
                    @click="checkCpfValidity">CONTINUAR
                </button>
              </div>

            </div>

            <div v-if="showPassword">
              <div class="text-left" style="display: flex; align-items: baseline;">
                <div class="ola">Olá</div>
                <div class="username">{{ userName }}</div>
              </div>


              <div style="margin-top: 33px" class="form-group">
                <label for="password">Digite sua senha</label>
                <div class="input-container">
                  <input type="password" v-model="password" class="form-control rounded-top-input-login" id="password"/>
                  <div class="icon-container" @click="togglePasswordVisibility">
                    <!-- Seu ícone SVG aqui -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clip-path="url(#clip0_1902_1412)">
                        <path
                            d="M0.833496 9.99998C0.833496 9.99998 4.16683 3.33331 10.0002 3.33331C15.8335 3.33331 19.1668 9.99998 19.1668 9.99998C19.1668 9.99998 15.8335 16.6666 10.0002 16.6666C4.16683 16.6666 0.833496 9.99998 0.833496 9.99998Z"
                            stroke="#1384AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                            stroke="#1384AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_1902_1412">
                          <rect width="20" height="20" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div style="margin-top: 10px" class="text-right">
                  <a class="forget" href="#" v-on:click="forgetPassword">Esqueceu sua senha?</a>
                </div>

              </div>
              <div class="form-group">
                <div class="text-center">
                  <button style="border-radius: 15px" @click="login"
                          :disabled="loginButtonDisabled || !cpf || !password || !validCpf"
                          class="btn btn-contiuar">
                    Entrar
                  </button>
                </div>
              </div>
            </div>
            <div v-else-if="validCpf && !showPassword">
              <p>CPF válido, mas não foi possível obter o nome do usuário.</p>
            </div>


            <!--            <div class="captcha-container" v-if="onNaoSouRobo">-->

            <!--              <vue-recaptcha-->
            <!--                  ref="recaptcha"-->
            <!--                  sitekey="6LfhnrgnAAAAAA1cqabHf8_E-QNt77p2b936EAE8-->
            <!--"-->
            <!--                  @verify="onRecaptchaVerified"-->
            <!--              ></vue-recaptcha>-->

            <!--            </div>-->

            <div>
              <div v-if="onNaoSouRobo" class="overlay"></div>
              <div class="content">
                <!-- Seu conteúdo normal aqui -->
              </div>
              <div v-if="onNaoSouRobo" class="captcha-container">
                <vue-recaptcha
                    ref="recaptcha"
                    sitekey="6LfhnrgnAAAAAA1cqabHf8_E-QNt77p2b936EAE8"
                    @verify="onRecaptchaVerified"
                ></vue-recaptcha>
              </div>
            </div>
          </form>
        </div>
      </template>


    </NovoLogin>

  </div>

</template>

<script>

//import MyUsers from './MyUsers.vue';

import axios from "axios";

import NovoLogin from "@/components/NovoLogin.vue";

const baseUrl = process.env.VUE_APP_BASE_URL;
const Imgbase = process.env.VUE_APP_API_IMG;


export default {
  components: {NovoLogin},
  data() {
    return {
      passwordVisible: false,
      cpf: "",
      password: "",
      validCpf: false,
      showPassword: false,
      userName: '',
      showCpfField: true,
      loading: false,
      tentativaLogin: 0,
      loginButtonDisabled: false,
      onNaoSouRobo: false,
      error: null,
      slides: [],
      termoPath: require('../../public/pdfs/Termos_e_condições_gerais_de_uso_sistema_Infoconsig.pdf')

    };
  },
  async mounted() {
    localStorage.clear();
    const cookies = document.cookie.split(";");

    this.slides = await this.getSlides(); // Recupera os slides ao montar o componente


    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
    }

    this.deleteAllCookies();
  },
  methods: {

    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
      const input = document.getElementById('password');
      if (this.passwordVisible) {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
    },

    async getSlides() {
      console.log(Imgbase)
      try {
        const response = await axios.get(`${baseUrl}slides`); // Rota para obter os slides do backend
        return response.data.map(slide => ({
          caption: slide.name,
          // imgSrc: 'http://api.test/storage/' + slide.img,
          imgSrc: Imgbase + slide.img,
          imgAlt: slide.name,
        }));
      } catch (error) {
        console.error('Erro ao recuperar os slides:', error);
        return []; // Retorna uma lista vazia em caso de erro
      }
    },
    deleteAllCookies() {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
      }
    },


    onRecaptchaVerified() {
      this.loginButtonDisabled = false;
      this.onNaoSouRobo = false;
    },

    forgetPassword() {
      return this.$router.push('/forget-password');
    },

    checkCpfValidity() {
      this.loading = true;
      this.showCpfField = false;

      axios.post(baseUrl + 'validacpf', {cpf: this.cpf})
          .then(response => {
            const valid = response.data.valid;
            const exist = response.data.exist;

            if (valid) {
              if (!exist) {
                localStorage.setItem("cpf", this.cpf);
                this.$router.push('/valida');
              }

              this.showCpfField = false;

              axios.post(baseUrl + 'getusername', {cpf: this.cpf})
                  .then(response => {
                    this.loading = false;
                    this.validCpf = true;
                    this.showPassword = true;
                    this.userName = response.data.nm_pessoa;
                  })
                  .catch(error => {
                    this.loading = false;
                    this.showPassword = false;
                    this.userName = '';
                    console.error(error);
                  });
            } else {
              this.loading = false;
              this.showCpfField = true;

              if (response.data.errors) {
                let errorText = '';
                for (const [, value] of Object.entries(response.data.errors)) {
                  errorText += value[0] + '<br>';
                }
                this.$swal.fire({
                  icon: 'error',
                  title: '',
                  html: errorText,
                  customClass: {
                    confirmButton: 'my-custom-button-class'
                  }
                });
              } else {
                this.$swal.fire({
                  icon: 'error',
                  title: '',
                  text: 'CPF não encontrado',
                  customClass: {
                    confirmButton: 'my-custom-button-class'
                  }
                });
              }
            }
          })
          .catch(error => {
            this.loading = false;
            console.error(error); // Exibir erro no console para fins de depuração
            this.validCpf = false;
            this.showPassword = false;
            this.userName = '';
          });
    },

    async login() {
      this.loginButtonDisabled = true;

      try {
        if (!this.cpf || !this.password) {
          return;
        }

        const response = await axios.post(baseUrl + "v2/auth", {
          cpf: this.cpf,
          password: this.password,
        });

        const token = response.data.authToken;
        const pessoa = response.data.pessoa[0].cd_usuario;
        localStorage.setItem("authToken", token);
        localStorage.setItem("pessoa", pessoa);

        if (pessoa) {
          const termo = response.data.user.termo;
          if (termo !== 0) {
            this.$router.push('/myusers');
          } else {
            this.$router.push('/termoaceite');
          }
        }

      } catch (error) {
        if (this.tentativaLogin > 2) {
          this.onNaoSouRobo = true;
          this.loginButtonDisabled = true;
          if (this.tentativaLogin > 3) {
            this.$refs.recaptcha.reset();
            this.loginButtonDisabled = true;
          }
        } else {
          this.loginButtonDisabled = false;
        }

        this.tentativaLogin++;

        if (error.response && error.response.data && error.response.data.error) {
          const errorMessage = error.response.data.error;

          if (errorMessage === 'Unauthorized - Account is temporarily blocked') {
            this.$swal.fire({
              icon: 'error',
              title: 'Conta bloqueada',
              text: 'Sua conta está bloqueada. Por favor, redefina sua senha.',
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'my-custom-button-class'
              }
            }).then(() => {
              // Redirecione para a página de redefinição de senha
              this.forgetPassword();
            });
          } else if (errorMessage === 'Unauthorized') {
            this.$swal.fire({
              icon: 'error',
              title: '',
              text: 'Senha Incorreta',
              customClass: {
                confirmButton: 'my-custom-button-class'
              }

            });



          }else if (errorMessage === 'Unauthorized - Email Dupli'){
            this.$swal.fire({
              icon: 'error',
              title: '',
              text: 'Seu cadastro está com o e-mail desatualizado. Contate nosso suporte para atualizar.',
              customClass: {
                confirmButton: 'my-custom-button-class'

              }
            });

          }

          else if (errorMessage === 'Unauthorized - Session is active in another place. All sessions have been terminated.') {
            this.$swal.fire({
              icon: 'error',
              title: '',
              text: 'Você se encontra com a sessão aberta em outro local',
              customClass: {
                confirmButton: 'my-custom-button-class'
              }
            });
          } else if (errorMessage === 'Unauthorized - not have user') {
            this.$swal.fire({
              icon: 'error',
              title: '',
              text: ' Você não tem usuário ativo',
              customClass: {
                confirmButton: 'my-custom-button-class'
              }
            });
          } else {
            this.$swal.fire({
              icon: 'error',
              title: '',
              text: 'Senha Incorreta',
              customClass: {
                confirmButton: 'my-custom-button-class'
              }

            });
            // Outros erros da API
            // Faça algo apropriado aqui
          }


        } else {
          this.$swal.fire({
            icon: 'error',
            title: '',
            text: 'Erro de autenticação',
            customClass: {
              confirmButton: 'my-custom-button-class'
            }
          });
        }

        console.error(error); // Exibir erro no console para fins de depuração
      }
    }


  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');

.captcha-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Certifique-se de que o z-index seja maior do que os outros elementos para que fique em cima */
}

.rounded-top-input-login {
  border-radius: 6px;
  border: 1px solid var(--White-light, #E0E6ED);
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Cor do fundo branco com transparência */
  z-index: 9999; /* Z-index alto para garantir que esteja sobre todos os outros elementos */
}

.content {
  /* Estilos normais para o conteúdo */
}

.captcha-container {

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000; /* Z-index ainda mais alto para garantir que esteja sobre o fundo branco */
}

.btn-contiuar {
  color: #1384AD;
  font-family: 'nunito Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 15px;
  width: 200px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 80px;
  background: #E0E6ED;
  box-shadow: 0px 10px 20px -10px #003DA4;
}

.rounded-top-input-login::placeholder {
  color: var(--White-light-dark, #888EA8);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form-control::placeholder {
  color: var(--White-light-dark, #888EA8);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; /* Defina a cor desejada para o texto do placeholder */
}

p {
  color: white;
}

.username {
  color: #FFF;
  margin-top: 30px;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.text-ajuda {
  color: #1384AD;
  font-family: "Nunito Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
}

.text-conectar {
  color: #0E1726;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (min-width: 544px) {
  .card-slide {
    width: 543px;
    height: 417px;
    flex-shrink: 0;
    border-radius: 20px 0px 0px 20px;
    background: #FBFBFB;
    box-shadow: 10px 10px 20px -10px #000; /* largura do card */
  }
}

.card-slide {
  z-index: 1;
  height: 417px;
  flex-shrink: 0;
  border-radius: 20px 0px 0px 20px;
  background: #FBFBFB;
  box-shadow: 10px 10px 20px -10px #000; /* largura do card */
}

.card-login {

  height: 417px;
  flex-shrink: 0;
  z-index: 0;
  border-radius: 0px 20px 20px 0px;
  background: #1384AD;
  box-shadow: 10px 10px 20px -10px #000;
}

.welcome {
  color: #FFF;
  font-family: "Nunito Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-top: 16px;
}

.logo-outline {
  margin-top: 30px;
  width: 90px;

  flex-shrink: 0;
}

.logo {
  margin-top: 43px;
  margin-left: 44px;
}

label {
  color: #FFF;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.direito {
  color: #BFC9D4;
  font-family: "Nunito Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  text-align: center;
  padding: 10px;
}

.ola {
  color: #FFF;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}


.funcionamento {
  margin-top: 12px;
  margin-left: 81px;
  width: 350px;
  height: 48px;
  flex-shrink: 0;
  color: #0E1726;

  font-family: "Nunito Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.forget {
  color: #FFF;

  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.centralizar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.input-container {
  display: flex;
  align-items: center;
}

.icon-container {
  margin-left: -30px; /* Ajuste conforme necessário para alinhar o ícone à direita */
  position: relative;
  z-index: 2; /* Para garantir que o ícone esteja acima do input */
}

.input-container input {
  flex: 1;
  padding-right: 40px; /* Garante espaço suficiente para o ícone */
}

</style>
