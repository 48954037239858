<template>
  <div>
    <Menus></Menus>
    <div class="container-fluid">
      <div class="page-wraper">
        <h3>Análise Solocitação de Proposta</h3>
        <hr class="custom-line">

        <div class="row">
          <div class="col-lg-4 col-md-12">

            <GraficoMargem
                ref="graficoMargem"
                :cd_consignataria="parseInt(this.cd_consignataria)"
                :cd_servidor="cd_servidor"
            ></GraficoMargem>

            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Informações do Funcionário</h5>
              </div>

              <div class="card-body">
                <label class="font-weight-bold">Consignante:</label> {{ this.nm_consignante }}<br>
                <label class="font-weight-bold">Averbador :</label> {{ this.nm_averbador }} <br>
                <label class="font-weight-bold">Nome: </label> {{ this.servidor }}<br>
                <label class="font-weight-bold">Matrícula: </label> {{ this.matricula }}<br>

                <label class="font-weight-bold">Data Admissão: </label> {{ this.dt_admissao }}<br>
                <label class="font-weight-bold">Regime:</label> {{ this.regime }}<br>
                <label class="font-weight-bold">Categoria:</label> {{ this.categoria }}<br>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Dados para Contato</h5>
              </div>
              <div class="card-body">

                <label class="font-weight-bold">Telefone:</label> {{ this.telefone }}<br>
                <label class="font-weight-bold">Email:</label> {{ this.email }}<br>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-1"></div>
          <div class="col-md-12 col-lg-7">
            <div class="card ">
              <div class="card-header ">
                <h5 style="color: white" class="card-title">Envio da Proposta</h5>
              </div>
              <div class="card-body">
                <p>Preencha todos os campos para enviar a proposta</p>
                <div class="row">
                  <div class="col-6 form-group">
                    <div class="row">
                      <div class="col-6"><label class="text-right">Vlr Parcela:</label></div>
                      <div class="col-6"><input v-model="valorParcela" class="form-control" type="text"></div>
                    </div>


                  </div>
                  <div class="form-group col-6">
                    <div class="row">
                      <div class="col-6"><label class="text-right">Prazo:</label></div>
                      <div class="col-6"><input v-model="numeroParcelas" class="form-control" type="text"></div>
                    </div>


                  </div>
                </div>
                <div class="row">
                  <div class="col-6 form-group">
                    <div class="row">
                      <div class="col-6"><label class="text-right">Valor Financiado:</label></div>
                      <div class="col-6"><input v-model="valor"
                                                @input="formatarValor('valor')"
                                                @keydown.enter="handleKeyDown($event, 'valor')"
                                                class="form-control" type="text"></div>
                    </div>


                  </div>
                  <div class="col-6 form-group">

                    <div class="row">
                      <div class="col-6"><label class="text-right">Valor Liberado:</label></div>
                      <div class="col-6">
                        <input v-model="valor_liberado"
                               class="form-control"
                               @input="formatarValor('valor_liberado')"
                               @keydown.enter="handleKeyDown($event, 'valor_liberado')"
                               type="text"></div>
                    </div>


                  </div>
                </div>
                <div class="row">
                  <div class="col-6 form-group">
                    <div class="row">
                      <div class="col-6"><label class="text-right">Juros Mensal:</label></div>
                      <div class="col-6"><input v-model="cet_mensal"
                                                @input="formatarValor('cet_mensal')"
                                                @keydown.enter="handleKeyDown($event, 'cet_mensal')"
                                                class="form-control" type="text"></div>
                    </div>


                  </div>
                  <div class="col-6 form-group">
                    <div class="row">
                      <div class="col-6"><label class="text-right">CET Anual:</label></div>
                      <div class="col-6"><input v-model="cet_anual"
                                                @input="formatarValor('cet_anual')"
                                                @keydown.enter="handleKeyDown($event, 'cet_anual')"
                                                class="form-control" type="text"></div>
                    </div>


                  </div>
                </div>
                <div class="row">
                  <div class="col-6 form-group ">
                    <div class="row">
                      <div class="col-6"><label class="text-right">Data Efetivação:</label></div>
                      <div class="col-6"><input v-model="dt_efetivacao" class="form-control" type="date"></div>
                    </div>


                  </div>
                  <div class="col-6 form-group ">
                    <div class="row">
                      <div class="col-6"><label class="text-right">Data Validade:</label></div>
                      <div class="col-6"><input v-model="dt_validade" class="form-control" type="date"></div>
                    </div>


                  </div>


                  <div class="form-group col-12">
                    <div class="row">
                      <div class="col-4 ">
                        <button @click="enviarProposta"
                                :disabled=" !valor || !dt_validade || !dt_efetivacao || !cet_anual"
                                class="btn btn-outline-info btn-block">Enviar
                        </button>
                      </div>
                      <div class="col-4 ">
                        <button class="btn btn-outline-info btn-block">Limpar</button>
                      </div>
                      <div class="col-4 ">
                        <button @click="getRejeitaProposta" class="btn btn-outline-danger btn-block">Rejeitar</button>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>


            Aviso: A sua proposta será enviada para o solicitante, e você receberá a confirmacação caso ele aceite ou
            rejeite
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue"
import BootstrapVue from "bootstrap-vue"
import axios from "axios";

import Menus from "@/components/Menus.vue";
import GraficoMargem from "@/components/GraficoMargem.vue";


Vue.use(BootstrapVue)

const baseUrl = process.env.VUE_APP_BASE_URL;
const baseUrlInfo = process.env.VUE_APP_INFOCONSIG_BASE_URL;


export default Vue.extend({
  components: {
    Menus,
    GraficoMargem

  },
  data() {
    return {
      url: baseUrlInfo,
      proposta: "",
      servidor: "",
      cpf: "",
      matricula: "",
      lotacao: "",
      valor: "",
      numeroParcelas: "",
      valorParcela: "",
      nm_averbador: "",
      nm_consignante: "",
      regime: "",
      categoria: "",
      telefone: "",
      email: "",
      cd_servidor: 0,
      cd_consignataria: 0,
      livre_porcentagem: 0,
      utilizada_porcentagem: 0,
      valor_livre: '',
      valor_utilizado: '',
      dt_admissao: '',
      valor_liberado: '',
      cet_mensal: '',
      cet_anual: '',
      dt_efetivacao: '',
      dt_validade: ''
    }
  },
  methods: {
    formatarValor(propriedade) {
      let value = this[propriedade];

      // Remover caracteres não numéricos, mantendo apenas números
      value = value.replace(/[^0-9]/g, '');

      // Adicionar um ponto decimal após duas casas decimais
      if (value.length > 2) {
        value = value.slice(0, -2) + ',' + value.slice(-2);
      }

      // Atualizar o valor da propriedade com a formatação
      this[propriedade] = value;
    },

    handleKeyDown(event, propriedade) {
      // Reverter a direção da tecla Enter para permitir entrada da direita para a esquerda
      if (event.key === 'Enter') {
        event.preventDefault();
        this[propriedade] = this[propriedade].split('').reverse().join('');
      }
    },
    enviarProposta() {

      let data = {
        'cd_proposta': this.proposta,
        'nr_parcela': this.numeroParcelas,
        'vl_reserva': this.valorParcela,
        'cet_mensal': this.cet_mensal,
        'cet_anual': this.cet_anual,
        'dt_efetivacao': this.dt_efetivacao,
        'dt_validade': this.dt_validade,
        'vl_liberado': this.valor_liberado,
        'vl_financiado': this.valor
      }
      console.log('aprova')
      //axios.post(`${baseUrl}aprova-solicitacao`, data)
      axios.post(baseUrl + 'enviar-solicitacao', data)
          .then(response => {
            console.log(response)
            this.$swal.fire({
              icon: "success",
              title: "Aceita com Sucesso",
              text: response.data.message,
              customClass: {
                confirmButton: "my-custom-button-class",
              },
            }).then(() => {
              // Fecha a janela atual (funciona apenas se a janela foi aberta via window.open)
              window.location.href = `${this.url}.acoespendentes/list/menu/74`;

            });


          })
          .catch(error => console.log(error))
    },


    async getPropostaDados() {
      try {
        const response = await axios.get(baseUrl + 'busca-solicitacao/' + this.cdProposta);

        this.proposta = response.data.cd_proposta;
        this.servidor = response.data.servidor.pessoa.nm_pessoa;
        this.cpf = response.data.servidor.pessoa.nr_cpf;
        this.matricula = response.data.servidor.nr_matricula;
        this.lotacao = response.data.servidor.ds_lotacao;
        this.valor = response.data.vl_financiado_format;
        this.numeroParcelas = response.data.nr_parcela;
        this.valorParcela = response.data.valorParcela_format;
        this.nm_averbador = response.data.servidor.averbador.nm_averbador;
        this.nm_consignante = response.data.servidor.consignante.nm_consignante;
        this.regime = response.data.servidor.regime.ds_regime_vinculo_trab;
        this.categoria = response.data.servidor.categoria.ds_situacao_categoria;
        this.telefone = '' + response.data.servidor.pessoa.nr_ddd_celular + "" + response.data.servidor.pessoa.nr_celular;
        this.email = response.data.servidor.pessoa.ds_email;
        this.cd_servidor = response.data.servidor.cd_servidor;
        this.cd_consignataria = response.data.cd_consignataria;
        this.dt_admissao = response.data.servidor.dt_admissao;

        // alert(this.cd_consignataria);

        // Agora, chame executarGetMargem

        if (this.cd_consignataria != 0) {
          await this.executarGetMargem();
        }

      } catch (error) {
        console.log(error);
      }
    },
    async executarGetMargem() {
      // Chame o método getMargem do componente GraficoMargem.vue
      await this.$nextTick();

      // Chame o método getMargem do componente GraficoMargem.vue

      await this.$refs.graficoMargem.getMargem();

    },


    getRejeitaProposta() {

      this.$swal.fire({
        title: 'Alerta',
        html: '<div style="text-align: justify;">' +
            '<center><p style="color: black;">' +
            'Gostaria realmente de rejeitar a proposta?' +
            '</p></center>' +
            '</div>',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {

          axios.get(baseUrl + 'rejeita-solicitacao/' + this.cdProposta)
              .then(response => {
                this.$swal.fire('Rejeitada!', 'Proposta numero ' + this.proposta + ' rejeitada.', 'success');
                console.log(response)
              })
              .catch(error => console.log(error))


          // Ação a ser realizada ao clicar em "Autorizar"

        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          // Ação a ser realizada ao clicar em "Cancelar"
          this.$swal.fire('Cancelado', 'Ação cancelada.', 'error');
        }
      });

    },


  },
  mounted() {
    this.getPropostaDados()

  },
  computed: {
    cdProposta() {
      return this.$route.params.cd_proposta;
    }
  }
})
</script>
<style scoped>
.page-wraper {
  width: 80%;
  margin: 0 auto;
}

.custom-line {
  border: none;
  border-top: 1px solid #1384AD;
  margin: 10px 0;
}

.card {
  margin-bottom: 30px;
}

.card-header {

  background-color: #1384ad;

}

.card-header > h5 {
  color: white;
}
</style>