<template>

  <div>
    <Menus></Menus>
    <div class="container">

      <div class="card card-primary">
        <div class="card-header"><h5>Cadastro de Slide</h5></div>
        <div class="card-body">
          <div class="form-group">
            <label for="">Nome<span class="text-danger">*</span></label>
            <input type="text" v-model="nome" class="form-control">
          </div>
          <div class="form-group">
            <label for="">Link</label>
            <input type="text" v-model="urlSlide" class="form-control">

          </div>
          <div class="form-group">
            <label for="">Ativo</label>
            <b-form-checkbox size="lg" v-model="ativoEdit" name="check-button" switch>

            </b-form-checkbox>
          </div>
          <div class="form-group">
            <label for="">Imagem <span class="text-danger">*</span>200 de altura x 450 de largura</label>
            <b-form-file
                v-model="img"
                :state="Boolean(img)"
                placeholder="Selecione o arquivo ou arraste aqui"
                drop-placeholder="Solte aqui seu arquivo"
            ></b-form-file>
          </div>
          <div class="form-group">
            <b-button variant="primary" :disabled="isLoading" @click="postEnviar">
              <b-spinner small v-if="isLoading"></b-spinner>

              <span style="color: white" v-else>Enviar</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script setup>



import Swal from "sweetalert2";
import axios from "axios";
import Menus from "@/components/Menus.vue";
const baseUrl = process.env.VUE_APP_BASE_URL;

export default {

  data() {
    return {
      nome: '',
      urlSlide:'',
      isLoading: false,
      ativoEdit: false,
      img: null
    }
  },
  components: {Menus},
  methods: {

    async postEnviar() {
      // Exibindo o alerta de carregamento
      Swal.fire({
        title: 'Carregando...',
        html: '<div class="loading-container"><div class="loading-spinner"></div></div>',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });

      try {
        // Criando um objeto FormData para enviar a imagem
        const formData = new FormData();
        formData.append('ativo', this.ativoEdit);
        formData.append('url', this.urlSlide);
        formData.append('nome', this.nome);
        formData.append('img', this.img); // Verifique se this.img contém a imagem corretamente

        // Realizando a requisição POST
        const response = await axios.post(`${baseUrl}slides`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Especifica o tipo de conteúdo da requisição
          },
        });

        // Lógica após a resposta bem-sucedida
        console.log(response.data); // Exemplo de como utilizar os dados da resposta
        Swal.fire({
          title: 'Sucesso!',
          text: 'O Slide foi gravado com sucesso.',
          icon: 'success',
          timer: 3000 // Tempo em milissegundos (3 segundos)
        }).then(() => {
          // Fechando o alerta de carregamento após exibir a mensagem de sucesso
          Swal.close();
        });

      } catch (error) {
        // Lógica para lidar com erros
        console.error('Erro ao gravar o diretório:', error);
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao gravar o diretório.',
          icon: 'error'
        }).then(() => {
          // Fechando o alerta de carregamento após exibir a mensagem de erro
          Swal.close();
        });
      }
    }




  }
}
</script>

<style scoped>
.card-header {
  background-color: #1384ad;
}


.card-header > h5 {
  color: white;
}
</style>